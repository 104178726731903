import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import { useCountriesTranslations } from 'modules/shared';
import { httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ResponseAPIType } from '../../shared/types';
import { TaxRateType } from '../types/vat-rate.type';

type ResponseType = TaxRateType;

export const useVatRatesQuery = (): UseQueryResult<ResponseType> => {
  const { i18n } = useTranslation();
  const [tCountries] = useCountriesTranslations();
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<ResponseType>(
    ['settings', 'vat-rate', i18n.language],
    async () => {
      const response = await httpClient.get<ResponseAPIType<ResponseType>>(
        `/v1/settings/partners/${currentPartnerId}/taxRates`,
      );
      return {
        ...response.data.data,
        taxRates: response.data.data.taxRates.map((x) => ({
          ...x,
          country: tCountries(x.countryCode),
        })),
      };
    },
    {
      staleTime: 5000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
