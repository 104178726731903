import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { GlobalSettingsData } from './components/global-settings-data';

export const GlobalSettingsPage: React.VFC = () => {
  const [t] = useTranslation('settings');

  return (
    <React.Fragment>
      <Box sx={{ m: 3, maxWidth: '448px' }}>
        <Typography variant="h5">{t('global-settings.description.1')}</Typography>
        <Typography variant="h5">{t('global-settings.description.2')}</Typography>
        <GlobalSettingsData />
      </Box>
    </React.Fragment>
  );
};
