import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import { httpClient } from 'common/api';
import { useAuth } from 'modules/authorization';
import { CountryCode, CurrencyCode, ResponseAPIType } from 'modules/shared/types';
import { GlobalsType } from '../types/globals.type';

type ResponseType = {
  mainShippingCountry: CountryCode;
  baseCurrency: CurrencyCode;
};

export const useGlobalSettingsQuery = (): UseQueryResult<GlobalsType> => {
  const { i18n } = useTranslation();
  const { authorized, partnerIds } = useAuth();

  return useQuery<GlobalsType>(
    ['settings', 'globals', i18n.language],
    async () => {
      const response = await httpClient.get<ResponseAPIType<ResponseType>>(
        `/v1/settings/partners/${partnerIds[0]}/globals`,
      );

      return {
        baseCurrency: response.data.data.baseCurrency || '',
        mainShippingCountry: response.data.data.mainShippingCountry || '',
      };
    },
    {
      staleTime: 10000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
