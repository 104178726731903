import React from 'react';
import { Box, Stack, TextField, TextFieldProps } from '@mui/material';
import { AlertIcon } from '../icons';
import { Tooltip, TooltipWrapper } from '../tooltip';

type Props = TextFieldProps & { tooltipContent?: string; tooltipError?: string };

export const TextFieldWithTooltip: React.VFC<Props> = (props) => {
  const { tooltipContent, tooltipError, label, ...textFieldProps } = props;

  const tooltipErrorComponent = props.error && tooltipError && (
    <TooltipWrapper
      content={
        <Box component="span" sx={{ color: 'error.main' }}>
          {tooltipError}
        </Box>
      }
      disableHoverListener={false}
      placement="right"
    >
      <Box component="span" sx={{ position: 'absolute', right: '-10px', top: '-3px' }}>
        <AlertIcon sx={{ fontSize: '16px', cursor: 'pointer' }} />
      </Box>
    </TooltipWrapper>
  );

  const labelComponent = !tooltipContent ? (
    label
  ) : (
    <Stack direction="row" spacing="10px">
      <span>{label}</span>{' '}
      <Box>
        <Tooltip content={tooltipContent} />
      </Box>
    </Stack>
  );

  return (
    <>
      <TextField {...textFieldProps} label={labelComponent} />
      <Box sx={{ position: 'relative', height: '1px', display: 'inline-flex' }}>{tooltipErrorComponent}</Box>
    </>
  );
};
