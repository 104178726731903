import React, { useMemo, useState } from 'react';
import { GridStateTypeWithId, GridViewContextType } from '../types';

type Props = {
  initialViews: GridStateTypeWithId[];
};

const initialData: GridViewContextType = {
  views: [[], () => null],
  current: [0, () => null],
};

export const GridViewContext = React.createContext<GridViewContextType>(initialData);

export const GridViewContextProvider: React.FC<Props> = (props) => {
  const views = useState([...initialData.views[0], ...props.initialViews]);
  const current = useState(initialData.current[0]);

  const value = useMemo(
    () => ({
      views,
      current,
    }),
    [views[0], current[0]],
  );

  return <GridViewContext.Provider value={value}>{props.children}</GridViewContext.Provider>;
};
