import React from 'react';
import { CardForm } from 'components';
import { IntegrationsList } from './components/integrations/integrations-list';
import { IntegrationContextProvider } from './context/integration.context';

export const IntegrationsPage: React.VFC = () => {
  return (
    <IntegrationContextProvider>
      <CardForm>
        <IntegrationsList />
      </CardForm>
    </IntegrationContextProvider>
  );
};
