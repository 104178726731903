import React from 'react';
import { TableCell, Typography } from '@mui/material';

type Props = {
  text: string;
};

export const ReadonlyTextCell: React.VFC<Props> = (props) => {
  return (
    <TableCell sx={{ textAlign: 'center' }} align="center">
      <Typography variant="h5">{props.text}</Typography>
    </TableCell>
  );
};
