import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { longDate } from 'common/format/date';
import { DataGridPagination, TextFieldOutlined } from 'components';
import { SaleRevenueReport } from 'generated/graphql';
import { useCountriesTranslations } from 'modules/shared';
import { Box } from '@mui/material';
import {
  DataGridPro,
  enUS,
  GridColDef,
  GridLinkOperator,
  GridLocaleText,
  GridRowsProp,
  plPL,
} from '@mui/x-data-grid-pro';
import { useGridContext } from '../hooks/use-grid-context';
import { useSaleRevenueReportsQuery } from '../hooks/use-sale-revenue-reports.query';
import { dataGridComponentsProps } from './data-grid-components-props';
import { dataGridStyles } from './data-grid.styles';
import { NoResults } from './no-results';
import { ReportToolbar } from './report-toolbar/report-toolbar';

type Row = Required<Omit<SaleRevenueReport, '__typename'>>;

export type SaleRevenueGridColDefCustom = GridColDef & { field: keyof Row };

export const SaleRevenueTable: React.VFC = () => {
  const gridContext = useGridContext();
  const { i18n } = useTranslation();
  const [tCountry] = useCountriesTranslations();

  const columns: SaleRevenueGridColDefCustom[] = useMemo(
    () => [
      { field: 'orderId', headerName: 'Order ID', width: 150, type: 'string' },
      { field: 'marketplace', headerName: 'Marketplace', width: 150, type: 'string' },
      { field: 'fulfillment', headerName: 'Fulfillment', width: 150, type: 'string' },
      {
        field: 'orderDate',
        headerName: 'Order Date',
        width: 150,
        type: 'date',
        valueGetter: (params) => longDate(params.value),
      },
      {
        field: 'shippedDate',
        headerName: 'Ship Date',
        width: 150,
        type: 'date',
        valueGetter: (params) => longDate(params.value),
      },
      { field: 'quantity', headerName: 'Quantity', width: 150, type: 'number' },
      { field: 'grandTotal', headerName: 'Grand Total', width: 150, type: 'number' },
      {
        field: 'orderCurrency',
        headerName: 'Order Currency',
        width: 150,
        type: 'string',
      },
      { field: 'productTotal', headerName: 'Product Total', width: 150, type: 'number' },
      { field: 'shippingTotal', headerName: 'Shipping Total', width: 150, type: 'string' },
      { field: 'otherTotal', headerName: 'Other Total', width: 150, type: 'number' },
      {
        field: 'shipCountry',
        headerName: 'Ship Country',
        width: 150,
        type: 'string',
        valueGetter: (params) => tCountry(params.value),
      },
      { field: 'productName', headerName: 'Product Name', width: 250, type: 'string' },
      { field: 'lineItemId', headerName: 'Line Item Id', width: 150, type: 'string' },
      { field: 'eAN', headerName: 'EAN', width: 150, type: 'string' },
      { field: 'category', headerName: 'Category', width: 150, type: 'string' },
    ],
    [],
  );

  const { data, isFetching } = useSaleRevenueReportsQuery(columns);

  const rows: GridRowsProp<Row> = (data?.saleRevenueReports?.nodes || []) as Row[];

  const supportedLocales: { [key: string]: Partial<GridLocaleText> } = {
    pl: plPL.components.MuiDataGrid.defaultProps.localeText,
    en: enUS.components.MuiDataGrid.defaultProps.localeText,
  };

  return (
    <Box
      sx={{
        mt: 0,
        height: 'calc(100vh - 240px)',
      }}
    >
      <DataGridPro
        disableVirtualization
        localeText={supportedLocales[i18n.language]}
        loading={isFetching}
        getRowId={(row) => row.lineItemId}
        rows={rows}
        columns={columns}
        components={{
          BaseTextField: TextFieldOutlined,
          Toolbar: ReportToolbar,
          NoResultsOverlay: NoResults,
          NoRowsOverlay: NoResults,
          Pagination: DataGridPagination,
        }}
        pagination
        paginationMode="server"
        page={gridContext.page[0]}
        pageSize={gridContext.itemsPerPage[0]}
        onPageChange={gridContext.page[1]}
        onPageSizeChange={gridContext.itemsPerPage[1]}
        rowCount={gridContext.rowCount[0]}
        rowsPerPageOptions={[10, 20, 50, 100]}
        sortingMode="server"
        sortModel={gridContext.sort[0]}
        onSortModelChange={gridContext.sort[1]}
        filterMode="server"
        filterModel={gridContext.filter[0]}
        pinnedColumns={gridContext.pinnedColumns[0]}
        onPinnedColumnsChange={gridContext.pinnedColumns[1]}
        onFilterModelChange={(state) => {
          gridContext.filter[1]({
            items: state.items,
            linkOperator: state.linkOperator || GridLinkOperator.And,
          });
        }}
        hideFooterSelectedRowCount
        sx={dataGridStyles}
        componentsProps={dataGridComponentsProps}
      />
    </Box>
  );
};
