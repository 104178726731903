import React, { useEffect, useState } from 'react';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useDebounce } from '@react-hook/debounce';
import { SearchIcon } from '../icons';
import { TextFieldOutlined } from '../input-fields';

type Props = {
  placeholder?: string;
  initialValue?: string;
  onDebounceChange: (value: string) => void;
  debounceTime?: number;
  sx?: SxProps<Theme>;
};

export const SearchTextInput: React.VFC<Props> = (props) => {
  const [value, setValue] = useState(props.initialValue || '');
  const [debouncedValue, setDebouncedValue] = useDebounce(value, props.debounceTime || 500);

  useEffect(() => {
    props.onDebounceChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <TextFieldOutlined
      sx={props.sx}
      placeholder="Search"
      onChange={(event) => {
        setValue(event.target.value || '');
        setDebouncedValue(event.target.value || '');
      }}
      value={value}
      InputProps={{
        endAdornment: <SearchIcon sx={{ color: 'nethansa.button.grayLight', fontSize: '16px' }} />,
        sx: { borderRadius: '6px' },
      }}
    />
  );
};
