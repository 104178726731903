import React, { useMemo, useState } from 'react';
import { GridLinkOperator } from '@mui/x-data-grid';
import { useGridViewContext } from '../hooks/use-grid-view-context';
import { GridContextType } from '../types';

const initialData: GridContextType = {
  rowCount: [0, () => null],
  page: [0, () => null],
  itemsPerPage: [10, () => null],
  sort: [[], () => null],
  filter: [
    {
      items: [],
      linkOperator: GridLinkOperator.And,
    },
    () => null,
  ],
  search: ['', () => null],
  pinnedColumns: [{}, () => null],
};

export const GridContext = React.createContext<GridContextType>(initialData);

export const GridContextProvider: React.FC = (props) => {
  const gridViewContext = useGridViewContext();

  const rowCount = useState(initialData.rowCount[0]);
  const page = useState(initialData.page[0]);
  const itemsPerPage = useState(initialData.itemsPerPage[0]);
  const search = useState(initialData.search[0]);
  const sort = useState(gridViewContext.currentView.sorting || initialData.sort[0]);
  const filter = useState(gridViewContext.currentView.filter || initialData.filter[0]);
  const pinnedColumns = useState(gridViewContext.currentView.pinnedColumns || initialData.pinnedColumns[0]);

  const value = useMemo(
    () => ({
      rowCount,
      page,
      itemsPerPage,
      sort,
      filter,
      search,
      pinnedColumns,
    }),
    [page[0], itemsPerPage[0], sort[0], filter[0], search[0], rowCount[0], pinnedColumns[0]],
  );

  return <GridContext.Provider value={value}>{props.children}</GridContext.Provider>;
};
