import { CheckboxClasses, CheckboxProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const checkboxStyles: {
  defaultProps?: Partial<CheckboxProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof CheckboxClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      alignSelf: 'flex-start',
      borderRadius: '4px',
      '& .MuiBox-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& + .MuiTypography-root': {
        fontSize: '13px',
        fontWeight: 500,
        paddingTop: '8px',
      },
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: 'transparent',
        },
      },
    },
  },
};
