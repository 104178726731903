import * as React from 'react';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { gridPaginationSelector, useGridApiContext, useGridRootProps, useGridSelector } from '@mui/x-data-grid-pro';
import DataGridPaginationActions from './data-grid-pagination-actions';

export const DataGridPagination = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  function GridPagination(props, ref) {
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const paginationState = useGridSelector(apiRef, gridPaginationSelector);

    const lastPage = React.useMemo(
      () => Math.floor(paginationState.rowCount / (paginationState.pageSize || 1)),
      [paginationState.rowCount, paginationState.pageSize],
    );

    const handlePageSizeChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newPageSize = Number(event.target.value);
        apiRef.current.setPageSize(newPageSize);
      },
      [apiRef],
    );

    const handlePageChange = React.useCallback<TablePaginationProps['onPageChange']>(
      (event, page) => {
        apiRef.current.setPage(page);
      },
      [apiRef],
    );

    return (
      <TablePagination
        ref={ref}
        component="div"
        ActionsComponent={DataGridPaginationActions as unknown as React.ElementType<TablePaginationActionsProps>}
        count={paginationState.rowCount}
        page={paginationState.page <= lastPage ? paginationState.page : lastPage}
        rowsPerPageOptions={
          rootProps.rowsPerPageOptions?.includes(paginationState.pageSize) ? rootProps.rowsPerPageOptions : []
        }
        rowsPerPage={paginationState.pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
        {...apiRef.current.getLocaleText('MuiTablePagination')}
        {...props}
      />
    );
  },
);
