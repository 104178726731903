import { useCallback, useContext } from 'react';
import { GridViewContext } from '../context/grid-view.context';

export const useGridViewContext = () => {
  const context = useContext(GridViewContext);

  const changeView = useCallback(
    (viewId: number) => {
      context.current[1](viewId);
    },
    [context.current[1]],
  );

  return {
    views: context.views[0],
    currentView: context.views[0].find((x) => x.id === context.current[0]) || context.views[0][0],
    currentViewId: context.current[0],
    changeView,
  };
};
