import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectField } from 'components';
import { Stack, Typography } from '@mui/material';
import { useCarriersListQuery } from '../../hooks/use-carriers-list.query';
import { ShippingCountryFormProps } from './shipping-country-form';

export const DefaultCarrier: React.VFC = () => {
  const { data } = useCarriersListQuery();

  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<ShippingCountryFormProps>();

  const carriers = useMemo(
    () =>
      data?.data.map((x) => ({
        value: x.code,
        children: x.name,
      })) || [],
    [data?.data.length],
  );

  return (
    <Stack sx={{ mt: 2 }}>
      <Controller
        control={control}
        name="defaultCarrier"
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <SelectField
              disabled={isSubmitting || (data?.data?.length || 0) < 2}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              label={'Default Carrier'}
              placeholder={'Select default carrier'}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              sx={{ mb: 2, flex: 1, m: 0 }}
              options={carriers}
            />
          );
        }}
      />
      <Typography variant="h5">
        The default carrier will be suggested when generating delivery labels to all countries except listed below:
      </Typography>
    </Stack>
  );
};
