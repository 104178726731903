import React, { ReactElement, useLayoutEffect, useState } from 'react';

type Props = {
  fallback?: ReactElement;
  delay?: number;
};

export const BlockMainRenderWithDelay: React.FC<Props> = (props) => {
  const [initialized, setInitialized] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setInitialized(true);
    }, props.delay || 300);
  }, []);

  return <React.Fragment>{initialized ? props.children : props.fallback}</React.Fragment>;
};
