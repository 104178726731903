import { ErrorResponseAPIType } from '../../modules/shared/types';

export function envelope<T>(promise: Promise<T>) {
  return promise
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw (error as ErrorResponseAPIType)?.response?.data?.error || {};
    });
}
