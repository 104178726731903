import React from 'react';
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

type Props = Omit<DialogProps, 'maxWidth'>;

export const Dialog: React.FC<Props> = (props) => {
  return (
    <MuiDialog {...props} hideBackdrop={false} onClose={() => null} transitionDuration={0}>
      {props.children}
    </MuiDialog>
  );
};
