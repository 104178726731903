import React, { useState } from 'react';
import { CarrierCode } from 'modules/shared/types';
import { IntegrationContextType } from '../types/integration.type';

export const IntegrationContext = React.createContext<IntegrationContextType>({});

export const IntegrationContextProvider: React.FC = (props) => {
  const connector = useState<CarrierCode | undefined>();
  const disconnectCarrier = useState<CarrierCode | undefined>();

  const value: IntegrationContextType = {
    connector,
    disconnectCarrier,
  };

  return <IntegrationContext.Provider value={value}>{props.children}</IntegrationContext.Provider>;
};
