import React from 'react';
import { Outlet } from 'react-router-dom';
import { environment } from 'environment';
import { Navbar } from './components/navbar';

export const LayoutMain: React.VFC = () => (
  <React.Fragment>
    {/* USE_COOKIE_AUTH set to 'false' means that React app is not embedded in WebApp and it is used as standalone */}
    {!environment.USE_COOKIE_AUTH && <Navbar />}
    <Outlet />
  </React.Fragment>
);
