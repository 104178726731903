import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { ConnectionStatus } from 'modules/shared/enums';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { usePlatformMarketsQuery } from '../../platform-connector';
import { OnboardingWizardContext, WizardFormStepType } from '../context/onboarding-wizard.provider';
import { useWizardCommitMutation } from '../hooks/default/use-wizard-commit.mutation';
import { useWizardGlobalsQuery } from '../hooks/globals';
import { useWizardPlatformsQuery } from '../hooks/platforms';
import { useWizardProductsQuery } from '../hooks/products/use-wizard-products.query';
import { useWizardVatAndShippingQuery } from '../hooks/vat-and-shipping';
import { SummaryLabel } from './summary/summary-label';
import { SummarySection } from './summary/summary-section';

export const SummaryWizardStep: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [tCountries] = useTranslation('countries');
  const [tCurrencies] = useTranslation('currencies');
  const [t] = useTranslation('wizard');
  const context = useContext(OnboardingWizardContext);
  const globalsQuery = useWizardGlobalsQuery();
  const amazonMarketplaces = usePlatformMarketsQuery('amzeu');
  const platforms = useWizardPlatformsQuery();
  const vatAndShipping = useWizardVatAndShippingQuery();
  const products = useWizardProductsQuery();
  const commitMutation = useWizardCommitMutation();

  const handleBackClick = useCallback(() => {
    context.goToFormStep(WizardFormStepType.products);
  }, []);

  const handleConfirmClick = useCallback(() => {
    commitMutation.mutate(undefined, {
      onSuccess: () => {
        context.goToCongratulationStep();
      },
    });
  }, []);

  let productImportLabel:
    | 'add-products.select.from-file'
    | 'add-products.select.amazon'
    | 'add-products.select.kaufland'
    | 'add-products.select.other';

  switch (products.data?.importMethod) {
    case 'from_file':
      productImportLabel = 'add-products.select.from-file';
      break;
    case 'directly_from_amazon':
      productImportLabel = 'add-products.select.amazon';
      break;
    case 'directly_from_kaufland':
      productImportLabel = 'add-products.select.kaufland';
      break;
    default:
      productImportLabel = 'add-products.select.other';
      break;
  }

  return (
    <>
      <DialogContent sx={{ maxWidth: '800px', maxHeight: '400px', overflow: 'auto' }}>
        <Box sx={{ py: 5, backgroundColor: 'background.paper', px: 7 }}>
          <Typography variant="h2" sx={{ mb: 0 }}>
            {t('summary.header')}
          </Typography>
        </Box>
        <Stack sx={{ backgroundColor: 'background.paper', px: 7, pb: 2 }} gap="10px">
          <SummarySection number={1}>
            <SummaryLabel
              label={t('summary.globals.country')}
              content={tCountries(globalsQuery.data?.mainShippingCountry || 'GB')}
            />
            <SummaryLabel
              label={t('summary.globals.currency')}
              content={tCurrencies(`${globalsQuery.data?.baseCurrency || 'GBP'}.long`)}
            />
          </SummarySection>
          <SummarySection number={2}>
            <SummaryLabel
              label={t('summary.platforms.connected-to')}
              content={
                <Stack sx={{ ml: 2 }} gap="16px">
                  {amazonMarketplaces.data?.length && (
                    <Stack direction="row" gap="20px">
                      <Typography variant="h5" sx={{ fontWeight: '700' }}>
                        {t('summary.platforms.amzeu')}
                      </Typography>
                      <Stack direction="row" flexWrap="wrap" gap="16px">
                        {amazonMarketplaces.data?.length &&
                          amazonMarketplaces.data
                            ?.filter((m) => !!m.isManagedByClipperon)
                            .map((x) => <FlagIcon key={x.code} country={x.code} showCountryCode />)}
                      </Stack>
                    </Stack>
                  )}

                  {platforms.data?.some(
                    (x) =>
                      x.platformCode === 'kauf' && x.connectionStatus === ConnectionStatus[ConnectionStatus.connected],
                  ) && (
                    <Stack direction="row" gap="10px">
                      <Typography variant="h5" sx={{ fontWeight: '700' }}>
                        {t('summary.platforms.kauf')}
                      </Typography>
                      <Stack direction="row" flexWrap="wrap" gap="16px">
                        <FlagIcon key="DE" country="DE" showCountryCode />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              }
            />
          </SummarySection>
          <SummarySection number={3}>
            <SummaryLabel
              label={t('summary.vat-and-shipping.union-oss')}
              content={vatAndShipping.data?.hasVatOss ? 'enabled' : 'disabled'}
            />
            <Table
              sx={{
                minWidth: 600,
                '.MuiTableHead-root .MuiTableRow-head .MuiTableCell-root': {
                  color: '#8B8B8D',
                  fontWeight: '500',
                  fontSize: '13px',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t('summary.vat-and-shipping.table.country')}</TableCell>
                  <TableCell>{t('summary.vat-and-shipping.table.vatRegistration')}</TableCell>
                  <TableCell>{t('summary.vat-and-shipping.table.defaultVATRate')}</TableCell>
                  <TableCell>{t('summary.vat-and-shipping.table.defaultShippingCost')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vatAndShipping.data?.countries.map((x) => {
                  const vatRate = x.vatRates.find((y) => x.selectedVatRate === y.value)?.label || '';

                  return (
                    <TableRow key={x.countryCode}>
                      <TableCell>{x.countryName}</TableCell>
                      <TableCell>{x.isVatRegistered ? t('summary.options.yes') : t('summary.options.no')}</TableCell>
                      <TableCell>{x.isVatRegistered ? vatRate : t('summary.options.not-applicable')}</TableCell>
                      <TableCell align="left">
                        {(x.defaultShippingCost || 0).toFixed(2)}{' '}
                        {tCurrencies(`${globalsQuery.data?.baseCurrency || 'GBP'}.short`)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </SummarySection>
          <SummarySection number={4}>
            <SummaryLabel label={t('summary.products.connected-to')} content={t(productImportLabel)} />
            <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }}>
              {/* {t('summary.products.products-imported', { count: 122 })} */}
            </Typography>
          </SummarySection>
        </Stack>
        <Box sx={{ backgroundColor: 'background.paper', px: 7, pb: 4 }}>
          <Typography variant="h5">{t('summary.confirmation')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, borderTop: '1px solid #DDE8F1' }}>
        {commitMutation.isLoading && (
          <Stack justifyContent="center" sx={{ marginRight: 1 }}>
            <SpinnerIcon sx={{ fontSize: '43px', marginTop: '2px', marginBottom: '-2px' }} />
          </Stack>
        )}
        <Button variant="text" onClick={handleBackClick} disabled={commitMutation.isLoading}>
          {tCommon('back')}
        </Button>
        <Button onClick={handleConfirmClick} disabled={commitMutation.isLoading}>
          {tCommon('confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
