import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';

type InputType = {
  importMethod: string;
};

export const useProductsWizardMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.post('/v1/wizard/steps/products', body);
    return;
  });
};
