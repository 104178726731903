import React from 'react';
import { DataGridExportMenu } from 'components';
import { Box } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { SearchTextInput } from '../../../../components/search-text-input';
import { useCommonTranslations } from '../../../shared';
import { useGridContext } from '../../hooks/use-grid-context';

export const ReportToolbar: React.VFC = () => {
  const gridContext = useGridContext();
  const { tCommon } = useCommonTranslations();

  return (
    <Box
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          gap: '12px',
          p: 1.5,
          px: 0.5,
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'nethansa.line.light',
        },
      }}
    >
      <GridToolbarContainer>
        <SearchTextInput
          sx={{ ml: 1, mr: 2, minWidth: '244px' }}
          placeholder={tCommon('search')}
          onDebounceChange={gridContext.search[1]}
        />
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <DataGridExportMenu
          label={tCommon('export')}
          options={[
            {
              label: 'Download as excel',
              onClick: () => console.log('Download'),
            },
          ]}
        />
      </GridToolbarContainer>
    </Box>
  );
};
