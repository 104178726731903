import React, { useCallback, useEffect, useState } from 'react';
import { httpClient } from 'common/api';
import { applyExternalAccess } from 'common/utils';
import i18next from 'i18next';
import { PlatformsConnectorModal } from 'modules/platform-connector';
import { ExternalAccessConfigType } from 'modules/shared/types';
import { OnboardingWizard } from 'modules/wizard';

type Page = 'wizard' | 'amazon-connector' | 'kaufland-connector';

type CoordinatorContextType = {
  page?: Page;
  showCloseButton?: boolean;
  close: () => void;
};

export const CoordinatorContext = React.createContext<CoordinatorContextType>({
  page: undefined,
  showCloseButton: false,
  close: () => null,
});

export const CoordinatorPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<Page | undefined>();
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);

  const pages: { [key in Page]: JSX.Element } = {
    wizard: <OnboardingWizard />,
    'amazon-connector': <PlatformsConnectorModal open onClose={() => setCurrentPage(undefined)} provider="amzeu" />,
    'kaufland-connector': <PlatformsConnectorModal open onClose={() => setCurrentPage(undefined)} provider="kauf" />,
  };

  const applyChangesOnOpenModal = useCallback(
    async (config?: ExternalAccessConfigType) => {
      if (config) {
        if (config.lang) await i18next.changeLanguage(config.lang);
        setShowCloseButton(!!config?.showCloseButton);
        if (!httpClient.defaults.params) httpClient.defaults.params = {};
        httpClient.defaults.params['partnerId'] = config.partnerId;
      }
    },
    [setShowCloseButton],
  );

  useEffect(() => {
    applyExternalAccess({
      openWizard: async (config) => {
        await applyChangesOnOpenModal(config);
        setCurrentPage('wizard');
      },
      openAmazonPlatformConnector: async (config) => {
        await applyChangesOnOpenModal(config);
        setCurrentPage('amazon-connector');
      },
      openKauflandPlatformConnector: async (config) => {
        await applyChangesOnOpenModal(config);
        setCurrentPage('kaufland-connector');
      },
    });
  }, []);

  // dirty hack to tell WebApp that Wizard finished and to refresh partner data on WebApp
  // I don't know how to react :(
  const onClose = () => {
    window.location.href = '/Wizard/Success';
  };

  return (
    <CoordinatorContext.Provider value={{ page: currentPage, close: () => onClose(), showCloseButton }}>
      <CoordinatorContext.Consumer>
        {(context) => (context.page ? pages[context.page] : null)}
      </CoordinatorContext.Consumer>
    </CoordinatorContext.Provider>
  );
};
