import React from 'react';
import { useTranslation } from 'react-i18next';
import { endOfMonth, format, startOfMonth, subDays, subMonths } from 'date-fns';
import { Stack } from '@mui/material';
import { GridLinkOperator } from '@mui/x-data-grid';
import { ReportTableViews } from './components/report-table-views/report-table-views';
import { SaleRevenueTable } from './components/sale-revenue-table';
import { GridViewContext, GridViewContextProvider } from './context/grid-view.context';
import { GridContextProvider } from './context/grid.context';
import { GridStateTypeWithId } from './types';

export const SalesRevenueReportsPage: React.VFC = () => {
  const { i18n } = useTranslation();

  const initialViews: GridStateTypeWithId[] = [
    {
      id: 0,
      name: 'All columns',
    },
    {
      id: 1,
      name: 'Sales Last 30days',
      sorting: [
        {
          field: 'orderDate',
          sort: 'desc',
        },
      ],
      filter: {
        items: [
          {
            columnField: 'orderDate',
            operatorValue: 'onOrAfter',
            value: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          },
        ],
        linkOperator: GridLinkOperator.And,
      },
    },
    {
      id: 2,
      name: 'Shipped Last Month',
      sorting: [
        {
          field: 'orderDate',
          sort: 'desc',
        },
      ],
      filter: {
        items: [
          {
            columnField: 'shippedDate',
            operatorValue: 'onOrAfter',
            value: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
          },
          {
            columnField: 'shippedDate',
            operatorValue: 'onOrBefore',
            value: format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
          },
        ],
        linkOperator: GridLinkOperator.And,
      },
    },
    {
      id: 3,
      name: 'Shipped This Month',
      sorting: [
        {
          field: 'shippedDate',
          sort: 'desc',
        },
      ],
      filter: {
        items: [
          {
            columnField: 'orderDate',
            operatorValue: 'onOrAfter',
            value: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
          },
        ],
        linkOperator: GridLinkOperator.And,
      },
    },
  ];

  return (
    <Stack key={i18n.language}>
      <GridViewContextProvider initialViews={initialViews}>
        <ReportTableViews />
        <GridViewContext.Consumer>
          {(params) => (
            <GridContextProvider key={params.current[0]}>
              <SaleRevenueTable />
            </GridContextProvider>
          )}
        </GridViewContext.Consumer>
      </GridViewContextProvider>
    </Stack>
  );
};
