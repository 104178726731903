import React from 'react';
import { DisconnectPlatformModal, PlatformsConnectorModal } from '../../platform-connector';
import { usePlatformsContext } from '../hooks/use-platforms-context';

export const PlatformConnectorsCoordinator: React.VFC = () => {
  const {
    connector: [provider, setProvider],
    disconnectPlatform: [disconnectProvider, setDisconnectProvider],
  } = usePlatformsContext();

  return (
    <React.Fragment>
      <PlatformsConnectorModal
        keepCloseButton
        open={!!provider}
        provider={provider}
        onClose={() => setProvider(undefined)}
      />
      <DisconnectPlatformModal open={!!disconnectProvider} onClose={() => setDisconnectProvider(undefined)} />
    </React.Fragment>
  );
};
