import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { NAVIGATION_APP_HEIGHT, nethansaPalette } from 'theme';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  children?: ReactElement[];
  title?: string;
};

export const NavigationTabsLayout: React.VFC<Props> = (props) => {
  const stackRef = useRef<HTMLDivElement>(null);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const borderLine: string = ['1px', 'solid', nethansaPalette.line.light].join(' ');

  const rootStyles: SxProps<Theme> = useMemo(
    () => ({
      zIndex: 1,
      position: 'sticky',
      top: NAVIGATION_APP_HEIGHT,
    }),
    [],
  );

  const containerStyles: SxProps<Theme> = useMemo(
    () => ({
      height: '79px',
      backgroundColor: 'common.white',
      width: '100%',
      borderBottom: borderLine,
      '& > *': {
        transition: 'width .3s',
        '&:hover': {
          color: 'nethansa.main.gray',
        },
        '&:before': {
          transition: 'all .3s',
          position: 'absolute',
          content: '""',
          width: '0px',
          height: '2px',
          bottom: 0,
          backgroundColor: 'text.primary',
        },
      },
    }),
    [],
  );

  const indicatorStyles: SxProps<Theme> = useMemo(
    () => ({
      content: '""',
      position: 'absolute',
      height: '3px',
      backgroundColor: 'common.black',
      left: `${indicatorPosition}px`,
      width: `${indicatorWidth}px`,
      bottom: 0,
      transition: 'left 0.3s, width 0.3s',
    }),
    [indicatorPosition, indicatorWidth],
  );

  const onHover = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!event.currentTarget) return;
      setIndicatorPosition(event.currentTarget.offsetLeft || 0);
      setIndicatorWidth(event.currentTarget.offsetWidth || 0);
    },
    [setIndicatorPosition, setIndicatorWidth],
  );

  const onExit = useCallback(() => {
    if (!stackRef.current) return;
    const activeItem = stackRef.current.querySelector<HTMLAnchorElement>('.NavigationTabsItemActive');
    if (!activeItem) return;
    setIndicatorPosition(activeItem.offsetLeft || 0);
    setIndicatorWidth(activeItem.offsetWidth || 0);
  }, [setIndicatorPosition, setIndicatorWidth]);

  const onChildReady = useCallback(
    (element: HTMLAnchorElement) => {
      if (!element) return;

      setTimeout(() => {
        setIndicatorPosition(element.offsetLeft || 0);
        setIndicatorWidth(element.offsetWidth || 0);
      }, 100);
    },
    [setIndicatorPosition, setIndicatorWidth],
  );

  const tabs = useMemo(
    () =>
      props.children?.map((x) => ({
        ...x,
        props: {
          ...x.props,
          handleNavigationTabHover: onHover,
          onReady: onChildReady,
          onExit: onExit,
        },
      })),
    [props.children, onHover, onChildReady, onExit],
  );

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Stack direction="row" sx={rootStyles}>
        {props.title && (
          <Stack
            sx={{
              minWidth: '205px',
              pl: 3,
              backgroundColor: 'common.white',
              borderBottom: borderLine,
            }}
            justifyContent="center"
          >
            <Typography variant="h1">{props.title}</Typography>
          </Stack>
        )}
        <Stack ref={stackRef} direction="row" justifyContent="flex-start" sx={containerStyles}>
          {tabs}
          <Box sx={indicatorStyles} />
        </Stack>
      </Stack>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
