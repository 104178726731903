import React from 'react';
import Alert from '@mui/material/Alert';
import { SpinnerIcon } from '../icons';

export const AlertLoader: React.FC = (props) => {
  return (
    <Alert
      sx={{
        minHeight: '56px',
        '&.MuiAlert-standardSuccess': {
          backgroundColor: 'background.default',
        },
        '& .MuiAlert-icon': {
          padding: '5px 0',
          marginRight: '9px',
        },
      }}
      icon={<SpinnerIcon sx={{ fontSize: '36px', marginLeft: '-3px' }} />}
    >
      {props.children}
    </Alert>
  );
};
