import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ShieldCheckedIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="33" height="41" viewBox="0 0 33 41" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M16.312 40.2c-.256 0-.508-.066-.732-.191-4.19-2.341-7.606-5.418-10.154-9.143C3.39 27.888 1.907 24.5 1.018 20.792-.488 14.504.234 9.497.266 9.292c.095-.635.584-1.138 1.215-1.253l.894-.163c4.982-.91 9.501-3.504 12.8-7.347.285-.333.701-.524 1.139-.524.438 0 .854.191 1.139.524 3.299 3.843 7.818 6.437 12.8 7.347l.895.163c.631.115 1.12.618 1.215 1.253.031.21.754 5.217-.753 11.5-.889 3.707-2.371 7.1-4.408 10.074-2.547 3.725-5.964 6.8-10.154 9.143-.225.126-.478.192-.736.191zM3.132 10.782c-.194 3.163.086 6.338.83 9.418.811 3.331 2.15 6.369 3.979 9.028 2.129 3.1 4.943 5.7 8.371 7.74 3.449-2.054 6.275-4.674 8.41-7.8 1.829-2.674 3.164-5.729 3.966-9.079.728-3.047 1-6.185.806-9.311-5.009-.961-9.606-3.424-13.182-7.06-3.575 3.637-8.172 6.101-13.18 7.064z"
                transform="translate(-253 -611) translate(230 546) translate(23 65)"
              />
              <path
                d="M16.312 26.9c-.472 0-.917-.19-1.2-.515l-3.823-4.373c-.322-.368-.39-.854-.18-1.277.211-.423.669-.717 1.2-.772.533-.055 1.058.138 1.38.505l2.568 2.937 5.553-6.946c.47-.586 1.404-.735 2.088-.333.683.403.856 1.204.387 1.79l-6.735 8.43c-.275.34-.723.546-1.206.554h-.032z"
                transform="translate(-253 -611) translate(230 546) translate(23 65)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
