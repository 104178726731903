import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormButtonsGroup } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { CarrierCode, CountryCode } from 'modules/shared/types';
import { Alert, Button } from '@mui/material';
import { useCarriersListQuery } from '../../hooks/use-carriers-list.query';
import { DefaultCarrier } from './default-carrier';
import { OtherCarriersList } from './other-carriers-list';

export type ShippingCountryFormProps = {
  defaultCarrier: CountryCode | string;
  otherCarriers: {
    [key in CarrierCode]: CountryCode[];
  };
};

export const ShippingCountryForm: React.VFC = () => {
  const { tCommon } = useCommonTranslations();
  const { data } = useCarriersListQuery();

  const form = useForm<ShippingCountryFormProps>({
    defaultValues: {
      defaultCarrier: '',
      otherCarriers: {
        ups: ['PL', 'AE', 'GB'],
        gls: [],
        dhl: [],
        eurohermes: [],
      },
    },
  });

  const submit = (data: ShippingCountryFormProps) => {
    console.log(data);
  };

  const hasAtLeastTwoCarriers = useMemo(() => data?.data && data?.data.length > 1, [data?.data.length]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        {data?.data && <DefaultCarrier />}
        {hasAtLeastTwoCarriers && <OtherCarriersList />}
        {!hasAtLeastTwoCarriers && (
          <Alert color="info" sx={{ mt: 2 }}>
            Adding a delivery country to a carrier will be possible after integration with at least two carriers.
          </Alert>
        )}
        {hasAtLeastTwoCarriers && (
          <FormButtonsGroup sx={{ mt: 2 }}>
            <Button type="button" size="small" variant="text">
              {tCommon('cancel')}
            </Button>
            <Button type="submit" size="small">
              {tCommon('save')}
            </Button>
          </FormButtonsGroup>
        )}
      </form>
    </FormProvider>
  );
};
