import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BlockMainRenderWithDelay, FlagIcon, FormButtonsGroup, Tooltip } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { generalPlatformFields } from '../../helpers/general-platform-fields';
import { useKauflandPlatformQuery } from '../../hooks/use-kaufland-platform.query';
import { KauflandMarketType } from '../../types/markets.type';
import { PlatformRecordType } from '../../types/platforms.type';
import { CheckboxCell } from '../platform-table/checkbox-cell';
import { RenderTableRowsFields } from '../platform-table/render-table-rows-fields';
import { tableStyles } from '../platform-table/table.styles';

export type PlatformsFormType = {
  kaufland: KauflandMarketType[];
};

export const KauflandPlatformForm: React.VFC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const { data } = useKauflandPlatformQuery();

  const markets = useMemo(() => data?.markets || [], [data?.markets]);

  const form = useForm<PlatformsFormType>({
    mode: 'onBlur',
    defaultValues: {
      kaufland: data?.markets || [],
    },
  });

  const submit = (data: PlatformsFormType) => {
    console.log(data);
  };

  //TODO: maybe it will be possible to get data only by platform id and use the same for amz and kauf
  const rows = useMemo(
    () =>
      generalPlatformFields(t).reduce<PlatformRecordType[]>((acc, v) => {
        const dataInRow = (data?.markets || []).map((x) => x[v.name as keyof KauflandMarketType]);
        acc.push({
          ...v,
          data: dataInRow,
        });
        return acc;
      }, []),
    [data?.markets],
  );

  return (
    <FormProvider {...form}>
      <BlockMainRenderWithDelay
        fallback={
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <SpinnerIcon sx={{ fontSize: '50px' }} />
          </Stack>
        }
      >
        <Box sx={{ display: 'flex' }}>
          <form onSubmit={form.handleSubmit(submit)}>
            <Table sx={{ ...tableStyles }}>
              <TableHead>
                <TableRow
                  sx={{
                    width: '100px',
                    position: 'sticky',
                    top: '185px',
                    zIndex: 2,
                    backgroundColor: 'common.white',
                  }}
                >
                  <TableCell sx={{ width: '200px' }} />
                  {markets.map((x) => (
                    <TableCell
                      key={x.code}
                      variant="head"
                      sx={{
                        width: '120px',
                        position: 'relative',
                        '& > *': { justifyContent: 'center' },
                      }}
                    >
                      <FlagIcon country={x.code} showCountryCode />
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Managed by Nethansa <Tooltip content="Managed by Nethansa" />
                  </TableCell>
                  {markets.map((x, index) => (
                    <CheckboxCell key={x.code} control={form.control} name={`markets.${index}.isManagedByNethansa`} />
                  ))}
                </TableRow>
                <RenderTableRowsFields rows={rows} type="kaufland" />
              </TableBody>
            </Table>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h5">* Field is required</Typography>
            </Box>
            <FormButtonsGroup
              sx={{
                py: 2,
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 3,
              }}
            >
              <Button type="button" size="small" variant="text">
                {tCommon('cancel')}
              </Button>
              <Button type="submit" size="small">
                {tCommon('save')}
              </Button>
            </FormButtonsGroup>
          </form>
        </Box>
      </BlockMainRenderWithDelay>
    </FormProvider>
  );
};
