import React from 'react';
import { TooltipWrapper } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { CarrierCode } from 'modules/shared/types/carrier-code.type';
import { Box, Button, List, ListItemButton } from '@mui/material';
import { useIntegrationContext } from '../../hooks/use-integration-context';

type Props = {
  connected?: boolean;
  carrierCode: CarrierCode;
};

export const ManageButton: React.VFC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const {
    connector: [, setConnector],
    disconnectCarrier: [, setDisconnectCarrier],
  } = useIntegrationContext();

  if (!props.connected) {
    return (
      <Button color="flat" size="small" onClick={() => setConnector(props.carrierCode)}>
        {tCommon('connect')}
      </Button>
    );
  }

  const manageMenu = (
    <List sx={{ '&>*': { fontSize: '13px', p: 0 } }}>
      <ListItemButton onClick={() => setConnector(props.carrierCode)}>{tCommon('edit')}</ListItemButton>
      <ListItemButton onClick={() => setDisconnectCarrier(props.carrierCode)}>{tCommon('disconnect')}</ListItemButton>
    </List>
  );

  return (
    <TooltipWrapper
      content={manageMenu}
      placement="right-start"
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
          },
        },
      }}
    >
      <Button color="flat" size="small" sx={{ minWidth: '47px' }}>
        <Box component="span">...</Box>
      </Button>
    </TooltipWrapper>
  );
};
