import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Alert, Box, Collapse, FormControlLabel, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { useOPTCodeVerification } from '../hooks/use-opt-code-verification.mutation';
import { useTwoFactoryQRCodeQuery } from '../hooks/use-two-factory-qr-code.query';
import { useTwoFactoryStatusQuery } from '../hooks/use-two-factory-status.query';
import { TwoStepVerificationOptForm } from './two-step-verification-opt-form';

export const TwoStepVerification: React.VFC = () => {
  const [t] = useTranslation('settings');
  const twoFactoryStatusQuery = useTwoFactoryStatusQuery();
  const qrCodeQuery = useTwoFactoryQRCodeQuery();
  const optCodeVerification = useOPTCodeVerification();

  const [enabled, setEnabled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [success, setSuccess] = useState(false);

  const disabledTwoFa = useCallback(async () => {
    await optCodeVerification.mutate(
      {
        code: null,
        isTwoFactorEnabled: false,
      },
      {
        onSuccess: () => {
          twoFactoryStatusQuery.refetch();
        },
      },
    );
  }, [optCodeVerification.mutate, twoFactoryStatusQuery.refetch]);

  const onFromSuccess = useCallback(() => {
    setExpanded(false);
    setSuccess(true);
    twoFactoryStatusQuery.refetch();
  }, [twoFactoryStatusQuery.refetch]);

  const onSwitchChange = useCallback(async () => {
    setSuccess(false);
    if (twoFactoryStatusQuery.data?.isTwoFactorEnabled) {
      setEnabled(false);
      await disabledTwoFa();
    } else {
      setEnabled(!enabled);
      setExpanded(!expanded);
    }
  }, [twoFactoryStatusQuery.data?.isTwoFactorEnabled, disabledTwoFa, enabled, expanded]);

  useEffect(() => {
    if (twoFactoryStatusQuery.isFetched) {
      setEnabled(twoFactoryStatusQuery.data?.isTwoFactorEnabled || false);
    }
  }, [twoFactoryStatusQuery.data?.isTwoFactorEnabled, twoFactoryStatusQuery.isFetched]);

  return (
    <Box>
      <Typography variant="h6" sx={{ lineHeight: '18px', fontSize: '13px' }}>
        <strong>{t('two-step-verification.description.bold')}</strong> {t('two-step-verification.description.span')}
      </Typography>
      <Box sx={{ mt: 2, mb: 1 }}>
        <FormControlLabel
          control={<Switch value={enabled} checked={enabled} onChange={onSwitchChange} />}
          label={
            <span>
              {t('two-step-verification.switch-label')}
              <strong>
                {twoFactoryStatusQuery.data?.isTwoFactorEnabled
                  ? t('two-step-verification.enabled')
                  : t('two-step-verification.disabled')}
              </strong>
            </span>
          }
        />
      </Box>
      <Collapse in={expanded && !twoFactoryStatusQuery.data?.isTwoFactorEnabled}>
        <Stack sx={{ px: 7 }} spacing={2}>
          <Typography variant="h5" sx={{ lineHeight: '18px', fontSize: '13px' }}>
            {t('two-step-verification.opt.description')}
          </Typography>
          <Stack alignItems="center" sx={{ my: 1 }}>
            {qrCodeQuery.data?.authenticatorUri && (
              <QRCode value={qrCodeQuery.data?.authenticatorUri || ''} size={200} />
            )}
            {!qrCodeQuery.data?.authenticatorUri && <Skeleton variant="rectangular" width={200} height={200} />}
          </Stack>
          <Box component={'ol'} sx={{ pl: '18px', ml: 0, my: 0, fontSize: '13px' }}>
            <Box component={'li'}>{t('two-step-verification.opt.steps.1')}</Box>
            <Box component={'li'}>{t('two-step-verification.opt.steps.2')}</Box>
            <Box component={'li'}>{t('two-step-verification.opt.steps.3')}</Box>
            <Box component={'li'}>{t('two-step-verification.opt.steps.4')}</Box>
          </Box>
        </Stack>
        <Box sx={{ px: 7 }}>
          <TwoStepVerificationOptForm onSuccess={onFromSuccess} />
        </Box>
      </Collapse>
      <Collapse in={success}>
        <Alert color="success" sx={{ mt: 2 }}>
          {t('two-step-verification.success')}
        </Alert>
      </Collapse>
    </Box>
  );
};
