import React from 'react';
import { Paper, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
};

export const CardForm: React.FC<Props> = (props) => (
  <Stack direction="row" sx={{ p: 3, ...(props.sx || {}) }}>
    <Paper
      sx={{
        p: 3,
        width: '100%',
        maxWidth: props.fullWidth ? 'unset' : '448px',
      }}
    >
      {props.children}
    </Paper>
  </Stack>
);
