import { GridLinkOperator, GridSlotsComponentsProps } from '@mui/x-data-grid-pro';
import { nethansaPalette } from '../../../theme';

export const dataGridComponentsProps: GridSlotsComponentsProps = {
  baseButton: {
    size: 'small',
    color: 'flat',
  },
  columnsPanel: {
    sx: {
      mt: 2.5,
      mb: 1,
      mx: 1,
    },
  },
  filterPanel: {
    linkOperators: [GridLinkOperator.And, GridLinkOperator.Or],
    columnsSort: 'asc',
    filterFormProps: {
      linkOperatorInputProps: {
        variant: 'outlined',
        size: 'small',
        color: 'flat',
      },
      columnInputProps: {
        variant: 'outlined',
        sx: {
          mt: 'auto',
          '& > .MuiInputLabel-outlined': {
            transform: 'translate(-12px, -22px) scale(1);',
            fontSize: '12px',
            fontWeight: 700,
            marginLeft: 0,
            lineHeight: '14.52px',
            letterSpacing: '0.22px',
            color: nethansaPalette.main.gray,
            textTransform: 'uppercase',
          },
          '& .MuiOutlinedInput-root': {
            height: '40px',
            '& .MuiInputBase-input': {
              fontSize: '14px',
              fontWeight: 500,
              borderRadius: '6px',
              '&.Mui-disabled': {
                '& + .MuiOutlinedInput-notchedOutline': {
                  borderColor: nethansaPalette.button.grayLight,
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
              legend: {
                display: 'none',
              },
            },
          },
        },
      },
      operatorInputProps: {
        variant: 'outlined',
        sx: {
          mt: 'auto',
          '& .MuiInputLabel-outlined': {
            transform: 'translate(-12px, -22px) scale(1);',
            fontSize: '12px',
            fontWeight: 700,
            marginLeft: 0,
            lineHeight: '14.52px',
            letterSpacing: '0.22px',
            color: nethansaPalette.main.gray,
            textTransform: 'uppercase',
          },
          '& .MuiOutlinedInput-root': {
            height: '40px',
            '& .MuiInputBase-input': {
              fontSize: '14px',
              fontWeight: 500,
              borderRadius: '6px',
              '&.Mui-disabled': {
                '& + .MuiOutlinedInput-notchedOutline': {
                  borderColor: nethansaPalette.button.grayLight,
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
              legend: {
                display: 'none',
              },
            },
          },
        },
      },
      valueInputProps: {
        sx: { mt: 'auto' },
      },
      deleteIconProps: {
        sx: {
          '& .MuiSvgIcon-root': { color: 'common.black' },
        },
      },
    },
    sx: {
      py: 1,
      // Customize inputs using css selectors
      '& .MuiDataGrid-filterForm': { p: 2 },
      '& .MuiDataGrid-filterForm:nth-of-type(even)': {
        backgroundColor: '#f5f5f5',
      },
      '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 200 },
      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2, width: 200 },
      '& .MuiDataGrid-filterFormValueInput': { width: 200 },
    },
  },
};
