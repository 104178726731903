import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertLoader } from 'components';
import { AmazonLogo } from 'components/logos/amazon.logo';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { PlatformsConnectorContext } from '../../context/platfroms-modal.provider';
import { usePlatformConnectMutation } from '../../hooks/use-platform-test.mutation';
import { usePlatformQuery } from '../../hooks/use-platform.query';
import { ConnectorHeader } from '../connector-header';

type FormProps = {
  sellerId: string;
  token: string;
};

export const AccountInformation: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const platformData = usePlatformQuery('amzeu');
  const platformConnectMutation = usePlatformConnectMutation('amzeu');
  const context = useContext(PlatformsConnectorContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useForm<FormProps>({
    defaultValues: {
      sellerId: platformData.data?.sellerId || '',
      token: platformData.data?.mwsAuthToken || '',
    },
  });

  const submit = (values: FormProps) => {
    platformConnectMutation.mutate({
      sellerId: values.sellerId,
      mwsAuthToken: values.token,
    });
  };

  useEffect(() => {
    if (platformData.data?.sellerId && platformData.data?.mwsAuthToken) {
      setValue('sellerId', platformData.data?.sellerId || '');
      setValue('token', platformData.data?.mwsAuthToken || '');
    }
  }, [platformData.data?.sellerId, platformData.data?.mwsAuthToken]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box sx={{ p: 4, pb: 2 }}>
        <ConnectorHeader logo={<AmazonLogo />} stepsCount={2} />
        <Box sx={{ mt: 4 }}>
          <Typography sx={{ mb: 3 }} variant="h2">
            {t('amazon.integrate.title')}
          </Typography>
          <Typography sx={{ mb: 3 }} variant="h5">
            {t('amazon.integrate.description')}
          </Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <span>{t('amazon.subtitle.leftSide')}</span>
            <a style={{ margin: '0 3.5px' }} href={t('amazon.subtitle.linkHref')} target="_blank" rel="noreferrer">
              {t('amazon.subtitle.linkText')}
            </a>
            <span>{t('amazon.subtitle.rightSide')}</span>
          </Alert>

          <Stack direction="row" gap="16px" sx={{ mt: 6 }}>
            <Controller
              control={control}
              name="sellerId"
              rules={{
                required: {
                  value: true,
                  message: t('amazon.form.seller-id.validation.required'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    disabled={
                      isSubmitting || isSubmitSuccessful || platformData.isLoading || !!platformData.data?.sellerId
                    }
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={t('amazon.form.seller-id.label')}
                    placeholder={t('amazon.form.seller-id.placeholder')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 2, flex: 1 }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="token"
              rules={{
                required: {
                  value: true,
                  message: t('amazon.form.token.validation.required'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    disabled={isSubmitting || isSubmitSuccessful || platformData.isLoading}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={t('amazon.form.token.label')}
                    placeholder={t('amazon.form.token.placeholder')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 2, flex: 2.5 }}
                  />
                );
              }}
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ px: 4, pb: 2 }}>
        {platformConnectMutation.isSuccess && <Alert severity="success">{t('amazon.integrate.success')}</Alert>}
        {platformConnectMutation.isLoading && <AlertLoader>{t('amazon.integrate.loading')}</AlertLoader>}
        {platformConnectMutation.isError && (
          <Alert severity="error">
            <Stack>
              <Box sx={{ fontWeight: 'bold' }}>{platformConnectMutation.error?.response.data.errorCode}</Box>
              <Box sx={{ fontWeight: 'normal' }}>{platformConnectMutation.error?.response.data.errorMessage}</Box>
            </Stack>
          </Alert>
        )}
      </Box>

      <Stack direction="row" justifyContent="flex-end" gap="10px" sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}>
        <Button
          variant="text"
          size="small"
          onClick={context.close}
          disabled={platformConnectMutation.isLoading || platformData.isLoading}
        >
          {tCommon('cancel')}
        </Button>
        {!platformConnectMutation.isSuccess && (
          <Button size="small" type="submit" disabled={platformConnectMutation.isLoading || platformData.isLoading}>
            {tCommon('connect')}
          </Button>
        )}
        {platformConnectMutation.isSuccess && (
          <Button size="small" onClick={() => context.setStep(1)}>
            {tCommon('next')}
          </Button>
        )}
      </Stack>
    </form>
  );
};
