import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const UserAddIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H54V54H0z" transform="translate(-243 -410) translate(243 410)" />
            <g
              stroke="#000"
              strokeLinejoin="round"
              strokeWidth="2.8"
              transform="translate(-243 -410) translate(243 410) translate(11 7)"
            >
              <circle cx="14.434" cy="9.185" r="9.185" />
              <path d="M24.341 22.307c-4.19-3.955-10.332-5.038-15.623-2.757C3.427 21.832 0 27.043 0 32.805v6.56h17.058M24.932 26.244L24.932 39.366M31.492 32.805L18.371 32.805" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
