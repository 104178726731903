import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';

type InputType = {
  file?: File;
};

type FileError =
  | 'text'
  | 'incorrect-extension'
  | 'incorrect-columns'
  | 'incorrect-data-in-first10-rows'
  | 'incorrect-worksheet-tab-name'
  | 'incorrect-file-content'
  | 'file-save-error';

type UploadError = { response: { data: FileError[] } };

export const useWizardProductsFileMutation = (): UseMutationResult<void, UploadError, InputType> => {
  return useMutation(async (body) => {
    if (!body.file) throw new Error('File is missing');
    const form = new FormData();

    form.append('file', body.file, body.file.name);
    await httpClient.post<void, string[], FormData>('/v1/wizard/steps/products/upload-file', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });
};
