import { useMutation, UseMutationResult } from 'react-query';
import { envelope, httpClient } from 'common/api';
import { ErrorMeta } from 'modules/shared/types';
import { ProfileDataType } from '../types/profile-data.type';

type InputType = Omit<ProfileDataType, 'email'>;

export const useProfileDataMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  return useMutation(async (body) => {
    return await envelope(httpClient.put(`/v1/settings/Profiles`, body));
  });
};
