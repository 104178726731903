import React, { useCallback, useEffect, useReducer } from 'react';
import { httpClient } from 'common/api';
import { environment } from 'environment';
import { ErrorMeta, ErrorResponseAPIType } from '../../shared/types';
import { clearCredentials, decodeToken, getCredentials, hasValidCredentials, setCredentials } from '../helpers';
import { AuthenticationInputType, AuthenticationResponseType, AuthenticationType } from '../types/authentication.type';

type State = {
  initialized: boolean;
  authorized: boolean;
  withCredentials: boolean;
  roles: string[];
  partnerIds: string[];
  currentPartnerId: string | null;
  email: string | null;
  userId: string | null;
};

export type AuthorizationContextType = State & {
  login: (
    body: { email: string; password: string },
    callbacks?: {
      onSuccess?: (data: AuthenticationType) => void;
      onError?: (error?: ErrorMeta) => void;
    },
  ) => void;
  logout: () => void;
};

type ActionType = 'UPDATE';

const reducer = (state: State, action: { type: ActionType; payload: Partial<State> }): State => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const initialState: State = {
  initialized: environment.USE_COOKIE_AUTH || hasValidCredentials(),
  authorized: environment.USE_COOKIE_AUTH || hasValidCredentials(),
  withCredentials: environment.USE_COOKIE_AUTH,
  userId: null,
  email: null,
  currentPartnerId: null,
  roles: [],
  partnerIds: [],
};

export const AuthorizationContext = React.createContext<AuthorizationContextType>({
  ...initialState,
  logout: () => null,
  login: () => null,
});

export const AuthorizationProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = useCallback(
    async (
      body: { email: string; password: string },
      callbacks?: {
        onSuccess?: (data: AuthenticationType) => void;
        onError?: (error?: ErrorMeta) => void;
      },
    ) => {
      try {
        const result = await httpClient.post<void, { data: AuthenticationResponseType }, AuthenticationInputType>(
          '/v1/Authentications/tokens',
          body,
        );

        const data = result.data.data;

        setCredentials(data.token, data.refreshToken);

        const decodedToken = decodeToken(data.token || '');

        dispatch({
          type: 'UPDATE',
          payload: {
            ...state,
            authorized: true,
            email: decodedToken.email,
            userId: decodedToken.userId,
            roles: decodedToken.roles,
            partnerIds:
              typeof decodedToken.partnerIds === 'string'
                ? decodedToken.partnerIds.split(',')
                : decodedToken.partnerIds,
          },
        });
        callbacks && callbacks?.onSuccess && callbacks?.onSuccess(data);

        return;
      } catch (err) {
        const error = err as ErrorResponseAPIType<void>;

        dispatch({
          type: 'UPDATE',
          payload: {
            ...state,
            authorized: false,
          },
        });

        callbacks && callbacks?.onError && callbacks?.onError(error?.response?.data?.error);
        return;
      }
    },
    [state, dispatch],
  );

  const logout = useCallback(() => {
    clearCredentials();
    dispatch({
      type: 'UPDATE',
      payload: {
        ...state,
        userId: undefined,
        email: null,
        roles: [],
        partnerIds: [],
        authorized: false,
      },
    });
  }, []);

  useEffect(() => {
    if (state.withCredentials) return;

    const credentials = getCredentials();

    if (!credentials || credentials.refreshTokenExpired < Date.now()) {
      return dispatch({
        type: 'UPDATE',
        payload: {
          ...state,
          initialized: true,
        },
      });
    }

    if (!credentials.token) return;

    const decodedToken = decodeToken(credentials.token || '');

    return dispatch({
      type: 'UPDATE',
      payload: {
        ...state,
        authorized: true,
        initialized: true,
        email: decodedToken.email,
        userId: decodedToken.userId,
        roles: decodedToken.roles,
        partnerIds:
          typeof decodedToken.partnerIds === 'string' ? decodedToken.partnerIds.split(',') : decodedToken.partnerIds,
      },
    });
  }, [dispatch]);

  const value: AuthorizationContextType = {
    ...state,
    currentPartnerId: state.partnerIds[0],
    login,
    logout,
  };

  return <AuthorizationContext.Provider value={value}>{props.children}</AuthorizationContext.Provider>;
};
