import React from 'react';
import { useKauflandPlatformQuery } from '../../hooks/use-kaufland-platform.query';
import { AmazonMarketType } from '../../types/markets.type';
import { KauflandPlatformForm } from './kaufland-platform-form';

export type PlatformsFormType = {
  markets: AmazonMarketType[];
};

export const KauflandPlatform: React.VFC = () => {
  const { isFetched } = useKauflandPlatformQuery();

  return isFetched ? <KauflandPlatformForm /> : null;
};
