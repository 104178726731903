import { PaperClasses, PaperProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const paperStyles: {
  defaultProps?: Partial<PaperProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof PaperClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      borderRadius: '6px',
    },
    elevation: {
      boxShadow: `0px 3px 5px ${nethansaPalette.line.light}`,
    },
  },
};
