import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LayoutMain } from 'modules/layout';
import { StylebookPage } from 'modules/stylebook';
import { ReportsRouter, SettingsRouter } from './routers';
import { routes } from './routes';

// More about: React router v6 Descendant Routes - https://reactrouter.com/docs/en/v6/getting-started/overview#descendant-routes
export const RootRouter: React.VFC = () => (
  <Routes>
    <Route element={<LayoutMain />}>
      <Route path={routes.Stylebook.path} element={<StylebookPage />} />
      <Route path={`${routes.Reports.path}/*`} element={<ReportsRouter />} />
      <Route path={`${routes.Settings.path}/*`} element={<SettingsRouter />} />
    </Route>
  </Routes>
);
