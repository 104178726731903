import { LinkBehavior } from 'components/link-behavior';
import { ButtonClasses, ButtonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    flat: true;
  }

  interface ButtonClasses {
    containedFlat: true;
    outlinedFlat: true;
  }
}

export const buttonsStyles: {
  defaultProps?: Partial<ButtonProps<'button'>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof ButtonClasses>> | undefined;
} = {
  defaultProps: {
    variant: 'contained',
    color: 'secondary',
    LinkComponent: LinkBehavior,
  },
  styleOverrides: {
    root: {
      borderRadius: '22px',
      fontWeight: 700,
      padding: '14px 36px',
      minWidth: '150px',
      fontSize: '12px',
      boxShadow: 'none',
      lineHeight: '15px',
      letterSpacing: '0.22px',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    sizeSmall: {
      borderRadius: '14px',
      minWidth: '68px',
      padding: '6px 16px',
      fontSize: '12px',
      lineHeight: '14.52px',
      letterSpacing: '0.22px',
      height: '30px',
    },
    text: {
      borderRadius: '22px',
      padding: '14px 36px',
      minWidth: '150px',
      fontSize: '12px',
      boxShadow: 'none',
      lineHeight: '15px',
      letterSpacing: '0.22px',
    },
    containedSecondary: {
      '&.Mui-disabled': {
        backgroundColor: nethansaPalette.button.blueInactive,
        color: nethansaPalette.line.dark,
      },
    },
    containedFlat: {
      '&:hover': {
        backgroundColor: nethansaPalette.button.grayHover,
      },
      '&.Mui-disabled': {
        backgroundColor: nethansaPalette.main.dark,
        color: nethansaPalette.main.gray,
      },
    },
  },
};
