import React, { useState } from 'react';
import { AlertLoader, Checkbox, Dialog, DialogHeader, SelectField, TextFieldNumber } from 'components';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';

const longHtmlText = `
  Aliquam <strong>eget finibus</strong> ante, non <em>facilisis lectus</em>. Sed vitae dignissim est, vel aliquam tellus.
  Praesent non <span style='color: red'>nunc mollis</span>, fermentum neque at, semper arcu.
  Nullam eget est sed sem iaculis gravida <a href='https://google.com'>eget</a> vitae justo.
`;

const longHtmlTextWithImage = `
  Lorem ipsum <img src='https://picsum.photos/200/80' />
  Hello! <button>Click</button>
`;

export const StylebookPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalLight, setOpenModalLight] = useState(false);
  const [age, setAge] = React.useState<string | undefined>('');

  const handleChangeAge = (value?: string) => {
    setAge(value);
  };

  return (
    <Box sx={{ m: 5 }}>
      <Paper sx={{ my: 2, p: 2 }}>
        <Typography variant="h1">H1</Typography>
        <Typography variant="h2">H2</Typography>
        <Typography variant="h3">H3</Typography>
        <Typography variant="h4">H4</Typography>
        <Typography variant="h5">H5</Typography>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <Button>Next</Button>

          <Button disabled color="secondary" variant={'contained'}>
            Primary contained
          </Button>
          <Button color="secondary" variant={'contained'}>
            Primary contained
          </Button>
          <Button variant="text">Cancel</Button>
          <Button size="small">Next</Button>
          <Button size="small" disabled>
            Next
          </Button>
          <Button size="small" color="flat" variant="contained">
            Cancel
          </Button>
          <Button disabled size="small" color="flat" variant="contained">
            Cancel
          </Button>
        </Box>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <TextField placeholder="Placeholder" disabled />
          <TextField placeholder="Placeholder" disabled value="A1CBDEFABCDEFG" />
          <TextField placeholder="Placeholder" />
          <TextField placeholder="Placeholder" value="A1CBDEFABCDEFG" />
          <TextField placeholder="Placeholder" fullWidth value="amzn.mws.2cccbb23-4cd5-6e78-f987-ghi6jkl5m432" />
        </Box>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <TextFieldNumber placeholder="Amount" sx={{ width: '120px' }} unit={'€'} precision={2} />
          <TextFieldNumber placeholder="Amount" sx={{ width: '120px' }} unit={'$$'} precision={2} clearable />
          <TextFieldNumber disabled placeholder="Amount" sx={{ width: '120px' }} unit={'$$'} precision={2} clearable />
        </Box>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <FormControl>
            <Checkbox label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
            <Checkbox checked value label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
            <Checkbox indeterminate label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />

            <Checkbox disabled label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
            <Checkbox disabled checked value label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
            <Checkbox disabled indeterminate label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
          </FormControl>
        </Box>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <SelectField
            value={age}
            tooltipContent={longHtmlText}
            onChange={handleChangeAge}
            placeholder="Select age"
            label="Main Shipping country"
            options={[
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
          <SelectField
            value={age}
            onChange={handleChangeAge}
            tooltipContent={longHtmlTextWithImage}
            placeholder={'Select age'}
            helperText="Message"
            label="Main Shipping country"
            options={[
              { value: '', children: <em>None</em> },
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
          <SelectField
            value={age}
            onChange={handleChangeAge}
            label="Main Shipping country"
            error
            helperText="Error here!"
            options={[
              { value: '', children: <em>None</em> },
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
          <SelectField
            value={age}
            disabled
            onChange={handleChangeAge}
            helperText="Some helper text"
            options={[
              { value: '', children: <em>None</em> },
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
        </Box>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Stack direction="row" justifyContent="center">
          <Box sx={{ width: '80%' }}>
            <Stepper activeStep={1} alternativeLabel>
              <Step>
                <StepLabel>Set globals</StepLabel>
              </Step>
              <Step>
                <StepLabel>Connect platforms</StepLabel>
              </Step>
              <Step>
                <StepLabel>Set VAT & shipping</StepLabel>
              </Step>
              <Step>
                <StepLabel>Add products</StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Stack>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Stack spacing={2}>
          <AlertLoader>Empty</AlertLoader>
          <Alert severity="error">This is an error alert — check it out!</Alert>
          <Alert severity="warning">This is a warning alert — check it out!</Alert>
          <Alert severity="info">This is an info alert — check it out!</Alert>
          <Alert severity="success">This is a success alert — check it out!</Alert>
        </Stack>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Button onClick={() => setOpenModal(true)}>Open modal</Button>
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogHeader title="Hello World!" onClose={() => setOpenModal(false)} />
          <DialogContent>
            <p>Lorem ipsum</p>
          </DialogContent>
          <DialogActions>
            <Button size="small">cancel</Button>
            <Button size="small">Next</Button>
          </DialogActions>
        </Dialog>

        <Button onClick={() => setOpenModalLight(true)}>Open light modal</Button>
        <Dialog open={openModalLight} onClose={() => setOpenModalLight(false)}>
          <DialogHeader lightMode onClose={() => setOpenModalLight(false)} spacing="lg" />
          <DialogContent>
            <p>Lorem ipsum</p>
          </DialogContent>
          <DialogActions>
            <Button>Next</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};
