import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, SelectField, TextFieldNumber, Tooltip } from 'components';
import { useCurrenciesTranslations } from 'modules/shared';
import { Box, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useWizardGlobalsQuery } from '../../hooks/globals';
import { useWizardVatAndShippingQuery } from '../../hooks/vat-and-shipping';
import { VATAndShippingFormProps } from '../vat-and-shipping-wizard.step';

type Props = {
  control: Control<VATAndShippingFormProps>;
  isLoading?: boolean;
};

export const VATAndShippingTable: React.FC<Props> = (props) => {
  const [tCurrencies] = useCurrenciesTranslations();
  const [t] = useTranslation('wizard');
  const vatAndShippingQuery = useWizardVatAndShippingQuery();
  const globalsQuery = useWizardGlobalsQuery();

  const vatAndShippingValues = useWatch({
    control: props.control,
    name: 'vatAndShipping',
    defaultValue: [],
  });

  const haveOptedUnionOSSValue = useWatch({
    control: props.control,
    name: 'haveOptedUnionOSS',
    defaultValue: false,
  });

  const baseCurrency = globalsQuery.data?.baseCurrency;
  const mainShippingCountry = globalsQuery.data?.mainShippingCountry;

  return (
    <>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('vat-and-shipping.table.country.header')}</TableCell>
            <TableCell>
              <Stack direction="row">
                <Box sx={{ mr: 1 }}>{t('vat-and-shipping.table.vatRegistration.header')}</Box>
                <Tooltip content={t('vat-and-shipping.table.vatRegistration.tooltip.__html')} />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction="row">
                <Box sx={{ mr: 1 }}>{t('vat-and-shipping.table.defaultVATRate.header')}</Box>
                <Tooltip content={t('vat-and-shipping.table.defaultVATRate.tooltip.__html')} />
              </Stack>
            </TableCell>
            <TableCell align="right">
              <Stack direction="row" justifyContent="flex-end">
                <Box sx={{ mr: 1 }}>{t('vat-and-shipping.table.defaultShippingCost.header')}</Box>
                <Tooltip content={t('vat-and-shipping.table.defaultShippingCost.tooltip.__html')} />
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {vatAndShippingQuery.data?.countries.map((x, i) => (
            <TableRow key={x.countryCode}>
              <TableCell>{x.countryName}</TableCell>
              <TableCell align="center">
                {x.countryCode == mainShippingCountry && <Checkbox label="" value checked disabled />}
                {x.countryCode != mainShippingCountry && (
                  <Controller
                    control={props.control}
                    name={`vatAndShipping.${i}.vatRegistered`}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          value={field.value}
                          checked={!!field.value}
                          label=""
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={props.isLoading}
                        />
                      );
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <Controller
                  control={props.control}
                  name={`vatAndShipping.${i}.defaultVAT`}
                  render={({ field, fieldState }) => {
                    const vatRegisteredValue = vatAndShippingValues ? vatAndShippingValues[i]?.vatRegistered : false;

                    return (
                      <SelectField
                        value={field.value || ''}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        placeholder={t('form.defaultVAT.placeholder')}
                        sx={{ m: 0 }}
                        disabled={
                          (!vatRegisteredValue && (!haveOptedUnionOSSValue || x.countryCode === 'GB')) ||
                          props.isLoading
                        }
                        options={x.vatRates.map((x) => ({ value: x.value, children: x.label }))}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
              </TableCell>
              <TableCell align="right">
                <Controller
                  control={props.control}
                  name={`vatAndShipping.${i}.defaultCost`}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <TextFieldNumber
                        value={field.value || ''}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        sx={{ maxWidth: '100px', minHeight: '40px' }}
                        unit={tCurrencies(`${baseCurrency || 'EUR'}.short`)}
                        precision={2}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        disabled={props.isLoading}
                      />
                    );
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {vatAndShippingQuery.isLoading && (
        <Stack
          gap={'0'}
          sx={{
            width: '100%',
            minWidth: 650,
            '& > .MuiSkeleton-root:nth-of-type(even)': {
              backgroundColor: '#EFEFEF',
            },
          }}
        >
          <Skeleton variant={'rectangular'} sx={{ height: '72px' }} />
          <Skeleton variant={'rectangular'} sx={{ height: '72px' }} />
          <Skeleton variant={'rectangular'} sx={{ height: '72px' }} />
          <Skeleton variant={'rectangular'} sx={{ height: '72px' }} />
          <Skeleton variant={'rectangular'} sx={{ height: '72px' }} />
        </Stack>
      )}
    </>
  );
};
