import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toggleArrayValue } from 'common/utils';
import { Checkbox, FlagIcon, Tooltip } from 'components';
import { AmazonLogo } from 'components/logos/amazon.logo';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { PlatformsConnectorContext } from '../../context/platfroms-modal.provider';
import { usePlatformMarketsMutation } from '../../hooks/use-platform-markets.mutation';
import { usePlatformMarketsQuery } from '../../hooks/use-platform-markets.query';
import { ConnectorHeader } from '../connector-header';

type FormProps = {
  markets: string[];
};

export const MarketsSettings: React.FC = () => {
  const [t] = useTranslation('platforms');
  const { tCommon } = useCommonTranslations();

  const context = useContext(PlatformsConnectorContext);
  const marketsQuery = usePlatformMarketsQuery('amzeu');
  const marketsMutation = usePlatformMarketsMutation('amzeu');

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
  } = useForm<FormProps>({
    defaultValues: {
      markets: [],
    },
  });

  const submit = async (values: FormProps) => {
    const data: { code: string; isManagedByClipperon: boolean }[] =
      marketsQuery.data?.map((x) => ({
        code: x.code,
        isManagedByClipperon: values.markets.some((y) => y === x.code) && x.isEnabled,
      })) || [];
    await marketsMutation.mutate(data, {
      onSuccess: () => {
        context.close();
      },
    });
  };

  useEffect(() => {
    if (!marketsQuery.data || marketsQuery.isLoading) return;
    setValue(
      'markets',
      marketsQuery.data.filter((x) => x.isManagedByClipperon || x.isDefaultMarketplace).map((x) => x.code),
    );
  }, [marketsQuery.data, marketsQuery.isLoading]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box sx={{ p: 4, pb: 2 }}>
        <ConnectorHeader logo={<AmazonLogo />} stepsCount={2} />
        <Box sx={{ mt: 4 }}>
          <Typography sx={{ mb: 3 }} variant="h2">
            {t('amazon.markets.title')}
          </Typography>
          <Typography sx={{ mb: 3 }} variant="h5">
            {t('amazon.markets.description')}
          </Typography>

          <Stack direction="row" gap="16px" sx={{ mt: 6, maxHeight: '290px', overflow: 'auto' }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('amazon.markets.table.marketplace.header')}</TableCell>
                  <TableCell>
                    <Stack direction="row">
                      <Box sx={{ mr: 1 }}>{t('amazon.markets.table.managed-by.header')}</Box>
                      <Tooltip content={t('amazon.markets.table.managed-by.tooltip.__html')} />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row">
                      <Box sx={{ mr: 1 }}>{t('amazon.markets.table.on-holiday.header')}</Box>
                      <Tooltip content={t('amazon.markets.table.on-holiday.tooltip.__html')} />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Controller
                  control={control}
                  name="markets"
                  render={({ field }) => {
                    return (
                      <>
                        {marketsQuery.data?.map((x) => (
                          <TableRow key={x.code}>
                            <TableCell>
                              <Stack direction="row" alignItems="center" gap="10px">
                                <FlagIcon country={x.code} />
                                {x.code}:{t(`amazon.markets.website.${x.code}`)}
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              {x.isEnabled ? (
                                <Checkbox
                                  disabled={marketsQuery.isLoading}
                                  label=""
                                  value={x.code}
                                  checked={field.value.some((y) => x.code === y)}
                                  onChange={(val) => {
                                    field.onChange(toggleArrayValue<string>(field.value, val.target?.value || ''));
                                  }}
                                  onBlur={() => field.onBlur()}
                                />
                              ) : (
                                <Box>{t('amazon.markets.table.n-a')}</Box>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {x.isEnabled
                                ? x.isOnHoliday
                                  ? t('amazon.markets.table.on-holiday.yes')
                                  : t('amazon.markets.table.on-holiday.no')
                                : t('amazon.markets.table.n-a')}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    );
                  }}
                />
              </TableBody>
            </Table>
          </Stack>
        </Box>
      </Box>
      <Stack direction="row" justifyContent="flex-end" gap="10px" sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}>
        <Button variant="text" size="small" onClick={() => context.setStep(0)} disabled={marketsQuery.isLoading}>
          {tCommon('back')}
        </Button>
        {!isSubmitSuccessful && (
          <Button size="small" type="submit" disabled={marketsQuery.isLoading}>
            {tCommon('save')}
          </Button>
        )}
      </Stack>
    </form>
  );
};
