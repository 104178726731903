import React, { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useCountriesQuery } from 'modules/shared';
import { Collapse, Stack } from '@mui/material';
import { useCarriersListQuery } from '../../hooks/use-carriers-list.query';
import { OtherCarrierField } from './other-carrier-field';
import { ShippingCountryFormProps } from './shipping-country-form';

export const OtherCarriersList: React.VFC = () => {
  const { data } = useCarriersListQuery();
  const { data: countries } = useCountriesQuery();

  const { control } = useFormContext<ShippingCountryFormProps>();

  const defaultCarrierValue = useWatch({
    control,
    name: 'defaultCarrier',
  });

  const otherCarriersList = useMemo(
    () => data?.data.filter((x) => (defaultCarrierValue as string) !== (x.code as string)) || [],
    [defaultCarrierValue],
  );

  const mappedAvailableCountries = useMemo(() => countries || [], [countries?.length]);

  return (
    <Collapse in={!!defaultCarrierValue} timeout="auto" unmountOnExit>
      <Stack sx={{ mt: 2 }}>
        {otherCarriersList.map((carrier) => {
          return (
            <Controller
              key={carrier.code}
              name={`otherCarriers.${carrier.code}`}
              render={({ field }) => (
                <OtherCarrierField
                  name={carrier.name}
                  countries={field.value || []}
                  availableCountries={mappedAvailableCountries}
                  addCountry={(countryCode) => {
                    field.onChange([...(field.value || []), countryCode]);
                  }}
                  removeCountry={(countryCode) => {
                    field.onChange([...(field.value || [])].filter((x) => x !== countryCode));
                  }}
                />
              )}
            />
          );
        })}
      </Stack>
    </Collapse>
  );
};
