import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogHeader } from 'components';
import { Box } from '@mui/material';
import { GlobalsWizardStep } from './components/globals-wizard.step';
import { PlatformsWizardStep } from './components/platforms-wizard.step';
import { ProductsWizardStep } from './components/products-wizard.step';
import { StepperWizard } from './components/stepper';
import { SummaryWizardStep } from './components/summary-wizard.step';
import { VatAndShippingWizardStep } from './components/vat-and-shipping-wizard.step';
import { OnboardingWizardContext, WizardFormStepType } from './context/onboarding-wizard.provider';

export const FormWithStepsModal: React.FC = () => {
  const [t] = useTranslation('wizard');
  const context = useContext(OnboardingWizardContext);

  const formSteps = useMemo(
    () => ({
      [WizardFormStepType.globals]: <GlobalsWizardStep />,
      [WizardFormStepType.platforms]: <PlatformsWizardStep />,
      [WizardFormStepType['vat-and-shipping']]: <VatAndShippingWizardStep />,
      [WizardFormStepType.products]: <ProductsWizardStep />,
      [WizardFormStepType.summary]: <SummaryWizardStep />,
    }),
    [],
  );

  //TODO: Decided what dialog should do when user clicks X
  return (
    <Dialog open={true} onClose={() => null}>
      <DialogHeader title={t('modal-title')} onClose={context.closeOnboarding} />
      <Box sx={{ width: '100%', pt: '28px', pb: '4px', backgroundColor: 'background.default' }}>
        <StepperWizard activeStep={context.formStep} />
      </Box>
      {formSteps[context.formStep]}
    </Dialog>
  );
};
