import React from 'react';
import { NavigationTabsItem, NavigationTabsLayout } from '../../components/navigation-tabs';
import { routes } from '../../routes';

export const ReportsLayout: React.VFC = () => {
  return (
    <NavigationTabsLayout title="Reports">
      <NavigationTabsItem href={routes.Reports.children.SalesRevenue.link()} children="Sales Revenue" />
      <NavigationTabsItem href={routes.Reports.children.OrderReturns.link()} children="Order Returns" />
      <NavigationTabsItem href={routes.Reports.children.Taxes.link()} children="Taxes" />
    </NavigationTabsLayout>
  );
};
