import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BillIcon,
  CarrierIcon,
  PaymentsIcon,
  ShieldCheckedIcon,
  UserAddIcon,
  UserEditIcon,
  WalletIcon,
} from 'components/icons';
import { routes } from 'routes';
import { Stack } from '@mui/material';
import { SettingCardItem } from './components/setting-card-item';

export const SettingsPage: React.VFC = () => {
  const [t] = useTranslation('settings');
  const SalesIntelligenceRoutes = routes.Settings.children.SalesIntelligence;
  const ProfileRoutes = routes.Settings.children.Profile;
  const CarriersRoutes = routes.Settings.children.Carriers;

  return (
    <Stack sx={{ p: 4 }} direction="row" flexWrap="wrap" gap="22px">
      <SettingCardItem
        icon={<WalletIcon sx={{ fontSize: '52px' }} />}
        title={t('configuration.sales-intelligence')}
        links={[
          { label: t('configuration.global-settings'), href: SalesIntelligenceRoutes.link() },
          {
            label: t('configuration.platforms'),
            href: SalesIntelligenceRoutes.children.Platforms.link(),
          },
          {
            label: t('configuration.vat-rates'),
            href: SalesIntelligenceRoutes.children.VatRates.link(),
          },
        ]}
      />
      <SettingCardItem
        icon={<CarrierIcon sx={{ fontSize: '52px' }} />}
        title={t('configuration.carriers')}
        links={[
          { label: 'Carriers', href: CarriersRoutes.link() },
          { label: 'Setup new carrier', href: CarriersRoutes.children.ShippingCountry.link() },
        ]}
      />
      <SettingCardItem
        icon={<UserEditIcon sx={{ fontSize: '52px' }} />}
        title={t('configuration.your-profile')}
        links={[
          { label: 'Change data', href: ProfileRoutes.link() },
          { label: 'Change password', href: ProfileRoutes.children.ChangePassword.link() },
          { label: 'Security', href: ProfileRoutes.children.Security.link() },
        ]}
      />
      <SettingCardItem
        disabled
        icon={<BillIcon sx={{ fontSize: '52px' }} />}
        title={t('configuration.payments-management')}
        links={[
          { label: 'Invoices', href: SalesIntelligenceRoutes.link() },
          {
            label: 'Company data',
            href: SalesIntelligenceRoutes.children.Platforms.link(),
          },
        ]}
      />
      <SettingCardItem
        disabled
        icon={<UserAddIcon sx={{ fontSize: '52px' }} />}
        title={t('configuration.users')}
        links={[
          { label: 'Users', href: SalesIntelligenceRoutes.link() },
          { label: 'Add new user', href: SalesIntelligenceRoutes.children.Platforms.link() },
        ]}
      />
      <SettingCardItem
        disabled
        icon={<ShieldCheckedIcon sx={{ fontSize: '48px' }} />}
        title={t('configuration.products-updates')}
        links={[{ label: 'Update settings', href: SalesIntelligenceRoutes.link() }]}
      />
      <SettingCardItem
        disabled
        icon={<PaymentsIcon sx={{ fontSize: '40px' }} />}
        title={t('configuration.usage-and-subscription')}
        links={[
          { label: 'Subscription', href: SalesIntelligenceRoutes.link() },
          { label: 'Usage', href: SalesIntelligenceRoutes.children.Platforms.link() },
        ]}
      />
    </Stack>
  );
};
