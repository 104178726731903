import { useQuery, UseQueryResult } from 'react-query';
import { PlatformCode } from 'modules/shared/types';
import { httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { PlatformDataWizardType } from '../../wizard/types/platform-wizard.type';
import { usePlatformsContext } from './use-platforms-context';

type ResponseType = {
  platformCode: 'amzeu' | 'kauf';
  connectionStatus: 'notAttempted' | 'connected' | 'failed';
}[];

export const usePlatformsSettingsQuery = (): UseQueryResult<PlatformDataWizardType[]> => {
  const { authorized, currentPartnerId } = useAuth();
  const { tab } = usePlatformsContext();

  return useQuery<PlatformDataWizardType[]>(
    ['settings', 'platforms'],
    async () => {
      const response = await httpClient.get<ResponseType>(`/v1/wizard/steps/platforms?partnerId=${currentPartnerId}`);

      if (response.data?.[0]?.platformCode && !tab[0]) {
        tab[1](String(response.data[0].platformCode).toLowerCase() as PlatformCode);
      }

      return response.data.map((x) => ({
        ...x,
        platformCode: String(x.platformCode).toLowerCase() as PlatformCode,
      }));
    },
    {
      staleTime: 5000,
      enabled: authorized,
    },
  );
};
