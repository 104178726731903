import { useQuery, UseQueryResult } from 'react-query';
import { httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';
import { useAuth } from '../../authorization';
import { MarketType } from '../types/market.type';

type ResponseType = MarketType[];

export const usePlatformMarketsQuery = (platformCode: PlatformCode): UseQueryResult<MarketType[]> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<MarketType[]>(
    ['platform', 'markets', platformCode],
    async () => {
      const response = await httpClient.get<ResponseType>(
        `/v1/wizard/steps/platforms/${platformCode}/marketplaces?partnerId=${currentPartnerId}`,
      );
      return response.data;
    },
    {
      staleTime: 0,
      enabled: authorized,
    },
  );
};
