import React from 'react';

type StepCircleWizard = {
  active: boolean;
  completed: boolean;
  error: boolean;
  icon: number | string;
};

export const StepCircleWizard: React.FC<StepCircleWizard> = (props) => {
  const svgContent = {
    active: (
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <circle cx="18" cy="18" r="16.5" fill="white" stroke="#FAAD1B" strokeWidth="3" />
        <text x="50%" y="64%" textAnchor="middle" fill="#282828" style={{ fontSize: '14px', fontWeight: 700 }}>
          {props.icon}
        </text>
      </svg>
    ),
    inactive: (
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <circle cx="18" cy="18" r="16.5" fill="white" stroke="#DDE8F1" strokeWidth="3" />
        <text x="50%" y="64%" textAnchor="middle" fill="#282828" style={{ fontSize: '14px', fontWeight: 700 }}>
          {props.icon}
        </text>
      </svg>
    ),
    completed: (
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <circle cx="18" cy="18" r="16.5" fill="#FAAD1B" stroke="#FAAD1B" strokeWidth="3" />
        <g clipPath="url(#clip0_824_1924)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.6936 12.2796C27.0914 12.6626 27.1035 13.2957 26.7204 13.6935L17.0934 23.6935C16.896 23.8987 16.6204 24.0099 16.3359 23.9993C16.0513 23.9887 15.7848 23.8574 15.6031 23.6381L11.2301 18.3621C10.8777 17.9369 10.9367 17.3065 11.3619 16.9541C11.7871 16.6016 12.4175 16.6606 12.7699 17.0859L16.4288 21.5002L25.2796 12.3065C25.6626 11.9086 26.2957 11.8966 26.6936 12.2796Z"
            fill="#282828"
          />
        </g>
        <defs>
          <clipPath id="clip0_824_1924">
            <rect width="16" height="12" fill="white" transform="translate(11 12)" />
          </clipPath>
        </defs>
      </svg>
    ),
  };

  if (props.completed) return svgContent.completed;

  if (props.active) return svgContent.active;

  return svgContent.inactive;
};
