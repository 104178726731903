import axios from 'axios';
import { environment } from 'environment';
import { getCredentials } from 'modules/authorization';

const httpClient = axios.create({
  baseURL: environment.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: environment.USE_COOKIE_AUTH,
});

if (!environment.USE_COOKIE_AUTH) {
  httpClient.interceptors.request.use(async (config) => {
    const credentials = getCredentials();

    config.headers = {
      ...(config.headers || {}),
      Authorization: `Bearer ${credentials.token}`,
    };

    return config;
  });
}

export { httpClient };
