import React, { useContext } from 'react';
import { CloseButton } from 'components';
import { Box, Stack, Typography } from '@mui/material';
import { CoordinatorContext } from '../../coordinator';
import { PlatformsConnectorContext } from '../context/platfroms-modal.provider';

type Props = {
  logo: JSX.Element;
  stepsCount?: number;
};

export const ConnectorHeader: React.FC<Props> = (props) => {
  const context = useContext(PlatformsConnectorContext);
  const coordinatorContext = useContext(CoordinatorContext);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box>{props.logo}</Box>
      <Box>
        <Stack direction="row" alignItems="center" gap="24px">
          {props.stepsCount && props.stepsCount > 1 && (
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '13px' }}>
              {context.step + 1} / {props.stepsCount}
            </Typography>
          )}
          {(coordinatorContext.showCloseButton || context.keepCloseButton) && <CloseButton onClick={context.close} />}
        </Stack>
      </Box>
    </Stack>
  );
};
