import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { usePlatformsContext } from '../hooks/use-platforms-context';

export const MainSettings: React.VFC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const {
    tab: [platform],
  } = usePlatformsContext();

  return (
    <Box>
      <Stack direction="row" gap={2}>
        <Stack sx={{ minWidth: '430px' }} gap={1}>
          <Typography variant="h3" fontSize="13px">
            {platform === 'amzeu' && t('amazon.account-identifier.label')}
            {platform === 'kauf' && t('kaufland.client-key.label')}
          </Typography>
          <Typography fontSize="13px" textTransform="uppercase" fontWeight="bold">
            A18PBGCY3DH711
          </Typography>
        </Stack>
        <Stack sx={{ minWidth: '430px' }} gap={1}>
          <Typography variant="h3" fontSize="13px">
            {platform === 'amzeu' && t('amazon.access-token.label')}
            {platform === 'kauf' && t('kaufland.secret-key.label')}
          </Typography>
          <Typography fontSize="13px" fontWeight="bold">
            amzn.mws.c8bd...
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ mt: 4 }}>
        <Stack gap={2}>
          <FormControlLabel
            control={<Switch value={true} checked={true} onChange={() => null} />}
            label={
              <Box component="span">
                {t('form.stockSendingToPlatform.label')}{' '}
                <Box component="strong" sx={{ '& button': { mb: '3px', ml: 0.8 } }}>
                  {tCommon('enabled')} <Tooltip content={t('form.stockSendingToPlatform.tooltip.__html')} />
                </Box>
              </Box>
            }
          />
        </Stack>
      </Box>
    </Box>
  );
};
