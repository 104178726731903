import React from 'react';
import { Control, Controller, UseFormGetValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileField } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { useWizardProductsFileMutation } from '../../hooks/products/use-wizard-products-file.mutation';
import { AddProductsFormProps } from '../products-wizard.step';

type Props = {
  control: Control<AddProductsFormProps>;
  getValues: UseFormGetValues<AddProductsFormProps>;
};

export const FileOption: React.FC<Props> = (props) => {
  const [t] = useTranslation('wizard');
  const { tCommon } = useCommonTranslations();
  const productsFileMutation = useWizardProductsFileMutation();

  const fileValueCheck = useWatch({
    control: props.control,
    name: 'fileToUpload',
  });

  const fileHasBeenUploadedValue = useWatch({
    control: props.control,
    name: 'fileHasBeenUploaded',
  });

  return (
    <Box>
      <Typography variant="h5" sx={{ '& > a': { textDecoration: 'underline' } }}>
        <a href={t('add-products.file.url')} target="_blank" rel="noreferrer">
          {t('add-products.file.label.link-label')}
        </a>{' '}
        {t('add-products.file.label.text')}
      </Typography>
      <Controller
        control={props.control}
        name="fileToUpload"
        rules={{
          required: {
            value: true,
            message: t('form.fileToUpload.validation.required'),
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FileField
              value={field.value}
              onChange={(file) => {
                field.onChange(file);
                field.onBlur();
              }}
              disabled={fileHasBeenUploadedValue || productsFileMutation.isLoading}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              label={t('form.fileToUpload.label')}
              buttonLabel={tCommon('choose')}
            />
          );
        }}
      />

      <Controller
        control={props.control}
        name="fileHasBeenUploaded"
        rules={{
          required: {
            value: true,
            message: t('form.fileHasBeenUploaded.validation.required'),
          },
        }}
        render={({ field, fieldState }) => {
          const handleSendFile = () => {
            // We cannot get value from UseWatch because it doesn't handle File object correctly.
            const fileValue = props.getValues('fileToUpload');

            if (!fileValue) return;

            productsFileMutation.mutate(
              { file: fileValue },
              {
                onSuccess: () => {
                  field.onChange(true);
                  field.onBlur();
                },
              },
            );
          };

          if (!fileValueCheck) return <div />;

          if (productsFileMutation.isSuccess) {
            return (
              <Alert severity="success" sx={{ mt: 2 }}>
                {t('add-products.file.success.text')}
              </Alert>
            );
          }

          return (
            <Box sx={{ mt: 2, position: 'relative' }}>
              <Stack direction="row" alignItems="center">
                <Box>
                  <Button
                    onClick={handleSendFile}
                    disabled={productsFileMutation.isLoading}
                    variant={'contained'}
                    color="flat"
                    size="small"
                  >
                    Upload
                  </Button>
                </Box>
                {productsFileMutation.isLoading && (
                  <Stack justifyContent="center" sx={{ marginLeft: 1 }}>
                    <SpinnerIcon sx={{ fontSize: '43px', marginTop: '2px', marginBottom: '-2px' }} />
                  </Stack>
                )}
              </Stack>
              {productsFileMutation.isError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  <Box sx={{ fontWeight: 'bold' }}>{t('add-products.file.error.text')}</Box>
                  {productsFileMutation.error.response.data &&
                    productsFileMutation.error.response.data.map((error, i) => {
                      return (
                        <Box key={i} sx={{ fontWeight: 'normal' }}>
                          {t(`add-products.file.error.${error}`)}
                        </Box>
                      );
                    })}
                </Alert>
              )}
              {fieldState.error && (
                <Typography
                  sx={{ color: 'error.main', fontSize: '0.75rem', position: 'absolute', top: 'calc(100% + 5px)' }}
                >
                  {fieldState.error.message}
                </Typography>
              )}
            </Box>
          );
        }}
      />
    </Box>
  );
};
