import React from 'react';
import { NAVIGATION_APP_HEIGHT, nethansaPalette } from 'theme';
import { Box } from '@mui/material';

export const Navbar: React.VFC = () => (
  <Box
    sx={{
      zIndex: 1,
      position: 'sticky',
      top: 0,
      height: NAVIGATION_APP_HEIGHT,
      backgroundColor: nethansaPalette.main.dark,
    }}
  />
);
