import React, { ReactElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { RenderInView, Tooltip } from 'components';
import { TableCell, TableRow } from '@mui/material';
import { PlatformPresentationFieldType, PlatformRecordType } from '../../types/platforms.type';
import { CheckboxCell } from './checkbox-cell';
import { NumberInputCell } from './number-input-cell';
import { ReadonlyTextCell } from './readonly-text-cell';

type Props = {
  rows: PlatformRecordType[];
  type: 'merchant' | 'amazon' | 'kaufland';
};

export const RenderTableRowsFields: React.VFC<Props> = React.memo(({ rows, type }) => {
  const form = useFormContext();

  const rowsComponents = useMemo(
    () =>
      rows.map((row, index) => (
        <RenderInView height={'49px'} key={`${String(row.name)}-${index}`} placeholderComponent={TableRow}>
          <TableCell align="center">
            {row.title} <Tooltip content={row.title} />
          </TableCell>
          {row.data.map((x, dataIndex) => {
            const componentsDictionary: { [key in PlatformPresentationFieldType]: ReactElement } = {
              checkbox: (
                <CheckboxCell
                  key={`${row.name}-${type}-${dataIndex}`}
                  control={form.control}
                  name={`${type}.${dataIndex}.${String(row.name)}`}
                />
              ),
              text: <ReadonlyTextCell key={`${String(row.name)}-${type}-${dataIndex}`} text={x ? 'Yes' : 'No'} />,
              number: (
                <NumberInputCell
                  key={`${row.name}-${type}-${dataIndex}`}
                  disabled={row.disabled}
                  unit={row.unit}
                  precision={row.precision}
                  rules={row.rules}
                  control={form.control}
                  name={`${type}.${dataIndex}.${String(row.name)}`}
                />
              ),
            };
            return componentsDictionary[row.type];
          })}
        </RenderInView>
      )),
    [],
  );

  return <React.Fragment>{rowsComponents}</React.Fragment>;
});
