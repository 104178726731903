import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';

type TwoFactoryStatusResponse = {
  isTwoFactorEnabled: boolean;
};

export const useTwoFactoryStatusQuery = (): UseQueryResult<TwoFactoryStatusResponse> => {
  const { authorized } = useAuth();

  return useQuery(
    ['settings', 'two-factory-status'],
    async () => {
      const response = await httpClient.get<ResponseAPIType<TwoFactoryStatusResponse>>(
        `/v1/settings/Profiles/twoFaStatus`,
      );

      return {
        isTwoFactorEnabled: response.data.data.isTwoFactorEnabled,
      };
    },

    {
      staleTime: 20000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
