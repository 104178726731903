import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const UploadIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.28698 3.79886C1.89975 4.19265 1.90507 4.8258 2.29886 5.21302C2.69265 5.60025 3.32579 5.59493 3.71302 5.20114L5.00351 3.88878L5 9.49938C4.99965 10.0517 5.44709 10.4997 5.99938 10.5C6.55166 10.5003 6.99965 10.0529 7 9.50062L7.00348 3.93888L8.29876 5.21293C8.6925 5.60021 9.32564 5.59498 9.71293 5.20124C10.1002 4.8075 10.095 4.17436 9.70124 3.78707L6.87573 1.00789C6.70409 0.704833 6.37879 0.500234 6.00562 0.5C5.99618 0.499995 5.98676 0.50012 5.97737 0.500374C5.96549 0.500049 5.95359 0.499936 5.94167 0.500035C5.67643 0.502245 5.42295 0.609738 5.23698 0.79886L2.28698 3.79886ZM1 11.5C0.447715 11.5 0 11.9477 0 12.5C0 13.0523 0.447715 13.5 1 13.5H11C11.5523 13.5 12 13.0523 12 12.5C12 11.9477 11.5523 11.5 11 11.5H1Z"
      />
    </SvgIcon>
  );
};
