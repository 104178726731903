import { useQuery, UseQueryResult } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import { SaleRevenueReportsQuery, SaleRevenueReportsQueryVariables } from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from '../../authorization';
import { SaleRevenueGridColDefCustom } from '../components/sale-revenue-table';
import { useGridContext } from './use-grid-context';
import { useGridContextGraphqlTranslator } from './use-grid-context-graphql-translator';

export const getReports = gql`
  query SaleRevenueReports(
    $partnerId: String!
    $first: Int!
    $after: String
    $sort: [SaleRevenueReportSortInput!]
    $filter: SaleRevenueReportFilterInput
  ) {
    saleRevenueReports(partnerId: $partnerId, first: $first, after: $after, where: $filter, order: $sort) {
      totalCount
      nodes {
        ...saleRevenueReportNode
      }
      pageInfo {
        ...paginationMeta
      }
      edges {
        cursor
      }
    }
  }

  fragment saleRevenueReportNode on SaleRevenueReport {
    category
    eAN
    fulfillment
    grandTotal
    lineItemId
    marketplace
    orderCurrency
    orderId
    orderDate
    otherTotal
    partnerIdentity
    productName
    productTotal
    quantity
    shipCountry
    shippedDate
    shippingTotal
  }

  fragment paginationMeta on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const useSaleRevenueReportsQuery = (
  columns: SaleRevenueGridColDefCustom[],
): UseQueryResult<SaleRevenueReportsQuery> => {
  const { partnerIds } = useAuth();
  const gridContext = useGridContext();
  const gridFilterSort = useGridContextGraphqlTranslator(columns, ['productName', 'eAN', 'category', 'orderId']);

  return useQuery<SaleRevenueReportsQuery>(
    [
      'reports',
      'sales-revenue',
      partnerIds[0],
      gridFilterSort.first,
      gridFilterSort.after,
      gridFilterSort.sort,
      gridFilterSort.filter,
    ],
    async () => {
      const data = (await graphqlClient.request<SaleRevenueReportsQueryVariables>(getReports, {
        partnerId: partnerIds[0],
        first: gridFilterSort.first,
        after: gridFilterSort.after,
        sort: gridFilterSort.sort,
        filter: gridFilterSort.filter,
      })) as SaleRevenueReportsQuery;

      gridContext.rowCount[1](data.saleRevenueReports?.totalCount || 0);

      return {
        ...data,
        saleRevenueReports: {
          ...data.saleRevenueReports,
          nodes: data.saleRevenueReports?.nodes?.map((x, index) => ({
            ...x,
            lineItemId: String(x.lineItemId) + index,
          })),
        },
      } as SaleRevenueReportsQuery;
    },
    {
      staleTime: 50000,
      keepPreviousData: false,
      retry: false,
    },
  );
};
