import { useQuery, UseQueryResult } from 'react-query';
import { AmazonMarketType } from '../types/markets.type';

const params: Omit<AmazonMarketType, 'code' | 'name'> = {
  isEnabled: true,
  isManagedByNethansa: true,
  holidaysEnabled: false,
  automaticDownloadOffers: true,
  automaticOffersCreation: true,
  priceManagement: true,
  nethansaCommission: 3,
  returnsReserve: 1,
  shippingCost: 1,
  handlingTimeInDays: 1,
  additionalCost: 1,
  minimumMarkup: 1,
  standardMarkup: 1,
  maximumMarkup: 1,
  differenceFromCompetition: 1,
  differenceFromCompetitionPercentage: 1,
};

const mocked: AmazonMarketType[] = [
  {
    code: 'UK',
    name: 'UK: amazon.co.uk',
    ...params,
  },
  {
    code: 'DE',
    name: 'DE: amazon.de',
    ...params,
    holidaysEnabled: true,
  },
  {
    code: 'SE',
    name: 'SE: amazon.se',
    ...params,
  },
  {
    code: 'PL',
    name: 'PL: amazon.pl',
    ...params,
  },
  {
    code: 'NL',
    name: 'NL: amazon.nl',
    ...params,
  },
  {
    code: 'FR',
    name: 'FR: amazon.fr',
    ...params,
  },
];

type ResponseType = {
  markets: AmazonMarketType[];
};

export const useAmazonPlatformQuery = (): UseQueryResult<ResponseType> => {
  return useQuery<ResponseType, Error, ResponseType>(
    ['settings', 'amazon', 'platform'],
    async () => {
      return {
        markets: mocked,
      };
    },
    {
      staleTime: 5000,
    },
  );
};
