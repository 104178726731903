import { useMutation, UseMutationResult } from 'react-query';
import { envelope, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { TaxRateInputType } from '../types/vat-rate.type';

type InputType = TaxRateInputType;

export const useVatRatesMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    return await envelope(httpClient.put(`/v1/settings/partners/${currentPartnerId}/taxRates`, body));
  });
};
