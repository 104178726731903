import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const BillIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H54V54H0z" transform="translate(-744 -216) translate(731 158) translate(13 58)" />
              <g fill="#000" fillRule="nonzero">
                <g>
                  <path
                    d="M28.447 40.2c-.237 0-.47-.056-.681-.164l-4.521-2.3-3.355 2.219c-.502.332-1.153.332-1.655 0L14.976 37.8l-3.257 2.154c-.502.332-1.153.332-1.655 0l-3.358-2.219-4.525 2.305c-.466.237-1.021.215-1.466-.058-.445-.274-.716-.76-.715-1.282V1.5C0 .978.272.494.716.221c.445-.272 1-.294 1.465-.057l4.521 2.3L10.058.249c.502-.332 1.153-.332 1.655 0L14.97 2.4 18.228.249c.502-.331 1.152-.331 1.654 0l3.359 2.219L27.766.163c.465-.237 1.02-.214 1.464.058.445.273.716.757.716 1.279v37.2c0 .828-.672 1.5-1.5 1.5h.001zM6.804 34.5c.294 0 .582.086.827.248l3.26 2.152 3.258-2.154c.502-.331 1.152-.331 1.654 0l3.255 2.154 3.258-2.154c.45-.298 1.027-.331 1.508-.086l3.119 1.59V3.95l-3.122 1.591c-.481.245-1.057.213-1.508-.085L19.058 3.3l-3.26 2.152c-.502.332-1.153.332-1.655 0L10.885 3.3 7.627 5.454c-.45.298-1.027.331-1.508.086L3 3.95v32.3l3.123-1.591c.211-.106.445-.16.681-.159z"
                    transform="translate(-744 -216) translate(731 158) translate(13 58) translate(12 7)"
                  />
                  <path
                    d="M22.858 11.749H7.085c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h15.773c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5zM22.858 17.688H7.085c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h15.773c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5zM22.858 30.1h-7.035c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h7.035c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5z"
                    transform="translate(-744 -216) translate(731 158) translate(13 58) translate(12 7)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
