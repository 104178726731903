import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabsItem, NavigationTabsLayout } from 'components/navigation-tabs';
import { routes } from 'routes';

export const AccountDataLayout: React.VFC = () => {
  const [t] = useTranslation('settings');
  return (
    <NavigationTabsLayout>
      <NavigationTabsItem href={routes.Settings.children.Profile.link()} exact children={t('tabs.your-data')} />
      <NavigationTabsItem
        href={routes.Settings.children.Profile.children.ChangePassword.link()}
        children={t('tabs.change-password')}
      />
      <NavigationTabsItem
        href={routes.Settings.children.Profile.children.Security.link()}
        children={t('tabs.security')}
      />
    </NavigationTabsLayout>
  );
};
