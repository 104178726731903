export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Export SaleRevenue report. */
  exportSaleRevenueReport: Array<SaleRevenueReport>;
  /** Query for SaleRevenue report rows. */
  saleRevenueReports?: Maybe<SaleRevenueReportsConnection>;
};


export type QueryExportSaleRevenueReportArgs = {
  order?: InputMaybe<Array<SaleRevenueReportSortInput>>;
  partnerId: Scalars['String'];
  where?: InputMaybe<SaleRevenueReportFilterInput>;
};


export type QuerySaleRevenueReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<SaleRevenueReportSortInput>>;
  partnerId: Scalars['String'];
  where?: InputMaybe<SaleRevenueReportFilterInput>;
};

/** Sale revenue report. */
export type SaleRevenueReport = {
  __typename?: 'SaleRevenueReport';
  category?: Maybe<Scalars['String']>;
  eAN?: Maybe<Scalars['String']>;
  fulfillment?: Maybe<Scalars['String']>;
  grandTotal?: Maybe<Scalars['Decimal']>;
  lineItemId?: Maybe<Scalars['String']>;
  marketplace?: Maybe<Scalars['String']>;
  orderCurrency?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  otherTotal?: Maybe<Scalars['Decimal']>;
  partnerIdentity?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productTotal?: Maybe<Scalars['Decimal']>;
  quantity?: Maybe<Scalars['Int']>;
  shipCountry?: Maybe<Scalars['String']>;
  shippedDate?: Maybe<Scalars['DateTime']>;
  shippingTotal?: Maybe<Scalars['Decimal']>;
};

export type SaleRevenueReportFilterInput = {
  and?: InputMaybe<Array<SaleRevenueReportFilterInput>>;
  category?: InputMaybe<StringOperationFilterInput>;
  eAN?: InputMaybe<StringOperationFilterInput>;
  fulfillment?: InputMaybe<StringOperationFilterInput>;
  grandTotal?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  lineItemId?: InputMaybe<StringOperationFilterInput>;
  marketplace?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SaleRevenueReportFilterInput>>;
  orderCurrency?: InputMaybe<StringOperationFilterInput>;
  orderDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  orderId?: InputMaybe<StringOperationFilterInput>;
  otherTotal?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  partnerIdentity?: InputMaybe<StringOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
  productTotal?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  quantity?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  shipCountry?: InputMaybe<StringOperationFilterInput>;
  shippedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  shippingTotal?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
};

export type SaleRevenueReportSortInput = {
  category?: InputMaybe<SortEnumType>;
  eAN?: InputMaybe<SortEnumType>;
  fulfillment?: InputMaybe<SortEnumType>;
  grandTotal?: InputMaybe<SortEnumType>;
  lineItemId?: InputMaybe<SortEnumType>;
  marketplace?: InputMaybe<SortEnumType>;
  orderCurrency?: InputMaybe<SortEnumType>;
  orderDate?: InputMaybe<SortEnumType>;
  orderId?: InputMaybe<SortEnumType>;
  otherTotal?: InputMaybe<SortEnumType>;
  partnerIdentity?: InputMaybe<SortEnumType>;
  productName?: InputMaybe<SortEnumType>;
  productTotal?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  shipCountry?: InputMaybe<SortEnumType>;
  shippedDate?: InputMaybe<SortEnumType>;
  shippingTotal?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SaleRevenueReportsConnection = {
  __typename?: 'SaleRevenueReportsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SaleRevenueReportsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SaleRevenueReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SaleRevenueReportsEdge = {
  __typename?: 'SaleRevenueReportsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SaleRevenueReport;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SaleRevenueReportsQueryVariables = Exact<{
  partnerId: Scalars['String'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SaleRevenueReportSortInput> | SaleRevenueReportSortInput>;
  filter?: InputMaybe<SaleRevenueReportFilterInput>;
}>;


export type SaleRevenueReportsQuery = { __typename?: 'Query', saleRevenueReports?: { __typename?: 'SaleRevenueReportsConnection', totalCount: number, nodes?: Array<{ __typename?: 'SaleRevenueReport', category?: string | null, eAN?: string | null, fulfillment?: string | null, grandTotal?: number | null, lineItemId?: string | null, marketplace?: string | null, orderCurrency?: string | null, orderId?: string | null, orderDate?: string | null, otherTotal?: number | null, partnerIdentity?: string | null, productName?: string | null, productTotal?: number | null, quantity?: number | null, shipCountry?: string | null, shippedDate?: string | null, shippingTotal?: number | null }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'SaleRevenueReportsEdge', cursor: string }> | null } | null };

export type SaleRevenueReportNodeFragment = { __typename?: 'SaleRevenueReport', category?: string | null, eAN?: string | null, fulfillment?: string | null, grandTotal?: number | null, lineItemId?: string | null, marketplace?: string | null, orderCurrency?: string | null, orderId?: string | null, orderDate?: string | null, otherTotal?: number | null, partnerIdentity?: string | null, productName?: string | null, productTotal?: number | null, quantity?: number | null, shipCountry?: string | null, shippedDate?: string | null, shippingTotal?: number | null };

export type PaginationMetaFragment = { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean };
