import React from 'react';
import { Skeleton, Stack } from '@mui/material';

export const UserProfileSkeleton: React.VFC = () => {
  return (
    <Stack gap="20px">
      <Skeleton height="50px" />
      <Skeleton height="50px" />
      <Skeleton height="50px" />
      <Skeleton height="50px" />
      <Skeleton height="50px" />
      <Skeleton height="50px" />
    </Stack>
  );
};
