import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components';
import { ExternalLinkIcon, SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Box, Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { OnboardingWizardContext, WizardFormStepType } from '../context/onboarding-wizard.provider';
import { useWizardGlobalsQuery } from '../hooks/globals';
import { useWizardVatAndShippingQuery } from '../hooks/vat-and-shipping';
import { useWizardVatAndShippingMutation } from '../hooks/vat-and-shipping/use-wizard-vat-and-shipping.mutation';
import { VATAndShippingTable } from './vat-and-shipping/vat-and-shipping-table';

export type VATAndShippingFormProps = {
  haveOptedUnionOSS: boolean;
  vatAndShipping: {
    countryCode: string;
    vatRegistered: boolean;
    defaultVAT: string;
    defaultCost: number | null;
  }[];
};

export const VatAndShippingWizardStep: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('wizard');
  const context = useContext(OnboardingWizardContext);
  const vatAndShippingQuery = useWizardVatAndShippingQuery();
  const vatAndShippingMutation = useWizardVatAndShippingMutation();
  const globalsQuery = useWizardGlobalsQuery();

  const { control, handleSubmit, setValue, formState } = useForm<VATAndShippingFormProps>({
    defaultValues: {
      haveOptedUnionOSS: false,
      vatAndShipping: [],
    },
  });

  const mainShippingCountry = globalsQuery.data?.mainShippingCountry;

  useEffect(() => {
    if (vatAndShippingQuery.isLoading || !vatAndShippingQuery.data) return;

    vatAndShippingQuery.data?.countries.forEach((x, i) => {
      setValue(`vatAndShipping.${i}`, {
        countryCode: x.countryCode,
        vatRegistered: mainShippingCountry == x.countryCode || x.isVatRegistered,
        defaultVAT: x.selectedVatRate || '',
        defaultCost: x.defaultShippingCost,
      });
    });

    setValue('haveOptedUnionOSS', !!vatAndShippingQuery.data?.hasVatOss);
  }, [vatAndShippingQuery.data]);

  const onSubmit = (values: VATAndShippingFormProps) => {
    vatAndShippingMutation.mutate(
      {
        hasVatOss: values.haveOptedUnionOSS,
        countries: values.vatAndShipping.map((x) => ({
          countryCode: x.countryCode,
          isVatRegistered: x.vatRegistered,
          selectedVatRate: x.vatRegistered ? x.defaultVAT : null,
          defaultShippingCost: x.defaultCost,
        })),
      },
      {
        onSuccess: () => {
          vatAndShippingQuery.refetch();
          context.goToFormStep(WizardFormStepType.products);
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent sx={{ maxWidth: '800px', maxHeight: '400px', overflow: 'auto' }}>
        <Box sx={{ p: 5, pb: 2, backgroundColor: 'background.paper' }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {t('set-vat-and-shipping-step.description')}
          </Typography>
          <Controller
            control={control}
            name="haveOptedUnionOSS"
            render={({ field }) => {
              return (
                <Checkbox
                  value={field.value}
                  checked={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={vatAndShippingMutation.isLoading}
                  label={
                    <Box component="span">
                      {t('form.haveOptedUnionOSS.label')}
                      <Box component="span">
                        <a href={t('form.haveOptedUnionOSS.url')} target="_blank" rel="noreferrer">
                          {t('form.haveOptedUnionOSS.labelCTA')}{' '}
                          <ExternalLinkIcon
                            sx={{
                              fontSize: '22px',
                              marginBottom: '-6px',
                            }}
                          />
                        </a>
                      </Box>
                    </Box>
                  }
                />
              );
            }}
          />
          <Box sx={{ mt: 4 }}>
            <VATAndShippingTable control={control} isLoading={vatAndShippingMutation.isLoading} />
          </Box>
        </Box>
      </DialogContent>
      {formState.errors?.vatAndShipping && (
        <Box sx={{ px: 5, pb: 2 }}>
          <Alert severity="error">{t('form.vatAndShipping.validation.required')}</Alert>
        </Box>
      )}
      {vatAndShippingMutation.error && (
        <Box sx={{ px: 5, pb: 2 }}>
          <Alert severity="error">{t('form.vatAndShipping.error')}</Alert>
        </Box>
      )}
      <DialogActions sx={{ p: 2, borderTop: '1px solid #DDE8F1' }}>
        {vatAndShippingMutation.isLoading && (
          <Stack justifyContent="center" sx={{ marginRight: 1 }}>
            <SpinnerIcon sx={{ fontSize: '43px', marginTop: '2px', marginBottom: '-2px' }} />
          </Stack>
        )}
        <Button
          variant="text"
          disabled={vatAndShippingQuery.isLoading || vatAndShippingMutation.isLoading}
          onClick={() => context.goToFormStep(WizardFormStepType.platforms)}
        >
          {tCommon('back')}
        </Button>
        <Button type="submit" disabled={vatAndShippingQuery.isLoading || vatAndShippingMutation.isLoading}>
          {tCommon('next')}
        </Button>
      </DialogActions>
    </form>
  );
};
