import { InputLabelClasses, InputLabelProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const inputLabelStyles: {
  defaultProps?: Partial<InputLabelProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof InputLabelClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      transform: 'none',
      fontSize: '14px',
      top: '6px',
      fontWeight: 500,
      left: '12px',
      marginLeft: 0,
      color: nethansaPalette.main.gray,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -20px) scale(1)',
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-disabled': {
        color: nethansaPalette.button.grayLight,
        backgroundColor: 'transparent',
      },
    },
  },
};
