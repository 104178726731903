import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Checkbox } from 'components';
import { TableCell } from '@mui/material';

type Props = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
};

export const CheckboxCell: React.VFC<Props> = (props) => {
  return (
    <TableCell sx={{ textAlign: 'center' }} align="center">
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <Checkbox label={''} checked={field.value} onChange={field.onChange} onBlur={field.onBlur} />
        )}
      />
    </TableCell>
  );
};
