import { TextFieldClasses, TextFieldProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const textFieldStyles: {
  defaultProps?: Partial<TextFieldProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TextFieldClasses>> | undefined;
} = {
  defaultProps: {
    InputLabelProps: {
      shrink: true,
      sx: {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(-12px, -26px) scale(1);',
          fontSize: '12px',
          fontWeight: 700,
          marginLeft: 0,
          lineHeight: '14.52px',
          letterSpacing: '0.22px',
          color: nethansaPalette.main.gray,
          textTransform: 'uppercase',
        },
      },
    },
  },
  styleOverrides: {
    root: {
      '& .MuiInputBase-root': {
        backgroundColor: nethansaPalette.main.light,
        minHeight: '35px',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 500,
        borderRadius: '6px',
        padding: '5px 10px',
        '&.Mui-disabled': {
          '& + .MuiOutlinedInput-notchedOutline': {
            borderColor: nethansaPalette.button.grayLight,
          },
        },
        '& + .MuiOutlinedInput-notchedOutline': {
          top: 0,
          legend: {
            display: 'none',
          },
        },
      },
      '.MuiFormHelperText-root': {
        position: 'absolute',
        top: 'calc(100%)',
        right: '-14px',
      },
      '& .Mui-disabled': {
        background: nethansaPalette.background.title,
        '& .MuiInputAdornment-root': {
          '& .MuiTypography-root': {
            color: nethansaPalette.button.grayLight,
          },
        },
      },

      '& .MuiInputBase-adornedStart': {
        paddingLeft: '8px',
        input: {
          paddingLeft: 0,
          width: '100%',
          textAlign: 'right',
        },
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '8px',
        input: {
          paddingRight: 0,
        },
        '& .MuiInputAdornment-positionEnd': {
          cursor: 'pointer',
          svg: {
            fontSize: '16px',
            fontWeight: 700,
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(-12px, -22px) scale(1);',
        fontSize: '12px',
        fontWeight: 700,
        marginLeft: 0,
        lineHeight: '14.52px',
        letterSpacing: '0.22px',
        color: nethansaPalette.main.gray,
        textTransform: 'uppercase',
      },
      '& .MuiOutlinedInput-root': {
        minHeight: '40px',
      },
    },
  },
};
