import { DividerClasses, DividerProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const dividerStyles: {
  defaultProps?: Partial<DividerProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof DividerClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      backgroundColor: nethansaPalette.line.light,
      borderColor: nethansaPalette.line.light,
    },
  },
};
