import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';
import omitBy from 'lodash.omitby';
import { PlatformCode } from 'modules/shared/types';
import { useAuth } from '../../authorization';

type InputType = {
  sellerId?: string;
  mwsAuthToken?: string;
  clientKey?: string;
  secretKey?: string;
};

type PlatformError = { response: { data: { errorCode: string; errorMessage: string } } };

export const usePlatformConnectMutation = (
  platformCode: PlatformCode,
): UseMutationResult<void, PlatformError, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const requestTestConnectionBody: Partial<InputType> = omitBy(
      {
        sellerId: body.sellerId,
        mwsAuthToken: body.mwsAuthToken,
        clientKey: body.clientKey,
        secretKey: body.secretKey,
      },
      (val) => !val,
    );

    await httpClient.post<null, { errorCode: string; errorMessage: string }, InputType>(
      `/v1/wizard/steps/platforms/${platformCode}/test-connection?partnerId=${currentPartnerId}`,
      requestTestConnectionBody,
    );

    // for time being platform data are saved by api in db after successful test call

    // const requestConnectBody: Partial<InputType> = omitBy(
    //   {
    //     sellerId: body.sellerId,
    //     mwsAuthToken: body.mwsAuthToken,
    //     clientKey: body.clientKey,
    //     secretKey: body.secretKey,
    //     connectionStatus: 'connected',
    //     connectionTimestamp: new Date().toISOString()
    //   },
    //   (val) => !val,
    // );

    // await httpClient.put<null, null, InputType>(`/steps/platforms/${platformCode}`, requestConnectBody);

    return;
  });
};
