import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';

type InputType = {
  hasVatOss: boolean;
  countries: {
    countryCode: string;
    isVatRegistered: boolean;
    selectedVatRate: string | null;
    defaultShippingCost: number | null;
  }[];
};

export const useWizardVatAndShippingMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.put('/v1/wizard/steps/vat-and-shipping', body);
    return;
  });
};
