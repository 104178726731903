import { useQuery, UseQueryResult } from 'react-query';
import { httpClient } from 'common/api';
import { ProductImportWizardType } from '../../types/product-import-wizard.type';

export const useWizardProductsQuery = (): UseQueryResult<ProductImportWizardType> => {
  return useQuery<ProductImportWizardType>(
    ['wizard', 'products'],
    async () => {
      const response = await httpClient.get<ProductImportWizardType>('/v1/wizard/steps/products');

      return response.data;
    },
    {
      staleTime: 5000,
      keepPreviousData: true,
    },
  );
};
