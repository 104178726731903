import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';
import { useAuth } from '../../authorization';

type FieldTypeInput = {
  code: string;
  isManagedByClipperon: boolean;
};

type InputType = FieldTypeInput[];

export const usePlatformMarketsMutation = (platformCode: PlatformCode): UseMutationResult<void, Error, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    await httpClient.put<void, Error, InputType>(
      `/v1/wizard/steps/platforms/${platformCode}/marketplaces?partnerId=${currentPartnerId}`,
      body,
    );
    return;
  });
};
