import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardForm } from 'components';
import { Box, Typography } from '@mui/material';
import { MainSettings } from './components/main-settings';
import { MarketplaceSettings } from './components/marketplace-settings';
import { PlatformConnectorsCoordinator } from './components/platform-connectors-coordinator';
import { PlatformsTabs } from './components/platforms-tabs';
import { PlatformsContextProvider } from './context/platforms.context';

export const PlatformsPage: React.VFC = () => {
  const [t] = useTranslation('platforms');

  return (
    <PlatformsContextProvider>
      <PlatformsTabs />
      <PlatformConnectorsCoordinator />
      <CardForm fullWidth sx={{ maxWidth: '1088px' }}>
        <Typography variant="h4" textTransform="uppercase" fontSize="12px" sx={{ color: 'nethansa.main.gray', pb: 4 }}>
          {t('page.section.main-settings')}
        </Typography>
        <Box sx={{ px: 2 }}>
          <MainSettings />
        </Box>
      </CardForm>
      <CardForm fullWidth sx={{ pt: 0, maxWidth: '1088px' }}>
        <Typography variant="h4" textTransform="uppercase" fontSize="12px" sx={{ color: 'nethansa.main.gray', pb: 4 }}>
          {t('page.section.marketplace-settings')}
        </Typography>
        <Box sx={{ px: 2 }}>
          <MarketplaceSettings />
        </Box>
      </CardForm>
    </PlatformsContextProvider>
  );
};
