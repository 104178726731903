import React, { ReactElement } from 'react';
import { nethansaPalette } from 'theme';
import { Box, Link, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  icon: ReactElement;
  title: string;
  disabled?: boolean;
  links: {
    label: string;
    href: string;
  }[];
};

export const SettingCardItem: React.VFC<Props> = (props) => {
  const root: SxProps<Theme> = {
    maxWidth: '479px',
    width: '100%',
    height: '170px',
    opacity: props.disabled ? 0.4 : 1,
    pointerEvents: props.disabled ? 'none' : 'all',
    boxShadow: `0px 3px 5px ${nethansaPalette.line.light}`,
  };

  return (
    <Stack sx={root} direction="row">
      <Stack
        sx={{
          width: '79px',
          backgroundColor: 'nethansa.line.light',
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
        }}
        alignItems="center"
        justifyContent="center"
      >
        {props.icon}
      </Stack>
      <Box
        sx={{
          flex: 1,
          p: 2,
          backgroundColor: 'common.white',
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
        }}
      >
        <Typography
          sx={{ color: 'nethansa.main.gray', textTransform: 'uppercase', fontSize: '12px', fontWeight: 'bold' }}
        >
          {props.title}
        </Typography>
        <Stack
          sx={{
            mt: 2,
            '& > a': {
              mb: 1.3,
              '&:last-of-type': {
                mb: 0,
              },
            },
          }}
        >
          {props.links.map((link) => (
            <Link key={link.href} href={link.href}>
              <Typography variant="h3">{link.label}</Typography>
            </Link>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};
