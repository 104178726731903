import React, { useContext } from 'react';
import { CoordinatorContext } from 'modules/coordinator';
import { DialogTitle as MuiDialogTitle, Stack, Typography } from '@mui/material';
import { CloseButton } from '../close-button/close-button';

export interface DialogTitleProps {
  title?: string;
  lightMode?: boolean;
  spacing?: 'md' | 'lg';
  onClose: () => void;
}

export const DialogHeader: React.FC<DialogTitleProps> = (props) => {
  const { title, onClose, lightMode, spacing } = props;
  const coordinatorContext = useContext(CoordinatorContext);

  return (
    <MuiDialogTitle
      sx={{
        m: 0,
        p: 0,
        backgroundColor: (theme) => (lightMode ? 'transparent' : theme.palette.flat.dark),
      }}
    >
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: spacing === 'lg' ? 3 : '15.5px 16px' }}
      >
        <Typography variant="h3" sx={{ color: (theme) => (lightMode ? 'unset' : theme.palette.common.white) }}>
          {title}
        </Typography>
        {onClose && coordinatorContext.showCloseButton && <CloseButton onClick={onClose} whiteIcon={!lightMode} />}
      </Stack>
    </MuiDialogTitle>
  );
};
