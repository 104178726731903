import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const tableStyles: SxProps<Theme> = {
  width: 'auto',
  '& .MuiTableHead-root': {
    position: 'relative',
    '& .MuiTableRow-head': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'nethansa.line.light',
    },
  },
  '& .MuiTableBody-root': {
    '& .MuiTableRow-root': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'nethansa.line.light',
      '&:hover': {
        backgroundColor: 'nethansa.other.pale',
        transition: 'background-color .2s',
      },
      '& .MuiTableCell-body': {
        px: 0.5,
        py: 1.5,
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '18px',
        border: 'none',
        '&:first-of-type': {
          pl: 1,
          textAlign: 'left',
        },
        '&:last-of-type': {
          pr: 0,
        },
      },
      '&:nth-of-type(odd)': {
        '& .MuiTableCell-body': {
          backgroundColor: 'unset',
        },
      },
    },
  },
};
