import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
  crossed?: boolean;
};

export const EyeIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99998 2.99999C8.70557 3.00459 8.41334 3.05137 8.13221 3.13888C8.26225 3.36758 8.33149 3.6258 8.33332 3.88888C8.33332 4.09316 8.29308 4.29544 8.21491 4.48417C8.13673 4.6729 8.02215 4.84438 7.8777 4.98883C7.73326 5.13327 7.56178 5.24785 7.37305 5.32603C7.18432 5.4042 6.98204 5.44444 6.77776 5.44444C6.51468 5.44261 6.25646 5.37337 6.02776 5.24333C5.84733 5.8691 5.86836 6.53579 6.08787 7.14895C6.30739 7.76211 6.71426 8.29066 7.25086 8.65973C7.78746 9.0288 8.42658 9.21968 9.0777 9.20534C9.72881 9.19099 10.3589 8.97215 10.8787 8.5798C11.3986 8.18745 11.7818 7.6415 11.9741 7.01927C12.1663 6.39703 12.158 5.73007 11.9502 5.11285C11.7424 4.49562 11.3456 3.95943 10.8161 3.58023C10.2866 3.20103 9.65125 2.99803 8.99998 2.99999V2.99999ZM16.9033 5.70555C15.3969 2.76638 12.4147 0.777771 8.99998 0.777771C5.58526 0.777771 2.60221 2.76777 1.09665 5.70583C1.03311 5.83152 1 5.9704 1 6.11124C1 6.25209 1.03311 6.39096 1.09665 6.51666C2.60304 9.45583 5.58526 11.4444 8.99998 11.4444C12.4147 11.4444 15.3978 9.45444 16.9033 6.51638C16.9669 6.39069 17 6.25181 17 6.11097C17 5.97012 16.9669 5.83125 16.9033 5.70555V5.70555ZM8.99998 10.1111C6.25971 10.1111 3.74748 8.58333 2.39082 6.1111C3.74748 3.63888 6.25943 2.1111 8.99998 2.1111C11.7405 2.1111 14.2525 3.63888 15.6091 6.1111C14.2528 8.58333 11.7405 10.1111 8.99998 10.1111Z"
        fill="#8B8B8D"
      />
      {props.crossed && (
        <path d="M1 2L17 11" stroke="#8B8B8D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      )}
    </SvgIcon>
  );
};
