import React, { useContext } from 'react';
import { PlatformsConnectorContext } from '../context/platfroms-modal.provider';
import { AccountInformation } from './amazon/account-information';
import { MarketsSettings } from './amazon/markets-settings';

export const AmazonConnector: React.FC = () => {
  const context = useContext(PlatformsConnectorContext);

  const steps: { [key: string]: JSX.Element } = {
    0: <AccountInformation />,
    1: <MarketsSettings />,
  };

  return <>{steps[context.step] || null}</>;
};
