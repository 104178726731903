import { useMutation, UseMutationResult } from 'react-query';
import { envelope, httpClient } from 'common/api';
import { ErrorMeta } from 'modules/shared/types';

type InputType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const useChangePasswordMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  return useMutation(async (body) => {
    return await envelope(httpClient.put(`/v1/settings/Profiles/password`, body));
  });
};
