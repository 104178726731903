import * as React from 'react';
import { ChangeEvent, LegacyRef, useEffect, useMemo, useState } from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { useDebounce } from '@react-hook/debounce';

type Props = TablePaginationActionsProps & {
  getItemAriaLabel: (type: 'first' | 'last' | 'next' | 'previous', page?: number) => string;
};

const DataGridPaginationActions = React.forwardRef<LegacyRef<HTMLDivElement> | undefined, Props>(
  function TablePaginationActions(props, ref) {
    const {
      backIconButtonProps,
      count,
      getItemAriaLabel,
      nextIconButtonProps,
      onPageChange,
      page,
      rowsPerPage,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showLastButton = {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showFirstButton = {},
      ...other
    } = props;

    const theme = useTheme();

    const [localPage, setLocalPage] = useState<number | null>(page || 0);
    const [debouncedPage, setDebouncedPage] = useDebounce(page || 0, 500);

    const totalPages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);

    const handleBackButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
      if (localPage !== null) {
        setLocalPage(localPage - 1);
        onPageChange(null, localPage === 0 ? 0 : localPage - 1);
      }
    };

    const handleNextButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
      if (localPage !== null) {
        setLocalPage(localPage + 1);
        onPageChange(null, localPage + 1);
      }
    };

    const handleChangePage = (event: ChangeEvent<HTMLInputElement>) => {
      const nextPage = parseInt(event.target.value || '1') - 1;
      setLocalPage(event.target.value ? nextPage : null);
    };

    const handleBlurPage = () => {
      if (localPage === null) return;
      if (localPage >= 0 && localPage < totalPages) return;
      setLocalPage(localPage < 0 ? 0 : totalPages - 1);
    };

    useEffect(() => {
      if (localPage === null) return;
      if (localPage >= 0 && localPage < totalPages) {
        setDebouncedPage(localPage);
        return;
      }
      setDebouncedPage(localPage < 0 ? 0 : totalPages < 1 ? 0 : totalPages - 1);
    }, [localPage]);

    useEffect(() => {
      onPageChange(null, debouncedPage);
    }, [debouncedPage]);

    return (
      <Stack ref={ref} {...other} direction="row" alignItems="center">
        <IconButton
          onClick={handleBackButtonClick}
          disabled={localPage === 0}
          color="inherit"
          aria-label={getItemAriaLabel('previous', page || 0)}
          title={getItemAriaLabel('previous', page || 0)}
          {...backIconButtonProps}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <TextField
            type="number"
            variant="outlined"
            value={localPage === null ? '' : localPage + 1}
            onChange={handleChangePage}
            onBlur={handleBlurPage}
            disabled={totalPages <= 1}
            InputProps={{
              sx: {
                '& input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                  },
                  MozAppearance: 'textfield',
                  textAlign: 'right',
                },
              },
              inputProps: {
                min: 0,
                max: totalPages,
              },
            }}
            sx={{
              width: '64px',
              mr: 1,
              '& .MuiOutlinedInput-root': {
                minHeight: '32px',
                borderRadius: '6px',
              },
            }}
          />
          <Typography sx={{ fontSize: '14px' }}>of</Typography>
          <Typography sx={{ fontSize: '14px' }}>{totalPages}</Typography>
        </Stack>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page && count !== -1 ? page >= Math.ceil(count / rowsPerPage) - 1 : false}
          color="inherit"
          aria-label={getItemAriaLabel('next', page || 0)}
          title={getItemAriaLabel('next', page || 0)}
          {...nextIconButtonProps}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </Stack>
    );
  },
);

export default DataGridPaginationActions;
