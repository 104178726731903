import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { applyExternalAccess } from 'common/utils';
import { SplashScreen } from 'components';
import { LoginModal, useAuth } from 'modules/authorization';
import { CoordinatorPage } from 'modules/coordinator';
import { Box } from '@mui/material';
import { RootRouter } from './routes';

export const App: React.FC = () => {
  const { i18n } = useTranslation();
  const { initialized, authorized } = useAuth();

  useEffect(() => {
    applyExternalAccess({ changeLanguage: (lang: string) => i18n.changeLanguage(lang).then() });
  }, []);

  return (
    <Box>
      {initialized && authorized && (
        <>
          <RootRouter />
          <CoordinatorPage />
          <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        </>
      )}
      <SplashScreen out={initialized} />
      {initialized && !authorized && <LoginModal />}
    </Box>
  );
};
