import React, { useCallback, useContext } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, DialogActions, DialogContent, MenuItemProps, Stack, Typography } from '@mui/material';
import { OnboardingWizardContext, WizardFormStepType } from '../context/onboarding-wizard.provider';
import { useProductsWizardMutation } from '../hooks/products/use-products-wizard.mutation';
import { FileOption } from './products/file-option';
import { ImportDirectlyOption } from './products/import-directly-option';
import { OtherOption } from './products/other-option';

type ImportType = 'FROM_FILE' | 'DIRECTLY_FROM_AMAZON' | 'DIRECTLY_FROM_KAUFLAND' | 'OTHER';

export type AddProductsFormProps = {
  importType?: ImportType | string;
  fileToUpload?: File;
  fileHasBeenUploaded?: boolean;
  externalImportStarted?: boolean;
};

export const ProductsWizardStep: React.FC = () => {
  const [t] = useTranslation('wizard');
  const { tCommon } = useCommonTranslations();
  const context = useContext(OnboardingWizardContext);
  const productsMutation = useProductsWizardMutation();

  const { control, handleSubmit, setError, getValues } = useForm<AddProductsFormProps>({
    defaultValues: {
      importType: '',
      fileToUpload: undefined,
      fileHasBeenUploaded: false,
    },
  });

  const importTypeValue = useWatch({
    control,
    name: 'importType',
  });

  const externalImportStartedValue = useWatch({
    control,
    name: 'externalImportStarted',
  });

  const handleBackClick = useCallback(() => {
    context.goToFormStep(WizardFormStepType['vat-and-shipping']);
  }, []);

  const submit = (values: AddProductsFormProps) => {
    productsMutation.mutate(
      {
        importMethod: values.importType || '',
      },
      {
        onSuccess: () => {
          context.goToFormStep(WizardFormStepType.summary);
        },
      },
    );
  };

  const views: { [key in ImportType]: JSX.Element | null } = {
    FROM_FILE: <FileOption control={control} getValues={getValues} />,
    DIRECTLY_FROM_AMAZON: <ImportDirectlyOption provider="amzeu" control={control} setError={setError} />,
    DIRECTLY_FROM_KAUFLAND: <ImportDirectlyOption provider="kauf" control={control} setError={setError} />,
    OTHER: <OtherOption />,
  };

  const selectOptions: MenuItemProps<'li', { value: ImportType }>[] = [
    { value: 'FROM_FILE', children: t('add-products.select.from-file') },
    { value: 'DIRECTLY_FROM_AMAZON', children: t('add-products.select.amazon'), disabled: true },
    { value: 'DIRECTLY_FROM_KAUFLAND', children: t('add-products.select.kaufland'), disabled: true },
    { value: 'OTHER', children: t('add-products.select.other') },
  ];

  return (
    <form onSubmit={handleSubmit(submit)}>
      <DialogContent>
        <Box sx={{ py: 5, backgroundColor: 'background.paper', px: 5 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            {t('add-products.header')}
          </Typography>

          <Controller
            control={control}
            name="importType"
            rules={{
              required: {
                value: true,
                message: t('form.importType.validation.required'),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <SelectField
                  disabled={productsMutation.isLoading || !!externalImportStartedValue}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  tooltipContent={t('form.importType.tooltip.__html')}
                  placeholder={t('form.importType.placeholder')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  options={selectOptions}
                  sx={{ mb: 2, ml: 0, minWidth: '400px' }}
                />
              );
            }}
          />
          {views[importTypeValue as ImportType] || null}
        </Box>
        <DialogActions sx={{ p: 2, borderTop: '1px solid #DDE8F1' }}>
          {productsMutation.isLoading && (
            <Stack justifyContent="center" sx={{ marginRight: 1 }}>
              <SpinnerIcon sx={{ fontSize: '43px', marginTop: '2px', marginBottom: '-2px' }} />
            </Stack>
          )}
          <Button variant="text" disabled={productsMutation.isLoading} onClick={handleBackClick}>
            {tCommon('back')}
          </Button>
          <Button type="submit" disabled={productsMutation.isLoading}>
            {tCommon('next')}
          </Button>
        </DialogActions>
      </DialogContent>
    </form>
  );
};
