import React, { ReactElement, useMemo } from 'react';
import { CarrierCode } from 'modules/shared/types';
import { Divider, Stack, Typography } from '@mui/material';
import { useCarriersListQuery } from '../../hooks/use-carriers-list.query';
import { useIntegrationContext } from '../../hooks/use-integration-context';
import { DHLPolandModalForm } from './carriers-form-modals/dhl-poland-modal-form';
import { EurohermesModalForm } from './carriers-form-modals/eurohermes-modal-form';
import { GLSModalForm } from './carriers-form-modals/gls-modal-form';
import { UPSModalForm } from './carriers-form-modals/ups-modal-form';
import { DisconnectCarrierModal } from './diconnect-carrier-modal';
import { ManageButton } from './manage-button';

export const IntegrationsList: React.VFC = () => {
  const { data } = useCarriersListQuery();

  const {
    connector: [connectorCarrierCode],
    disconnectCarrier: [disconnectCarrierCode, setDisconnectCarrierCode],
  } = useIntegrationContext();

  const modals = useMemo<{ [key in CarrierCode]: ReactElement }>(
    () => ({
      dhl: <DHLPolandModalForm />,
      gls: <GLSModalForm />,
      ups: <UPSModalForm />,
      eurohermes: <EurohermesModalForm />,
    }),
    [],
  );

  return (
    <>
      <Stack
        spacing={2}
        divider={<Divider sx={{ width: '100%', backgroundColor: 'nethansa.line.light', opacity: 0.5 }} />}
      >
        {data?.data.map((x) => (
          <Stack key={x.id} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2" color={!x.connected ? 'nethansa.main.gray' : 'text.main'}>
              {x.name}
            </Typography>
            <ManageButton connected={x.connected} carrierCode={x.code} />
          </Stack>
        ))}
      </Stack>
      {connectorCarrierCode && modals[connectorCarrierCode]}
      <DisconnectCarrierModal
        open={!!disconnectCarrierCode}
        carrierCode={disconnectCarrierCode}
        onClose={() => setDisconnectCarrierCode(undefined)}
      />
    </>
  );
};
