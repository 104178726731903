import React, { useEffect, useRef, useState } from 'react';
import { Alert, Collapse } from '@mui/material';

type Props = {
  show: boolean;
  isSuccess: boolean;
  isError: boolean;
  successMessage?: string | React.ElementType;
  errorMessage?: string | React.ElementType;
  duration?: number;
};

export const FormAlert: React.VFC<Props> = (props) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.show) setShow(true);

    setTimeout(() => {
      if (!ref.current) return;
      setShow(false);
    }, props.duration || 5000);
  }, [props.show, props.duration]);

  const color = props.isSuccess ? 'success' : props.isError ? 'error' : undefined;

  const message = props.isSuccess ? props.successMessage : props.isError ? props.errorMessage : undefined;

  return (
    <Collapse in={show} ref={ref}>
      <Alert color={color} sx={{ mb: 1 }}>
        {message}
      </Alert>
    </Collapse>
  );
};
