import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';

type InputType = {
  platformCode: string;
};

export const useWizardPlatformDeleteMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.delete(`/v1/wizard/steps/platforms/${body.platformCode}`);
    return;
  });
};
