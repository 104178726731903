import React from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { SettingsMenu } from './components/settings-menu';

export const SettingsLayout: React.VFC = () => {
  return (
    <Stack direction="row">
      <SettingsMenu />
      <Outlet />
    </Stack>
  );
};
