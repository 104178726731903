import { useQuery, UseQueryResult } from 'react-query';
import { httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';
import { PlatformDataWizardType } from '../../types/platform-wizard.type';

type ResponseType = {
  platformCode: 'amzeu' | 'kauf';
  connectionStatus: 'notAttempted' | 'connected' | 'failed';
  sellerId?: string;
  mwsAuthToken?: string;
  clientKey?: string;
  secretKey?: string;
  connectionTimestamp: string | null;
  connectionError: string | null;
}[];

// const mockedData: ResponseType = [
//   {
//     platformCode: 'AMZEU',
//     sellerId: 'A1BCRPEAUUGCNR',
//     mwsAuthToken: 'amzn.mws.3b73ceb1-cc1b-d63d-ae74-5bd68208358f',
//     connectionStatus: 'notAttempted',
//     connectionTimestamp: null,
//     connectionError: null,
//   },
//   {
//     platformCode: 'KAUF',
//     clientKey: '5a143aae6de113ad044944cb2c85ff4a',
//     secretKey: 'ce2f3483fc13b73ed8aa00e7da6dae1337ffd0ab84fd6b30fa2565e3378279c4',
//     connectionStatus: 'failed',
//     connectionTimestamp: '2021-07-21T17:32:28Z',
//     connectionError: 'Invalid key',
//   },
// ];

export const useWizardPlatformsQuery = (): UseQueryResult<PlatformDataWizardType[]> => {
  return useQuery<PlatformDataWizardType[]>(
    ['wizard', 'platforms'],
    async () => {
      const response = await httpClient.get<ResponseType>('/v1/wizard/steps/platforms');
      return response.data.map((x) => ({
        ...x,
        platformCode: String(x.platformCode).toLowerCase() as PlatformCode,
      }));
    },
    {
      staleTime: 5000,
    },
  );
};
