import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { TextFieldWithTooltip } from 'components';
import { useCountriesTranslations } from 'modules/shared';
import { CountryCode } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

type Props = {
  name: string;
  countries: CountryCode[] | [];
  availableCountries: { value: CountryCode; label: string }[] | [];
  addCountry: (countryCode: CountryCode) => void;
  removeCountry: (countryCode: CountryCode) => void;
};

type RenderItemOptions = {
  name: string;
  code: CountryCode;
  handleRemove: (countryCode: CountryCode) => void;
};

function renderItem({ name, code, handleRemove }: RenderItemOptions) {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="delete" title="Delete" onClick={() => handleRemove(code)}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText>
        <Typography variant="h5">{name}</Typography>
      </ListItemText>
    </ListItem>
  );
}

export const OtherCarrierField: React.VFC<Props> = (props) => {
  const [tCountries] = useCountriesTranslations();

  return (
    <Stack>
      <Stack sx={{ mt: 2 }}>
        <Typography variant="h2">{props.name}</Typography>
        <List
          sx={{
            '& .MuiCollapse-root': {
              '&:nth-of-type(odd)': {
                backgroundColor: 'nethansa.background.title',
              },
            },
          }}
        >
          <TransitionGroup>
            {props.countries.map((country) => (
              <Collapse key={country}>
                {renderItem({ code: country, name: tCountries(country), handleRemove: props.removeCountry })}
              </Collapse>
            ))}
          </TransitionGroup>
        </List>

        <Autocomplete
          disablePortal
          clearIcon={null}
          size="small"
          fullWidth
          freeSolo={false}
          onChange={(_, value) => {
            console.log(value);
            if (!value?.value) return;
            props.addCountry(value?.value as unknown as CountryCode);
          }}
          value={undefined}
          id="combo-box-demo"
          options={props.availableCountries}
          renderInput={(params) => {
            return (
              <TextFieldWithTooltip
                {...params}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                  sx: {
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(-12px, -26px) scale(1);',
                      fontSize: '12px',
                      fontWeight: 700,
                      marginLeft: 0,
                      lineHeight: '14.52px',
                      letterSpacing: '0.22px',
                      color: nethansaPalette.main.gray,
                      textTransform: 'uppercase',
                    },
                  },
                }}
                fullWidth
                sx={{ mt: 3, mb: 2, mx: 0 }}
                value=""
                placeholder="Select a country "
                label="Add country"
              />
            );
          }}
        />
      </Stack>
      <Divider />
    </Stack>
  );
};
