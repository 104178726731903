import { RouteParams } from '../types';

export function getRouteObject<Params>(path: string): RouteParams<Params> {
  const split = path.split('/');
  const name = split[split.length - 1];

  const link = (params?: Params): string => {
    if (!params) {
      return path;
    }
    let pathRaw: string = path;
    Object.entries(params).forEach(([key, value]) => {
      pathRaw = pathRaw.replaceAll(`:${key}`, value);
    });
    return pathRaw;
  };

  return {
    name: name,
    path: path,
    link: link,
  };
}
