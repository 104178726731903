import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertLoader } from 'components';
import { KauflandLogo } from 'components/logos/kaufland.logo';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { PlatformsConnectorContext } from '../context/platfroms-modal.provider';
import { usePlatformConnectMutation } from '../hooks/use-platform-test.mutation';
import { usePlatformQuery } from '../hooks/use-platform.query';
import { ConnectorHeader } from './connector-header';

type FormProps = {
  clientKey: string;
  secretKey: string;
};

export const KauflandConnector: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const platformData = usePlatformQuery('kauf');
  const platformConnectMutation = usePlatformConnectMutation('kauf');

  const context = useContext(PlatformsConnectorContext);
  const { control, handleSubmit, setValue } = useForm<FormProps>({
    defaultValues: {
      clientKey: platformData.data?.clientKey || '',
      secretKey: platformData.data?.secretKey || '',
    },
  });

  const submit = (values: FormProps) => {
    platformConnectMutation.mutate({
      clientKey: values.clientKey,
      secretKey: values.secretKey,
    });
  };

  useEffect(() => {
    if (platformData.data?.clientKey && platformData.data?.secretKey) {
      setValue('clientKey', platformData.data?.clientKey || '');
      setValue('secretKey', platformData.data?.secretKey || '');
    }
  }, [platformData.data?.clientKey, platformData.data?.secretKey]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box sx={{ p: 4 }}>
        <ConnectorHeader logo={<KauflandLogo />} />
        <Box sx={{ mt: 4 }}>
          <Typography sx={{ mb: 3 }} variant="h2">
            {t('kaufland.title')}
          </Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            {t('kaufland.subtitle.leftSide')}
            <a style={{ margin: '0 3.5px' }} href={t('kaufland.subtitle.linkHref')} target="_blank" rel="noreferrer">
              {t('kaufland.subtitle.linkText')}
            </a>
            {t('kaufland.subtitle.rightSide')}
          </Alert>
        </Box>
        <Stack gap="30px" sx={{ mt: 6 }}>
          <Controller
            control={control}
            name="clientKey"
            rules={{
              required: {
                value: true,
                message: t('kaufland.form.client-key.validation.required'),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  disabled={
                    platformConnectMutation.isSuccess || platformConnectMutation.isLoading || platformData.isLoading
                  }
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label={t('kaufland.form.client-key.label')}
                  placeholder={t('kaufland.form.client-key.placeholder')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 2, flex: 1 }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="secretKey"
            rules={{
              required: {
                value: true,
                message: t('kaufland.form.secret-key.validation.required'),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  disabled={
                    platformConnectMutation.isSuccess || platformConnectMutation.isLoading || platformData.isLoading
                  }
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label={t('kaufland.form.secret-key.label')}
                  placeholder={t('kaufland.form.secret-key.placeholder')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ flex: 2.5 }}
                />
              );
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ px: 4, pb: 2 }}>
        {platformConnectMutation.isSuccess && <Alert severity="success">{t('kaufland.integrate.success')}</Alert>}
        {platformConnectMutation.isLoading && <AlertLoader>{t('kaufland.integrate.loading')}</AlertLoader>}
        {platformConnectMutation.isError && (
          <Alert severity="error">
            <Stack>
              <Box sx={{ fontWeight: 'bold' }}>{platformConnectMutation.error?.response.data.errorCode}</Box>
              <Box sx={{ fontWeight: 'normal' }}>{platformConnectMutation.error?.response.data.errorMessage}</Box>
            </Stack>
          </Alert>
        )}
      </Box>
      <Stack direction="row" justifyContent="flex-end" gap="10px" sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}>
        {!platformConnectMutation.isSuccess && (
          <Button variant="text" size="small" onClick={context.close} disabled={platformConnectMutation.isLoading}>
            {tCommon('cancel')}
          </Button>
        )}
        {!platformConnectMutation.isSuccess && (
          <Button size="small" type="submit" disabled={platformConnectMutation.isLoading}>
            {tCommon('connect')}
          </Button>
        )}
        {platformConnectMutation.isSuccess && (
          <Button size="small" onClick={context.close}>
            {tCommon('ok')}
          </Button>
        )}
      </Stack>
    </form>
  );
};
