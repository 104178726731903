import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { nethansaPalette } from '../../theme';

type Props = {
  sx?: SxProps<Theme>;
};

export const CheckedIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.834L3.5 7L9 1"
        stroke={nethansaPalette.main.dark}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
