import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const dataGridStyles: SxProps<Theme> = {
  border: 'none',
  '& .MuiDataGrid-columnHeader': {
    minHeight: '66px',
    backgroundColor: '#EDEDF7',
    '* .MuiDataGrid-columnHeaderTitleContainer': {
      minHeight: '66px',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '15.73px',
      letterSpacing: '0.5px',
    },
  },
  '& .MuiDataGrid-row': {
    backgroundColor: 'common.white',
    '&:nth-of-type(odd)': {
      backgroundColor: 'common.white',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'nethansa.background.title',
    },
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: 'common.white',
    '& .MuiTablePagination-select': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'nethansa.button.grayLight',
    },
  },
};
