import { getRouteObject } from './helpers';

const settingsPathBase = '/Settings/New';

export const routes = {
  Stylebook: getRouteObject('/Stylebook'),
  Reports: {
    ...getRouteObject('/Reports'),
    children: {
      SalesRevenue: getRouteObject('/Reports/SalesRevenue'),
      OrderReturns: getRouteObject('/Reports/OrderReturns'),
      Taxes: getRouteObject('/Reports/Taxes'),
    },
  },
  Settings: {
    ...getRouteObject(`${settingsPathBase}`),
    children: {
      SalesIntelligence: {
        ...getRouteObject(`${settingsPathBase}/SalesIntelligence`),
        children: {
          Platforms: getRouteObject(`${settingsPathBase}/SalesIntelligence/Platforms`),
          VatRates: getRouteObject(`${settingsPathBase}/SalesIntelligence/VatRates`),
        },
      },
      Profile: {
        ...getRouteObject(`${settingsPathBase}/Profile`),
        children: {
          ChangePassword: getRouteObject(`${settingsPathBase}/Profile/ChangePassword`),
          Security: getRouteObject(`${settingsPathBase}/Profile/Security`),
        },
      },
      Carriers: {
        ...getRouteObject(`${settingsPathBase}/Carriers`),
        children: {
          ShippingCountry: getRouteObject(`${settingsPathBase}/Carriers/ShippingCountry`),
        },
      },
    },
  },
};
