import { TFunction } from 'i18next';
import { PlatformFieldType } from '../types/platforms.type';

// Required for parser
// const [t] = useTranslation('platforms');

export const generalPlatformFields = (t: TFunction): PlatformFieldType[] => [
  {
    name: 'priceManagement',
    type: 'checkbox',
    title: t('form.priceManagement.label'),
    tooltip: t('form.priceManagement.tooltip.__html'),
  },
  {
    name: 'automaticDownloadOffers',
    type: 'checkbox',
    title: t('form.automaticDownloadOffers.label'),
    tooltip: t('form.automaticDownloadOffers.tooltip.__html'),
  },
  {
    name: 'automaticOffersCreation',
    type: 'checkbox',
    title: t('form.automaticOffersCreation.label'),
    tooltip: t('form.automaticOffersCreation.tooltip.__html'),
  },
  {
    name: 'nethansaCommission',
    type: 'number',
    title: t('form.nethansaCommission.label'),
    tooltip: t('form.nethansaCommission.tooltip.__html'),
    disabled: true,
    unit: '%',
  },
  {
    name: 'returnsReserve',
    type: 'number',
    title: t('form.returnsReserve.label'),
    tooltip: t('form.returnsReserve.tooltip.__html'),
    unit: '%',
    precision: 2,
    rules: {
      required: {
        value: true,
        message: 'Field is required',
      },
    },
  },
  {
    name: 'shippingCost',
    type: 'number',
    title: t('form.shippingCost.label'),
    tooltip: t('form.shippingCost.tooltip.__html'),
    unit: 'zł',
    precision: 2,
    rules: {
      required: {
        value: true,
        message: 'Field is required',
      },
    },
  },
  {
    name: 'handlingTimeInDays',
    type: 'number',
    title: t('form.handlingTimeInDays.label'),
    tooltip: t('form.handlingTimeInDays.tooltip.__html'),
    unit: '',
    rules: {
      required: {
        value: true,
        message: 'Field is required',
      },
    },
  },
  {
    name: 'additionalCost',
    type: 'number',
    title: t('form.additionalCost.label'),
    tooltip: t('form.additionalCost.tooltip.__html'),
    unit: 'zł',
    precision: 2,
  },
  {
    name: 'minimumMarkup',
    type: 'number',
    title: t('form.minimumMarkup.label'),
    tooltip: t('form.minimumMarkup.tooltip.__html'),
    unit: '%',
    precision: 2,
    rules: {
      required: {
        value: true,
        message: 'Field is required',
      },
    },
  },
  {
    name: 'standardMarkup',
    type: 'number',
    title: t('form.standardMarkup.label'),
    tooltip: t('form.standardMarkup.tooltip.__html'),
    unit: '%',
    precision: 2,
    rules: {
      required: {
        value: true,
        message: 'Field is required',
      },
    },
  },
  {
    name: 'maximumMarkup',
    type: 'number',
    title: t('form.maximumMarkup.label'),
    tooltip: t('form.maximumMarkup.tooltip.__html'),
    unit: '%',
    precision: 2,
  },
  {
    name: 'differenceFromCompetitionPercentage',
    type: 'number',
    title: t('form.differenceFromCompetitionPercentage.label'),
    tooltip: t('form.differenceFromCompetitionPercentage.tooltip.__html'),
    unit: '%',
    precision: 2,
    rules: {
      required: {
        value: true,
        message: 'Field is required',
      },
    },
  },
  {
    name: 'differenceFromCompetition',
    type: 'number',
    title: t('form.differenceFromCompetition.label'),
    tooltip: t('form.differenceFromCompetition.tooltip.__html'),
    unit: '$',
    precision: 2,
  },
];
