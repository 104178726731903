import React from 'react';
import { useActiveLocation } from 'modules/shared';
import { Link, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  href: string;
  exact?: boolean;
};

export const SettingsMenuItem: React.FC<Props> = (props) => {
  const active = useActiveLocation(props.href, props.exact);

  const styles: SxProps<Theme> = {
    pl: 1.5,
    py: 1.2,
    my: 0.5,
    color: 'common.black',
    textDecoration: 'none',
    backgroundColor: active ? 'nethansa.background.select' : '',
    borderRadius: 1.5,
    transition: 'background-color .3s',
    '&:hover': {
      backgroundColor: active ? 'nethansa.background.select' : 'nethansa.background.title',
    },
  };

  return (
    <Link sx={styles} href={props.href}>
      <Typography variant="h3" fontWeight={active ? '700' : 'unset'}>
        {props.children}
      </Typography>
    </Link>
  );
};
