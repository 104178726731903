import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, Dialog } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { CarrierCode } from 'modules/shared/types/carrier-code.type';
import { Box, Button, Stack, Typography } from '@mui/material';

type Props = {
  open: boolean;
  carrierCode?: CarrierCode;
  onClose: (confirm: boolean) => void;
};

export const DisconnectCarrierModal: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('settings');

  return (
    <Dialog open={props.open} onClose={() => null}>
      <Stack
        sx={{ minWidth: '496px', p: '32px' }}
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h1" sx={{ color: 'error.main' }}>
          {t('carriers.disconnect-modal.title')}
        </Typography>
        {props.onClose && <CloseButton onClick={() => props.onClose(false)} />}
      </Stack>

      <Box sx={{ px: '32px', pb: '32px', maxWidth: '500px' }}>
        <Typography variant="h5">
          {t('carriers.disconnect-modal.description.top', {
            carrier: t(`carriers.code-to-text.${props.carrierCode || 'dhl'}`),
          })}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="h5">
          {t('carriers.disconnect-modal.description.bottom')}
        </Typography>
      </Box>

      <Stack direction="row" justifyContent="flex-end" gap="10px" sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}>
        <Button variant="text" size="small" onClick={() => props.onClose(false)}>
          {tCommon('cancel')}
        </Button>
        <Button size="small" type="submit" onClick={() => props.onClose(true)}>
          {tCommon('confirm')}
        </Button>
      </Stack>
    </Dialog>
  );
};
