import React from 'react';
import { CardForm } from 'components';
import { UserProfileForm } from './components/user-profile-form';
import { UserProfileSkeleton } from './components/user-profile-skeleton';
import { useProfileDataQuery } from './hooks/use-profile-data.query';

export const YourDataPage: React.VFC = () => {
  const profileDataQuery = useProfileDataQuery();

  return (
    <CardForm>
      {!profileDataQuery.isFetched && <UserProfileSkeleton />}
      {profileDataQuery.isFetched && <UserProfileForm />}
    </CardForm>
  );
};
