import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { nethansaPalette } from '../../theme';

type Props = {
  sx?: SxProps<Theme>;
};

export const CloseIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={nethansaPalette.main.dark}
        d="M1.00139 1.17816L18.3566 18.1784"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke={nethansaPalette.main.dark}
        d="M18.3559 1.17819L1.00001 18.1777"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
