import React, { useCallback } from 'react';
import { Control, Controller, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlatformCode } from 'modules/shared/types';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useWizardProductsImportMutation } from '../../hooks/products/use-wizard-products-import.mutation';
import { AddProductsFormProps } from '../products-wizard.step';

type Props = {
  control: Control<AddProductsFormProps>;
  setError: UseFormSetError<AddProductsFormProps>;
  provider: PlatformCode;
};

export const ImportDirectlyOption: React.FC<Props> = (props) => {
  const [t] = useTranslation('wizard');
  const productsImportMutation = useWizardProductsImportMutation(props.provider);

  const handleStartImport = useCallback(
    ({ onChange, onBlur }: { onChange: (value: boolean) => void; onBlur: () => void }) => {
      productsImportMutation.mutate(null, {
        onSuccess: () => {
          onChange(true);
          onBlur();
        },
        onError: () => {
          props.setError('externalImportStarted', {
            type: 'API_ERROR',
            message: t('form.externalImportStarted.error.message'),
          });
        },
      });
    },
    [props.provider, props.setError, t],
  );

  return (
    <Box>
      <Typography variant="h5" sx={{ maxWidth: '600px' }}>
        {t('form.externalImportStarted.description')}
      </Typography>
      <Controller
        control={props.control}
        name="externalImportStarted"
        rules={{
          required: {
            value: true,
            message: t('form.externalImportStarted.validation.required'),
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <Box>
              <Button
                disabled={!!field.value || productsImportMutation.isLoading}
                onClick={() => handleStartImport({ onChange: field.onChange, onBlur: field.onBlur })}
                color={'flat'}
                size="small"
                sx={{ my: 2 }}
              >
                {field.value ? t('form.externalImportStarted.importing') : t('form.externalImportStarted.start-import')}
              </Button>
              {!!fieldState.error && <Alert severity="error">{fieldState.error?.message}</Alert>}
            </Box>
          );
        }}
      />
    </Box>
  );
};
