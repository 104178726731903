import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';

export const useWizardProductsImportMutation = (provider: PlatformCode): UseMutationResult<void, Error, null> => {
  return useMutation(async () => {
    await httpClient.post(`/v1/wizard/steps/products/${provider}/start-import`);
    return;
  });
};
