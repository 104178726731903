type Environment = {
  API_URL: string;
  GRAPHQL_URL: string;
  MUI_LICENSE_KEY: string;
  USE_COOKIE_AUTH: boolean;
  ENV: 'development' | 'production' | 'test';
};

export const environment: Environment = {
  API_URL: window.REACT_APP_API_URL || process.env.REACT_APP_API_URL || '',
  GRAPHQL_URL: window.REACT_APP_GRAPHQL_URL || process.env.REACT_APP_GRAPHQL_URL || '',
  MUI_LICENSE_KEY: process.env.MUI_LICENSE_KEY || '',
  USE_COOKIE_AUTH: window.REACT_APP_USE_COOKIE_AUTH || process.env.REACT_APP_USE_COOKIE_AUTH === 'true' || false,
  ENV: process.env.NODE_ENV,
};
