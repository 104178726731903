import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BlockMainRenderWithDelay, FlagIcon, FormButtonsGroup, RenderInView, Tooltip } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { generalPlatformFields } from '../../helpers/general-platform-fields';
import { useAmazonPlatformQuery } from '../../hooks/use-amazon-platform.query';
import { AmazonMarketType } from '../../types/markets.type';
import { PlatformRecordType } from '../../types/platforms.type';
import { CheckboxCell } from '../platform-table/checkbox-cell';
import { DividerRow } from '../platform-table/divider-row';
import { ReadonlyTextCell } from '../platform-table/readonly-text-cell';
import { RenderTableRowsFields } from '../platform-table/render-table-rows-fields';
import { tableStyles } from '../platform-table/table.styles';

export type PlatformsFormType = {
  merchant: AmazonMarketType[];
  amazon: AmazonMarketType[];
};

export const AmazonPlatformForm: React.VFC = () => {
  const { tCommon } = useCommonTranslations();
  const { data } = useAmazonPlatformQuery();
  const [t] = useTranslation('platforms');

  const markets = useMemo(() => data?.markets || [], [data?.markets]);

  const form = useForm<PlatformsFormType>({
    mode: 'onBlur',
    defaultValues: {
      merchant: data?.markets || [],
      amazon: data?.markets || [],
    },
  });

  const submit = (data: PlatformsFormType) => {
    console.log(data);
  };

  //TODO: maybe it will be possible to get data only by platform id and use the same for amz and kauf
  const rows = useMemo(
    () =>
      generalPlatformFields(t).reduce<PlatformRecordType[]>((acc, v) => {
        const dataInRow = (data?.markets || []).map((x) => x[v.name as keyof AmazonMarketType]);
        acc.push({
          ...v,
          data: dataInRow,
        });
        return acc;
      }, []),
    [data?.markets],
  );

  return (
    <FormProvider {...form}>
      <BlockMainRenderWithDelay
        fallback={
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <SpinnerIcon sx={{ fontSize: '50px' }} />
          </Stack>
        }
      >
        <Box sx={{ display: 'block' }}>
          <form onSubmit={form.handleSubmit(submit)}>
            <Table sx={{ ...tableStyles, width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '180px' }} />
                  {markets.map((x) => (
                    <TableCell
                      key={x.code}
                      variant="head"
                      sx={{
                        position: 'sticky',
                        top: '184px',
                        zIndex: 2,
                        '& > *': { justifyContent: 'center' },
                      }}
                    >
                      <FlagIcon country={x.code} showCountryCode />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t('form.managedByNethansa.label')} <Tooltip content={t('form.managedByNethansa.tooltip.__html')} />
                  </TableCell>
                  {markets.map((x, index) => (
                    <CheckboxCell key={x.code} control={form.control} name={`markets.${index}.isManagedByNethansa`} />
                  ))}
                </TableRow>
                <DividerRow text={t('amazon.fulfilled-by-merchant')} colSpan={markets.length + 1} />
                <RenderInView height={'49px'} placeholderComponent={TableRow}>
                  <TableCell>
                    {t('form.holidaysEnabled.label')} <Tooltip content={t('form.holidaysEnabled.tooltip.__html')} />
                  </TableCell>
                  {markets.map((x) => (
                    <ReadonlyTextCell key={x.code} text={x.holidaysEnabled ? tCommon('yes') : tCommon('no')} />
                  ))}
                </RenderInView>
                <RenderTableRowsFields rows={rows} type="merchant" />
                <DividerRow text={t('amazon.fulfilled-by-amazon')} colSpan={markets.length + 1} />
                <RenderTableRowsFields rows={rows} type="amazon" />
              </TableBody>
            </Table>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h5">{t('form.field-is-required')}</Typography>
            </Box>
            <FormButtonsGroup
              sx={{
                py: 2,
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 2,
              }}
            >
              <Button type="button" size="small" variant="text">
                {tCommon('cancel')}
              </Button>
              <Button type="submit" size="small">
                {tCommon('save')}
              </Button>
            </FormButtonsGroup>
          </form>
        </Box>
      </BlockMainRenderWithDelay>
    </FormProvider>
  );
};
