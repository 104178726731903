import React from 'react';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const NethansaLogo: React.FC<Props> = (props) => {
  return (
    <Box sx={props.sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="170" height="32" viewBox="0 0 170 32" fill="none">
        <g clipPath="url(#clip0_6_86)">
          <path
            d="M30.1046 12.0697C30.051 11.8787 29.9974 11.6876 29.9439 11.5239L28.899 12.6156C29.1401 13.6255 29.2741 14.6626 29.3009 15.727C29.4616 23.6148 23.2996 30.1379 15.5569 30.2743C7.81416 30.4108 1.41102 24.1606 1.27707 16.2729C1.14311 8.38515 7.27834 1.86207 15.021 1.7256C17.7538 1.67102 20.3257 2.43523 22.5226 3.7726L24.0765 3.22673C17.9949 -1.11289 9.5824 -0.430559 4.2777 5.19185C-0.250042 9.96816 -1.16095 16.9279 1.43781 22.6049C1.70573 23.2054 2.02723 23.8058 2.37551 24.3517C2.85776 25.1159 3.39359 25.8528 4.00979 26.5352C4.22412 26.7808 4.43845 26.9991 4.65278 27.2175C4.86711 27.4358 5.10823 27.6542 5.34936 27.8725C6.04593 28.473 6.79609 29.0188 7.54625 29.4555C8.10887 29.7831 8.69828 30.0833 9.31448 30.3562C14.9943 32.7853 21.7725 31.5844 26.3002 26.8081C30.1582 22.7687 31.3906 17.1463 30.1046 12.0697Z"
            fill="#4543A6"
          />
          <path
            d="M29.8636 8.63082L30.8281 9.58608L32.2213 4.78247L29.6493 5.65585L27.5596 6.36548L28.5241 7.32074L28.2293 7.64826L26.8898 9.06751L26.1128 9.9136L25.5502 10.5141L22.496 13.7347L21.853 14.417L9.79688 27.1902C10.4131 27.4904 11.0293 27.7634 11.6723 27.9544L23.2194 15.7271L23.8624 15.0447L26.22 12.561L26.354 12.4246L26.9702 11.7695L27.8007 10.8689L28.3365 10.2957L29.1938 9.36773L29.5957 8.93104L29.8636 8.63082Z"
            fill="#FAAD1B"
          />
          <path
            d="M19.6021 4.75513L19.1467 4.91889L14.9404 6.36543L15.9049 7.32069L3.71484 20.2304C3.92917 20.8854 4.22388 21.5132 4.54538 22.1136L17.2713 8.65806L18.2358 9.61333L19.5485 5.10994L19.6021 4.75513Z"
            fill="#FAAD1B"
          />
          <path
            d="M24.9331 8.16678L25.2278 7.15693L25.3082 6.884V6.85671L25.9244 4.75513L23.8882 5.46475H23.8615L22.629 5.90144L21.6913 6.22896L21.2627 6.39272L22.2272 7.34799L20.968 8.68535L19.1997 10.5686L18.5568 11.2509L6.15234 24.379C6.36667 24.6246 6.58101 24.843 6.79534 25.0613C7.03646 25.2796 7.25079 25.498 7.49191 25.689L19.8963 12.561L20.5393 11.8787L23.5667 8.65806L24.5312 9.61333L24.6652 9.14934L24.9331 8.16678Z"
            fill="#FAAD1B"
          />
          <path
            d="M51.9387 25.6619V16.464C51.9387 12.7522 49.7418 10.6233 46.9019 10.6233C43.7673 10.6233 41.8383 13.0797 41.8383 16.3003V25.6619H39.7754V9.14946H41.8383V11.251H41.8919C42.9368 9.64074 44.7586 8.71277 46.8751 8.71277C51.3493 8.71277 54.0284 11.8515 54.0284 16.4368V25.6346H51.9387V25.6619Z"
            fill="#282828"
          />
          <path
            d="M58.7708 18.1835C58.9584 21.486 61.9054 24.1607 65.0668 24.1607C67.5852 24.1607 69.9964 22.6869 70.8805 20.2578H73.0774C71.845 23.8332 68.5765 26.044 65.0936 26.044C60.5123 26.044 56.6543 22.2502 56.6543 17.4193C56.6543 12.6157 60.539 8.71277 65.0668 8.71277C69.916 8.71277 73.5865 12.9705 73.5865 17.5285C73.5865 17.7468 73.5597 17.9379 73.5597 18.1562H58.7708V18.1835ZM71.4163 16.273C70.8269 12.9432 68.255 10.6233 65.04 10.6233C62.0126 10.6233 59.3066 12.8613 58.744 16.273H71.4163Z"
            fill="#282828"
          />
          <path
            d="M77.256 25.6619V11.06H73.8535V9.14947H77.256V3.30872H79.319V9.14947H83.2037V11.06H79.319V25.6619H77.256Z"
            fill="#282828"
          />
          <path
            d="M97.136 25.6619V16.4641C97.136 12.7522 94.9391 10.6233 92.0992 10.6233C88.9646 10.6233 87.0356 13.0797 87.0356 16.3003V25.6619H84.9727V3.30872H87.0356V11.251H87.0892C88.134 9.64075 89.9559 8.71278 92.0724 8.71278C96.5465 8.71278 99.2257 11.8515 99.2257 16.4368V25.6346H97.136V25.6619Z"
            fill="#282828"
          />
          <path
            d="M116.988 25.6618V22.7687H116.934C115.595 24.8157 113.264 26.0712 110.612 26.0712C105.762 26.0712 101.904 22.332 101.904 17.3647C101.904 12.3973 105.869 8.73999 110.612 8.73999C113.317 8.73999 115.621 9.96819 116.934 12.0698H116.988V9.14939H119.051V25.6618H116.988ZM117.095 17.4192C117.095 13.7347 114.255 10.6232 110.585 10.6232C106.861 10.6232 103.967 13.5163 103.967 17.4465C103.967 21.2403 106.78 24.1607 110.612 24.1607C114.228 24.1607 117.095 21.1311 117.095 17.4192Z"
            fill="#282828"
          />
          <path
            d="M134.859 25.6619V16.464C134.859 12.7522 132.662 10.6233 129.822 10.6233C126.687 10.6233 124.758 13.0797 124.758 16.3003V25.6619H122.695V9.14946H124.758V11.251H124.812C125.857 9.64074 127.679 8.71277 129.795 8.71277C134.269 8.71277 136.948 11.8515 136.948 16.4368V25.6346H134.859V25.6619Z"
            fill="#282828"
          />
          <path
            d="M146.807 13.2161C146.7 11.6058 145.709 10.6233 144.369 10.6233C143.003 10.6233 141.905 11.6877 141.905 13.107C141.905 14.3079 142.735 15.154 144.718 15.8363C148.656 17.1736 149.433 18.7567 149.433 21.1312C149.433 24.1607 147.45 26.0712 144.343 26.0712C141.556 26.0712 139.359 24.0515 139.359 20.9128V20.8036H141.422C141.449 23.0144 142.574 24.188 144.316 24.188C146.191 24.188 147.343 23.1236 147.343 21.2403C147.343 19.63 146.325 18.7567 144.477 18.0743C141.637 17.0099 139.815 16.1911 139.815 13.3526C139.815 10.7052 141.771 8.76733 144.45 8.76733C146.888 8.76733 148.87 10.6506 148.87 13.2707H146.807V13.2161Z"
            fill="#282828"
          />
          <path
            d="M166.177 25.6618V22.7687H166.124C164.784 24.8157 162.453 26.0712 159.801 26.0712C154.952 26.0712 151.094 22.332 151.094 17.3647C151.094 12.3973 155.059 8.73999 159.801 8.73999C162.507 8.73999 164.811 9.96819 166.124 12.0698H166.177V9.14939H168.24V25.6618H166.177ZM166.311 17.4192C166.311 13.7347 163.471 10.6232 159.801 10.6232C156.077 10.6232 153.183 13.5163 153.183 17.4465C153.183 21.2403 155.997 24.1607 159.828 24.1607C163.445 24.1607 166.311 21.1311 166.311 17.4192Z"
            fill="#282828"
          />
        </g>
      </svg>
    </Box>
  );
};
