import * as React from 'react';
import { Box } from '@mui/material';

type TooltipProps = {
  className: string;
  ownerState: {
    arrow: boolean;
    disableFocusListener: boolean;
    disableInteractive: boolean;
    disableTouchListener: boolean;
    isRtl: boolean;
    open: boolean;
    placement: 'top-start' | 'top-end';
    touch: boolean;
  };
};

export const TooltipArrow = React.forwardRef<HTMLDivElement, TooltipProps>(() => {
  return (
    <Box
      className="Tooltip-Arrow"
      sx={{
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
        width: '16px',
        height: '10px',
        position: 'absolute',
        top: 'calc(100% - 0.5px)',
        overflow: 'hidden',
        '&:before': {
          transformOrigin: '100% 0',
          boxShadow: '0px 0px 5px 1px rgb(64 64 64 / 16%)',
          content: '""',
          margin: 'auto',
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: 'currentColor',
          transform: 'rotate(45deg)',
        },
      }}
    />
  );
});

TooltipArrow.displayName = 'TooltipArrow';
