import React from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import { TextFieldNumber } from 'components';
import { TableCell } from '@mui/material';

type Props = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  disabled?: boolean;
  unit?: string;
  precision?: number;
} & Pick<ControllerProps, 'rules'>;

export const NumberInputCell: React.VFC<Props> = (props) => {
  return (
    <TableCell sx={{ textAlign: 'center' }} align="center">
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field, fieldState }) => (
          <TextFieldNumber
            error={!!fieldState.error}
            sx={{ maxWidth: '96px' }}
            unit={props.unit}
            precision={props.precision || 0}
            disabled={props.disabled}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            tooltipError={fieldState.error?.message}
          />
        )}
      />
    </TableCell>
  );
};
