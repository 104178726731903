import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';

type Props = {
  text: string;
  colSpan?: number;
};

export const DividerRow: React.VFC<Props> = (props) => {
  return (
    <TableRow
      sx={{
        height: '40px',
        width: '100%',
        backgroundColor: 'nethansa.background.group',
        position: 'sticky',
        top: '191px',
        zIndex: 1,
        '&:after': {
          content: '""',
          position: 'absolute',
          left: '-32px',
          top: 0,
          height: '42px',
          width: 'calc(100% + 64px)',
          backgroundColor: 'nethansa.background.group',
          zIndex: -1,
        },
      }}
    >
      <TableCell colSpan={props.colSpan || 1}>
        <Typography variant="h4" sx={{ marginLeft: '-15px' }}>
          {props.text}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
