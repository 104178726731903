import React from 'react';
import { Box, Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { CheckedIcon } from '../icons';

type Props = Omit<CheckboxProps, 'icon' | 'checkedIcon' | 'indeterminateIcon'> & {
  label: string | JSX.Element;
};

type CheckboxIconProps = {
  disabled?: boolean;
};

const CheckboxDefault: React.FC<CheckboxIconProps> = (props) => {
  return (
    <Box
      component="span"
      sx={{
        height: '18px',
        width: '18px',
        backgroundColor: props.disabled ? 'background.default' : 'common.white',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'flat.main',
      }}
    />
  );
};

const CheckboxChecked: React.FC<CheckboxIconProps> = (props) => {
  return (
    <Box
      component="span"
      sx={{
        height: '18px',
        width: '18px',
        backgroundColor: props.disabled ? 'common.white' : 'primary.main',
        borderRadius: '4px',
        borderWidth: props.disabled ? '2px' : 'none',
        borderStyle: props.disabled ? 'solid' : 'none',
        borderColor: props.disabled ? 'flat.main' : 'none',
      }}
    >
      <CheckedIcon
        sx={{ fontSize: '10px', path: { stroke: props.disabled ? 'nethansa.button.grayLight' : 'common.black' } }}
      />
    </Box>
  );
};

const CheckboxIndeterminate: React.FC<CheckboxIconProps> = (props) => {
  return (
    <Box
      component="span"
      sx={{
        height: '18px',
        width: '18px',
        backgroundColor: 'common.white',
        borderRadius: '4px',
        borderWidth: `2px`,
        borderStyle: 'solid',
        borderColor: props.disabled ? 'flat.main' : 'secondary.main',
      }}
    >
      <Box
        sx={{
          height: '2px',
          width: '10px',
          borderRadius: '4px',
          backgroundColor: props.disabled ? 'flat.main' : 'secondary.main',
        }}
      />
    </Box>
  );
};

export const Checkbox: React.FC<Props> = (props) => {
  const { label, ...inputProps } = props;
  if (!label)
    return (
      <MuiCheckbox
        {...inputProps}
        icon={<CheckboxDefault disabled={props.disabled} />}
        checkedIcon={<CheckboxChecked disabled={props.disabled} />}
        indeterminateIcon={<CheckboxIndeterminate disabled={props.disabled} />}
      />
    );

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          {...inputProps}
          icon={<CheckboxDefault disabled={props.disabled} />}
          checkedIcon={<CheckboxChecked disabled={props.disabled} />}
          indeterminateIcon={<CheckboxIndeterminate disabled={props.disabled} />}
        />
      }
      label={label}
    />
  );
};
