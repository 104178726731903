import React, { useCallback } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectChangeEvent,
  Stack,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { SpinnerIcon } from '../icons';
import { Tooltip } from '../tooltip';

type Props = {
  value?: string;
  onChange: (value?: string) => void;
  onBlur?: (value?: string) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  tooltipContent?: string;
  options: MenuItemProps[];
  disabled?: boolean;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
};

export const SelectField: React.FC<Props> = (props) => {
  const handleOnChange = useCallback(
    (event: SelectChangeEvent) => {
      props.onChange(event.target?.value || '');
    },
    [props.onChange],
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!props.onBlur) return;
      props.onBlur(event.target?.value || '');
    },
    [props.onBlur],
  );

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', m: 1, ...props.sx }}>
      {props.label && (
        <Stack
          direction="row"
          alignItems="center"
          gap="12px"
          sx={{
            fontSize: '12px',
            fontWeight: 700,
            color: 'nethansa.main.gray',
            textTransform: 'uppercase',
            letterSpacing: '0.22px',
            lineHeight: '14.52px',
            mb: 0.75,
            position: 'absolute',
            top: '-20px',
            left: 0,
          }}
        >
          {props.label}
          {props.tooltipContent && <Tooltip content={props.tooltipContent} />}
        </Stack>
      )}
      <FormControl sx={{ m: 0, minWidth: 150, pt: 0 }} disabled={props.disabled}>
        <InputLabel shrink={false} sx={{ '&.MuiFormLabel-filled': { display: 'none' } }}>
          {props.placeholder}
        </InputLabel>
        <Box sx={{ position: 'relative' }}>
          <MuiSelect
            disabled={props.disabled}
            value={props.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={props.error}
            sx={{ width: '100%' }}
          >
            {props.options.map(({ children, ...rest }, index) => (
              <MenuItem key={index} {...rest}>
                {children}
              </MenuItem>
            ))}
          </MuiSelect>
          {props.isLoading && (
            <Stack
              sx={{ ml: 2, position: 'absolute', right: '-46px', top: '-4px' }}
              alignItems="center"
              justifyContent="center"
            >
              <SpinnerIcon sx={{ fontSize: '43px' }} />
            </Stack>
          )}
        </Box>
        <FormHelperText
          error={props.error}
          sx={{
            mx: 0,
            position: 'absolute',
            right: 0,
            top: '100%',
            textAlign: 'right',
            visibility: !props.helperText ? 'hidden' : 'visible',
          }}
        >
          {props.helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
