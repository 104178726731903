import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldPassword } from 'components';
import { Stack, TextField } from '@mui/material';
import { useIntegrationContext } from '../../../hooks/use-integration-context';
import { IntegrationGenericModalForm } from '../integration-generic-modal-form';

type FormProps = {
  clientId: string;
  login: string;
  password: string;
};

export const DHLPolandModalForm: React.VFC = () => {
  const [t] = useTranslation('settings');
  const {
    connector: [, setConnector],
  } = useIntegrationContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormProps>({
    mode: 'onBlur',
    defaultValues: {
      clientId: '',
      login: '',
      password: '',
    },
  });

  const submit = (data: FormProps) => {
    console.log(data);
  };

  return (
    <IntegrationGenericModalForm
      open
      onClose={() => setConnector(undefined)}
      submit={handleSubmit(submit)}
      header="Connect to DHL Poland account"
      logoElement={<img alt="carrier-logo" src="/images/carriers/logo-carrier-dhl.svg" />}
    >
      <Stack gap="30px">
        <Controller
          control={control}
          name="clientId"
          rules={{
            required: {
              value: true,
              message: t('form.carrierClientId.validation.required', { carrier: 'DHL' }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="name"
                disabled={isSubmitting}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.carrierClientId.label')}
                placeholder={t('form.carrierClientId.placeholder', { carrier: 'DHL' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="login"
          rules={{
            required: {
              value: true,
              message: t('form.carrierLogin.validation.required', { carrier: 'DHL' }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="name"
                disabled={isSubmitting}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.carrierLogin.label')}
                placeholder={t('form.carrierLogin.placeholder', { carrier: 'DHL' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: {
              value: true,
              message: t('form.carrierPassword.validation.required', { carrier: 'DHL' }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextFieldPassword
                autoComplete="password"
                disabled={isSubmitting}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.carrierPassword.label')}
                placeholder={t('form.carrierPassword.placeholder', { carrier: 'DHL' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
      </Stack>
    </IntegrationGenericModalForm>
  );
};
