import React, { useState } from 'react';
import { useCommonTranslations } from 'modules/shared';
import { Box, Stack, TextFieldProps, Typography } from '@mui/material';
import { EyeIcon } from '../icons';
import { TextFieldWithTooltip } from './text-field-with-tooltip';

type Props = TextFieldProps & { tooltipContent?: string };

export const TextFieldPassword: React.VFC<Props> = (props) => {
  const { ...textFieldProps } = props;
  const { tCommon } = useCommonTranslations();
  const [showPassword, setShowPassword] = useState(false);

  const text = showPassword ? tCommon('hide') : tCommon('show');

  return (
    <Box data-testid="TextFieldPassword" sx={{ position: 'relative', '& > .MuiFormControl-root': { width: '100%' } }}>
      <Stack
        data-testid="TextFieldPassword-EyeIconContainer"
        direction="row"
        spacing={0.5}
        sx={{ position: 'absolute', right: 0, top: '-20px', cursor: 'pointer' }}
        onClick={() => setShowPassword(!showPassword)}
      >
        <EyeIcon sx={{ fontSize: '17px' }} crossed={!showPassword} />{' '}
        <Typography fontSize="12px" color="nethansa.main.gray" fontWeight={700} textTransform="uppercase">
          {text}
        </Typography>
      </Stack>
      <TextFieldWithTooltip {...textFieldProps} type={showPassword ? 'text' : 'password'} />
    </Box>
  );
};
