import React from 'react';
import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { SpinnerIcon } from '../icons';

type Props = {
  justifyContent?: 'flex-end' | 'flex-start';
  isLoading?: boolean;
  sx?: SxProps<Theme>;
};

export const FormButtonsGroup: React.FC<Props> = (props) => {
  const rootStyles: SxProps<Theme> = {
    ...(props?.sx || {}),
  };

  const containerStyles: SxProps<Theme> = {
    position: 'relative',
    paddingLeft: props.justifyContent === 'flex-end' || !props.justifyContent ? 4 : 0,
    paddingRight: props.justifyContent === 'flex-start' ? 4 : 0,
  };

  const spinnerStyles: SxProps<Theme> = {
    position: 'absolute',
    left: 0,
    fontSize: '36px',
  };

  return (
    <Stack sx={rootStyles} direction="row" justifyContent={props?.justifyContent || 'flex-end'}>
      <Stack sx={containerStyles} direction="row" alignItems="center" spacing={1}>
        {props.isLoading && <SpinnerIcon sx={spinnerStyles} />}
        {props.children}
      </Stack>
    </Stack>
  );
};
