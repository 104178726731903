import { TooltipClasses, TooltipProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const tooltipStyles: {
  defaultProps?: Partial<TooltipProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TooltipClasses>> | undefined;
} = {
  styleOverrides: {
    popper: {
      marginBottom: '0px',
      '&[data-popper-placement="top-start"]': {
        '& .MuiTooltip-tooltip': {
          marginLeft: '-21px',
          '& .Tooltip-Arrow': {
            left: '20px',
          },
        },
      },
      '&[data-popper-placement="top-end"]': {
        '& .MuiTooltip-tooltip': {
          marginRight: '-19px',
          '& .Tooltip-Arrow': {
            right: '20px',
          },
        },
      },
      '&[data-popper-placement="bottom-end"]': {
        '& .MuiTooltip-tooltip': {
          marginLeft: '-21px',
          '& .Tooltip-Arrow': {
            right: '20px',
            top: '-10px',
            transform: 'rotate(180deg)',
          },
        },
      },
      '&[data-popper-placement="right-start"]': {
        '& .MuiTooltip-tooltip': {
          marginLeft: '-21px',
          '& .Tooltip-Arrow': {
            left: '-13px',
            top: '10px',
            transform: 'rotate(90deg)',
          },
        },
      },
      '&[data-popper-placement="right"]': {
        '& .MuiTooltip-tooltip': {
          marginLeft: '-21px',
          '& .Tooltip-Arrow': {
            left: '-13px',
            top: 'calc(50% - 5px)',
            transform: 'rotate(90deg)',
          },
        },
      },
    },
    tooltip: {
      background: nethansaPalette.main.light,
      color: nethansaPalette.main.dark,
      padding: 16,
      boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.3)',
      borderRadius: '6px',
      maxWidth: '30rem',
      fontSize: '13px',
    },
  },
};
