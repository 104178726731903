import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { CarrierType } from '../types/carrier.type';

const mocked: CarrierType[] = [
  { id: '1', name: 'DHL Poland', connected: false, code: 'dhl' },
  { id: '2', name: 'Eurohermes DHL', connected: true, code: 'eurohermes' },
  { id: '3', name: 'GLS', connected: false, code: 'gls' },
  { id: '5', name: 'UPS', connected: false, code: 'ups' },
];

export const useCarriersListQuery = (): UseQueryResult<ResponseAPIType<CarrierType[]>> => {
  return useQuery<ResponseAPIType<CarrierType[]>>(
    ['settings', 'carriers'],
    async () => {
      return {
        data: mocked,
        errors: undefined,
        pagination: undefined,
      };
    },
    {
      staleTime: 5000,
      keepPreviousData: true,
    },
  );
};
