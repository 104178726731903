import React, { useState } from 'react';
import { PlatformCode } from 'modules/shared/types';
import { usePlatformsSettingsQuery } from '../hooks/use-platforms-settings.query';
import { PlatformsContextType } from '../types/platforms.type';

export const PlatformsContext = React.createContext<PlatformsContextType>({});

export const PlatformsContextProvider: React.FC = (props) => {
  const { isFetched, data } = usePlatformsSettingsQuery();

  const tab = useState<PlatformCode | undefined>(
    isFetched && data?.[0] ? (String(data[0]?.platformCode).toLowerCase() as PlatformCode) : undefined,
  );
  const connector = useState<PlatformCode | undefined>();
  const disconnectPlatform = useState<PlatformCode | undefined>();

  const value: PlatformsContextType = {
    tab,
    connector,
    disconnectPlatform,
  };

  return <PlatformsContext.Provider value={value}>{props.children}</PlatformsContext.Provider>;
};
