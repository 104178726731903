import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';

type InputType = {
  mainShippingCountry: string;
  baseCurrency: string;
};

export const useWizardGlobalsMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.put<null, null, InputType>('/v1/wizard/steps/globals', {
      baseCurrency: body.baseCurrency,
      mainShippingCountry: body.mainShippingCountry,
    });
    return;
  });
};
