import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type Props = TextFieldProps;

export const TextFieldOutlined: React.VFC<Props> = (props) => {
  const { ...textFieldProps } = props;

  return <TextField {...textFieldProps} variant="outlined" />;
};
