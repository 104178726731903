import { MenuItemClasses, MenuItemProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const menuItemStyles: {
  defaultProps?: Partial<MenuItemProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof MenuItemClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      padding: '6px 13px',
      fontSize: '13px',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: 'nethansa.other.pale',
      },
    },
  },
};
