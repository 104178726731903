import React from 'react';
import { NavigationTabsItem, NavigationTabsLayout } from 'components/navigation-tabs';
import { routes } from 'routes';

export const CarriersLayout: React.VFC = () => (
  <NavigationTabsLayout>
    <NavigationTabsItem href={routes.Settings.children.Carriers.link()} exact children="Integrations" />
    <NavigationTabsItem
      href={routes.Settings.children.Carriers.children.ShippingCountry.link()}
      children="Shipping Country"
    />
  </NavigationTabsLayout>
);
