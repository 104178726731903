import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { dateFormats } from 'common/format/date';
import 'common/i18next';
import { queryClient } from 'common/query-client';
import { applyExternalAccess } from 'common/utils';
import { AuthorizationProvider } from 'modules/authorization';
import { GlobalStyles } from 'modules/shared';
import { ExternalAccessType } from 'modules/shared/types';
import { theme } from 'theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { App } from './App';
import { environment } from './environment';

declare global {
  interface Window {
    __REACT_APP_ACCESS__: ExternalAccessType;
    REACT_APP_API_URL: string;
    REACT_APP_GRAPHQL_URL: string;
    REACT_APP_USE_COOKIE_AUTH: boolean;
    location: Location;
  }
}

LicenseInfo.setLicenseKey(environment.MUI_LICENSE_KEY);

const bootstrap = (element?: HTMLElement | null) => {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={null}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AuthorizationProvider>
              <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={dateFormats}>
                  <App />
                  <CssBaseline />
                  <GlobalStyles />
                </LocalizationProvider>
              </QueryClientProvider>
            </AuthorizationProvider>
          </BrowserRouter>
        </ThemeProvider>
      </Suspense>
    </React.StrictMode>,
    element || document.getElementById('root'),
  );
};

applyExternalAccess({
  bootstrap,
});

if (process.env.NODE_ENV === 'development') {
  bootstrap(document.getElementById('root'));
}
