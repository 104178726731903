import { ExternalAccessType } from 'modules/shared/types';

export const applyExternalAccess = (methods?: ExternalAccessType): void => {
  const existingMethods = window.__REACT_APP_ACCESS__ || {
    bootstrap: () => null,
    changeLanguage: () => null,
    openWizard: () => null,
    openAmazonPlatformConnector: () => null,
    openKauflandPlatformConnector: () => null,
  };

  window.__REACT_APP_ACCESS__ = {
    bootstrap: methods?.bootstrap || existingMethods.bootstrap,
    changeLanguage: methods?.changeLanguage || existingMethods.changeLanguage,
    openWizard: methods?.openWizard || existingMethods.openWizard,
    openAmazonPlatformConnector: methods?.openAmazonPlatformConnector || existingMethods.openAmazonPlatformConnector,
    openKauflandPlatformConnector:
      methods?.openKauflandPlatformConnector || existingMethods.openKauflandPlatformConnector,
  };
};
