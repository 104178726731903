import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { CountryCode } from '../types';

export const useCountriesQuery = (): UseQueryResult<{ value: CountryCode; label: string }[]> => {
  const { i18n } = useTranslation();
  return useQuery<{ value: CountryCode; label: string }[]>(
    ['shared', 'countries', i18n.language],
    async () => {
      const { data } = await axios.get<{ [key in CountryCode]: string }>(`/locales/${i18n.language}/countries.json`);
      return Object.entries(data).map((input) => ({
        value: input[0],
        label: input[1],
      })) as { value: CountryCode; label: string }[];
    },
    {
      staleTime: 500000,
      keepPreviousData: true,
    },
  );
};
