import { GraphQLClient, RequestDocument } from 'graphql-request';
import { environment } from '../../environment';
import { getCredentials } from '../../modules/authorization';

const client = new GraphQLClient(environment.GRAPHQL_URL, {
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: environment.USE_COOKIE_AUTH ? 'same-origin' : 'omit',
});

export const graphqlClient = {
  request: async <Variables>(document: RequestDocument, variables?: Variables | undefined) => {
    if (environment.USE_COOKIE_AUTH) return client.request(document, variables);

    const credentials = getCredentials();

    return client.request(document, variables, {
      Authorization: `Bearer ${credentials.token}`,
    });
  },
};
