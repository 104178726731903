import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'components';
import { useCountriesTranslations, useCurrenciesTranslations } from 'modules/shared';
import { Divider, Paper, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useGlobalSettingsQuery } from '../hooks/use-global-settings.query';

export const GlobalSettingsData: React.VFC = () => {
  const [tCountries] = useCountriesTranslations();
  const [tCurrencies] = useCurrenciesTranslations();
  const [t] = useTranslation('settings');

  const { data, isLoading } = useGlobalSettingsQuery();

  const itemStyles: SxProps<Theme> = { '& > *': { flex: 1 } };

  return (
    <Paper sx={{ width: '100%', maxWidth: '448px', mt: 3, p: 3 }}>
      <Stack spacing={3} divider={<Divider />}>
        <Stack direction="row" sx={itemStyles}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5">{t('global-settings.main-shipping-country.label')}</Typography>
            <Tooltip content={t('global-settings.main-shipping-country.tooltip.__html')} />
          </Stack>
          <Typography variant="h5" fontWeight={700}>
            {!isLoading && tCountries(data?.mainShippingCountry || `GB`)}
          </Typography>
        </Stack>
        <Stack direction="row" sx={itemStyles}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5">{t('global-settings.base-currency.label')}</Typography>
            <Tooltip content={t('global-settings.base-currency.tooltip.__html')} />
          </Stack>
          <Typography variant="h5" fontWeight={700}>
            {!isLoading && tCurrencies(`${data?.baseCurrency || 'EUR'}.long`)}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
