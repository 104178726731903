import { NativeSelectClasses, NativeSelectProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const nativeSelectStyles: {
  defaultProps?: Partial<NativeSelectProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof NativeSelectClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    outlined: {},
  },
};
