import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SalesRevenueReportsPage } from '../../modules/reports';
import { ReportsLayout } from '../../modules/reports/reports.layout';
import { routes } from '../routes';

export const ReportsRouter: React.VFC = () => {
  const reportsRoutes = routes.Reports;

  return (
    <Routes>
      <Route element={<ReportsLayout />}>
        <Route path={reportsRoutes.children.SalesRevenue.name} element={<SalesRevenueReportsPage />} />
        <Route path={reportsRoutes.children.OrderReturns.name} element={<SalesRevenueReportsPage />} />
        <Route path={reportsRoutes.children.Taxes.name} element={<SalesRevenueReportsPage />} />
        <Route index element={<Navigate to={reportsRoutes.children.SalesRevenue.name} />} />
      </Route>
    </Routes>
  );
};
