import { useMutation, UseMutationResult } from 'react-query';
import { envelope, httpClient } from 'common/api';
import { ErrorMeta } from 'modules/shared/types';

type InputType = {
  isTwoFactorEnabled: boolean;
  code: string | null;
};

type CodeError = 'wrongTwoFaCode';

export const useOPTCodeVerification = (): UseMutationResult<void, ErrorMeta<{ code: CodeError[] }>, InputType> => {
  return useMutation(async (body) => {
    await envelope(httpClient.put(`/v1/settings/Profiles/twoFactorAuthentication`, body));
  });
};
