import React from 'react';
import { Stack, Typography } from '@mui/material';

type Props = {
  active?: boolean;
  onClick?: () => void;
};

export const ReportTableViewItem: React.FC<Props> = (props) => {
  return (
    <Stack
      justifyContent="center"
      onClick={props.onClick}
      sx={{
        px: 3,
        height: '100%',
        backgroundColor: props.active ? 'nethansa.background.title' : '',
        '&:hover': {
          cursor: props.active ? 'default' : 'pointer',
          backgroundColor: 'nethansa.background.title',
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '16px',
          color: props.active ? 'common.black' : 'nethansa.main.gray',
        }}
      >
        {props.children}
      </Typography>
    </Stack>
  );
};
