import { useContext, useMemo } from 'react';
import { parseDate } from 'common/format/date';
import { isValid } from 'date-fns';
import { InputMaybe, SaleRevenueReportFilterInput, SaleRevenueReportSortInput, SortEnumType } from 'generated/graphql';
import { GridLinkOperator } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GridContext } from '../context/grid.context';

type ResultType = {
  first: number;
  after?: string;
  filter?: InputMaybe<SaleRevenueReportFilterInput>;
  sort?: InputMaybe<SaleRevenueReportSortInput>;
};

const operatorsDictionary = {
  is: 'eq',
  not: 'neq',
  contains: 'contains',
  equals: 'eq',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  isEmpty: 'eq',
  isNotEmpty: 'neq',
  isAnyOf: 'in',
  '=': 'eq',
  '!=': 'neq',
  '>': 'gt',
  '>=': 'gte',
  '<': 'lt',
  '<=': 'lte',
  after: 'gt',
  onOrAfter: 'gte',
  before: 'lt',
  onOrBefore: 'lte',
};

export const useGridContextGraphqlTranslator = (columns: GridColDef[], searchableColumns: string[]): ResultType => {
  const gridContext = useContext(GridContext);

  const first = useMemo(() => gridContext.itemsPerPage[0], [gridContext.itemsPerPage[0]]);

  const after = useMemo(
    () => gridContext.page[0] * gridContext.itemsPerPage[0],
    [gridContext.page[0], gridContext.itemsPerPage[0]],
  );

  const sort: InputMaybe<SaleRevenueReportSortInput> = useMemo(
    () =>
      gridContext.sort[0].reduce<InputMaybe<SaleRevenueReportSortInput>>((acc, v) => {
        if (!acc) acc = {};
        acc[v.field as keyof SaleRevenueReportSortInput] = v.sort === 'asc' ? SortEnumType.Asc : SortEnumType.Desc;
        return acc;
      }, {}),
    [gridContext.sort[0]],
  );

  const parseValue = (value: string, columnField: string) => {
    const type: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions' | string =
      columns.find((x) => x.field === columnField)?.type || 'string';
    if (type === 'number') {
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) ? numberValue : value;
    }
    if (type === 'date') {
      const dateValue = parseDate(value);
      return isValid(dateValue) ? dateValue : value;
    }
    if (type === 'boolean') {
      return value === 'true';
    }
    return value;
  };

  const linkOperator: GridLinkOperator = gridContext.filter[0].linkOperator || GridLinkOperator.And;

  const search: InputMaybe<SaleRevenueReportFilterInput> | undefined =
    gridContext.search[0].length > 0
      ? {
          or: searchableColumns?.map((x) => {
            const column = columns.find((y) => y.field === x);
            const value = gridContext.search[0];
            if (!column) return {};
            const operator = column.type === 'number' ? 'eq' : 'contains';
            return {
              [x]: {
                [operator]: parseValue(value, column.field),
              },
            };
          }),
        }
      : undefined;

  const filter: InputMaybe<SaleRevenueReportFilterInput> | undefined =
    gridContext.filter[0].items.length > 0
      ? {
          [linkOperator]: gridContext.filter[0].items.map((x) => ({
            [x.columnField]: {
              [operatorsDictionary[x.operatorValue as keyof typeof operatorsDictionary]]: parseValue(
                x.value,
                x.columnField,
              ),
            },
          })),
        }
      : undefined;

  const hasFilterOrSearch = !!search || !!filter;

  const combinedFilters = hasFilterOrSearch
    ? {
        and: [...(search ? [{ ...search }] : []), ...(filter ? [{ ...filter }] : [])],
      }
    : undefined;

  return {
    first: first,
    after: after > 0 ? window.btoa(String(after - 1)) : undefined,
    sort: gridContext.sort[0]?.length > 0 ? sort : undefined,
    filter: combinedFilters as InputMaybe<SaleRevenueReportFilterInput>,
  };
};
