import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { VatRatesForm } from './components/vat-rates-form';
import { useVatRatesQuery } from './hooks/use-vat-rates.query';

export const VatRatesPage: React.VFC = () => {
  const [t] = useTranslation('wizard');
  const vatRatesQuery = useVatRatesQuery();

  return (
    <Box sx={{ maxWidth: '720px' }}>
      <Box sx={{ p: 3, pb: 0 }}>
        <Typography variant="h5" sx={{ mb: 0 }}>
          {t('set-vat-and-shipping-step.description')}
        </Typography>
      </Box>

      {vatRatesQuery.data && vatRatesQuery.isFetched && <VatRatesForm />}
    </Box>
  );
};
