import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

type Props = {
  label: string;
  content: string | JSX.Element;
};

export const SummaryLabel: React.FC<Props> = (props) => {
  const valueIsString = typeof props.content === 'string';

  return (
    <Stack direction={valueIsString ? 'row' : 'column'} gap="10px">
      <Typography variant="h5" sx={{ color: '#8B8B8D' }}>
        {props.label}
      </Typography>
      {valueIsString && (
        <Typography variant="h5" sx={{ fontWeight: '700' }}>
          {props.content}
        </Typography>
      )}
      {!valueIsString && <Box>{props.content}</Box>}
    </Stack>
  );
};
