import React from 'react';
import { NavigationTabsItem, NavigationTabsLayout } from 'components/navigation-tabs';
import { routes } from 'routes';

export const SalesIntelligenceLayout: React.VFC = () => (
  <NavigationTabsLayout>
    <NavigationTabsItem href={routes.Settings.children.SalesIntelligence.link()} exact children="Global Settings" />
    <NavigationTabsItem
      href={routes.Settings.children.SalesIntelligence.children.Platforms.link()}
      children="Platforms"
    />
    <NavigationTabsItem
      href={routes.Settings.children.SalesIntelligence.children.VatRates.link()}
      children="VAT rates"
    />
  </NavigationTabsLayout>
);
