import React, { useCallback } from 'react';
import { useCommonTranslations } from 'modules/shared';
import { PlatformCode } from 'modules/shared/types';
import { NAVIGATION_APP_HEIGHT } from 'theme';
import { Box, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { usePlatformsContext } from '../hooks/use-platforms-context';
import { usePlatformsSettingsQuery } from '../hooks/use-platforms-settings.query';
import { ManageButtonMenu } from './manage-button-menu';

export const PlatformsTabs: React.VFC = () => {
  const { tCommon } = useCommonTranslations();
  const {
    tab: [tab, setTab],
  } = usePlatformsContext();

  const platformsQuery = usePlatformsSettingsQuery();

  const rootStyles: SxProps<Theme> = {
    position: 'sticky',
    zIndex: 3,
    top: `calc(${NAVIGATION_APP_HEIGHT} + 79px)`,
    height: '60px',
    backgroundColor: 'common.white',
    boxShadow: (theme) => `inset 0px 3px 5px ${theme.palette.nethansa.line.light}`,
  };

  const itemStyles: (isActive: boolean) => SxProps<Theme> = useCallback(
    (isActive) => ({
      px: 3,
      minWidth: '160px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '12px',
      height: '100%',
      backgroundColor: isActive ? 'background.default' : '',
      cursor: 'pointer',
      transition: 'background-color 0.3s, color 0.3s',
      letterSpacing: '0.22px',
      color: isActive ? 'text.primary' : 'nethansa.main.gray',
      boxShadow: isActive ? (theme) => `inset 0px 3px 5px ${theme.palette.nethansa.line.light}` : '',
      userSelect: 'none',
    }),
    [],
  );

  const handleTabClick = useCallback((tabNext: PlatformCode) => () => setTab(tabNext), [setTab]);

  return (
    <Stack sx={rootStyles} direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" sx={{ height: '100%' }}>
        {platformsQuery.data?.map((x) => (
          <Box key={x.platformCode} sx={itemStyles(tab === x.platformCode)} onClick={handleTabClick(x.platformCode)}>
            {tCommon(`platform-code-to-name.${x.platformCode}`)}
          </Box>
        ))}
      </Stack>
      <Box sx={{ mx: 4 }}>
        <ManageButtonMenu />
      </Box>
    </Stack>
  );
};
