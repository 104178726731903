import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const PaymentsIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.84615 0V27.7692C5.84615 28.5804 5.19577 29.2308 4.38462 29.2308C3.57346 29.2308 2.92308 28.5804 2.92308 27.7692V10.2308H4.38462V7.30769H0V27.7692C0 30.1735 1.98038 32.1538 4.38462 32.1538H19C18.7077 31.2769 18.5425 30.2538 18.5425 29.2308H8.49739C8.664 28.7689 8.77069 28.2852 8.77069 27.7692V2.92308H32.1553V19C33.1784 19.2923 34.2015 19.7308 35.0784 20.4615V0H5.84615ZM11.6923 5.84615V11.6923H29.2308V5.84615H11.6923ZM11.6923 14.6154V17.5385H19V14.6154H11.6923ZM21.9231 14.6154V17.5385H29.2308V14.6154H21.9231ZM11.6923 19V21.9231H19V19H11.6923ZM29.2308 20.4615C24.3726 20.4615 20.4615 24.3726 20.4615 29.2308C20.4615 34.0889 24.3726 38 29.2308 38V35.0769C25.9043 35.0769 23.3846 32.5572 23.3846 29.2308C23.3846 25.9043 25.9043 23.3846 29.2308 23.3846C32.5572 23.3846 35.0769 25.9043 35.0769 29.2308C35.0769 30.4994 34.7159 31.6715 34.0714 32.6113L32.1538 30.6923L31.4231 37.2692L38 36.5385L36.1731 34.7115C37.3876 33.1857 38 31.2433 38 29.2308C38 24.3726 34.0889 20.4615 29.2308 20.4615ZM11.6923 23.3846V26.3077H19V23.3846H11.6923Z"
        fill="#282828"
      />
    </SvgIcon>
  );
};
