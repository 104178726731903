import React from 'react';
import { Stack, Typography } from '@mui/material';

export const NoResults: React.VFC = () => {
  return (
    <Stack sx={{ width: '100%', height: '100%' }} alignItems="center" justifyContent="center">
      <Typography>No results</Typography>
    </Stack>
  );
};
