import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { NAVIGATION_APP_HEIGHT, nethansaPalette } from 'theme';
import { Stack, Typography } from '@mui/material';
import { SettingsMenuItem } from './settings-menu-item';

export const SettingsMenu: React.VFC = () => {
  const [t] = useTranslation('settings');
  const borderLine: string = ['1px', 'solid', nethansaPalette.line.light].join(' ');

  return (
    <Stack
      sx={{
        top: NAVIGATION_APP_HEIGHT,
        position: 'sticky',
        minWidth: '205px',
        height: `calc(100vh - ${NAVIGATION_APP_HEIGHT})`,
        borderRight: borderLine,
        backgroundColor: 'common.white',
      }}
    >
      <Stack justifyContent="center" sx={{ height: '79px', px: 3, borderBottom: borderLine }}>
        <Typography variant="h1">{t('title')}</Typography>
      </Stack>
      <Stack flex={1} sx={{ p: 1.5 }}>
        <Stack>
          <SettingsMenuItem href={routes.Settings.link()} exact children={t('menu.configuration')} />
          <SettingsMenuItem
            href={routes.Settings.children.SalesIntelligence.link()}
            children={t('menu.sales-intelligence')}
          />
          <SettingsMenuItem href={routes.Settings.children.Carriers.link()} children={t('menu.carriers')} />
          <SettingsMenuItem href={routes.Settings.children.Profile.link()} children={t('menu.your-profile')} />
        </Stack>
      </Stack>
    </Stack>
  );
};
