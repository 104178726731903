import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { TextFieldWithTooltip } from './text-field-with-tooltip';

type Props = Omit<TextFieldProps, 'type' | 'inputProps' | 'InputProps' | 'onChange' | 'onBlur' | 'value'> & {
  unit?: string;
  precision?: number;
  clearable?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  value?: string | number;
  tooltipContent?: string;
  tooltipError?: string;
  step?: number;
};

const parseFloatValue = (value?: string | number, precision?: number) => {
  const newValue = parseFloat(String(value || '').replaceAll(',', '.'));
  if (isNaN(newValue)) {
    return '';
  }
  return precision || precision === 0 ? newValue.toFixed(precision) : newValue.toString();
};

export const TextFieldNumber: React.VFC<Props> = (props) => {
  const { unit, precision, clearable, onChange, onBlur, value, step, ...inputProps } = props;
  const [inputValue, setInputValue] = useState<string>((value || '') as string);
  const [initialized, setInitialized] = useState(false);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(event.target?.value || '');
    },
    [setInputValue],
  );

  const handleBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const val = parseFloatValue(event.target?.value || '', precision);
      setInputValue(val);
      if (!onBlur) return;
      onBlur(val);
    },
    [setInputValue],
  );

  const handleClear = useCallback(() => {
    setInputValue('');
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const valNumber = !value ? 0 : typeof value === 'number' ? value : parseFloat((value as string) || '');
      if (event.code === 'ArrowUp') {
        const val = parseFloatValue(valNumber + (step || 1), precision);
        setInputValue(val);
      }
      if (event.code === 'ArrowDown') {
        const val = parseFloatValue(valNumber - (step || 1), precision);
        setInputValue(val);
      }
    },
    [value, precision, step],
  );

  useEffect(() => {
    if (!onChange) return;
    onChange(inputValue);
    if (inputValue) setInitialized(true);
  }, [inputValue]);

  useEffect(() => {
    if (initialized) return;
    if (value) {
      const val = parseFloatValue(value || '', precision);
      setInputValue(val);
      setInitialized(true);
    }
  }, [value, initialized]);

  return (
    <TextFieldWithTooltip
      {...inputProps}
      inputProps={{
        inputMode: 'numeric',
      }}
      InputProps={{
        startAdornment: (unit || unit === '') && <InputAdornment position="start">{unit}</InputAdornment>,
        endAdornment: clearable && inputValue && (
          <InputAdornment position="end" onClick={handleClear}>
            <CloseIcon />
          </InputAdornment>
        ),
      }}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
};
