import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const AlertIcon: React.VFC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
        fill="#FFEEED"
        stroke="#FF515C"
      />
      <path
        d="M8.76301 9.914L8.92501 3H7.07101L7.23801 9.914H8.76301ZM8.00001 13C8.17801 13 8.34201 12.948 8.49201 12.843C8.64201 12.739 8.76301 12.599 8.85601 12.423C8.94901 12.249 8.99701 12.055 9.00001 11.843C8.99701 11.528 8.89601 11.258 8.69601 11.033C8.49601 10.808 8.26401 10.695 8.00001 10.695C7.72501 10.695 7.48901 10.808 7.29201 11.033C7.09401 11.258 6.99701 11.528 7.00001 11.843C6.99701 12.161 7.09401 12.433 7.29201 12.66C7.48901 12.887 7.72501 13 8.00001 13V13Z"
        fill="#FF515C"
      />
    </SvgIcon>
  );
};
