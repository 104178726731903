import { ListItemButtonClasses, ListItemButtonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const listItemButtonStyles: {
  defaultProps?: Partial<ListItemButtonProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof ListItemButtonClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      padding: '6px 13px',
      fontSize: '13px',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: nethansaPalette.other.pale,
        transition: 'background-color .2s',
      },
    },
  },
};
