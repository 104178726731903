import React, { ElementType, useState } from 'react';
import { useInView } from 'react-hook-inview';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  placeholderComponent?: ElementType;
  sx?: SxProps<Theme>;
  height?: number | string;
};

export const RenderInView: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref] = useInView({
    threshold: 1,
    unobserveOnEnter: true,
    rootMargin: '300px',
    onEnter: () => {
      setIsVisible(true);
    },
  });

  return (
    <Box
      sx={{ ...(props.sx || {}), height: props.height }}
      component={props.placeholderComponent}
      className="observer-container"
      ref={ref}
    >
      {isVisible ? props.children : null}
    </Box>
  );
};
