import React from 'react';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useGridViewContext } from '../../hooks/use-grid-view-context';
import { ReportTableViewItem } from './report-table-views-item';

export const ReportTableViews: React.VFC = () => {
  const { views, currentView, changeView } = useGridViewContext();

  const rootStyle: SxProps<Theme> = {
    height: '60px',
    position: 'relative',
    backgroundColor: 'common.white',
    '&:after': {
      content: '""',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      boxShadow: `0px 3px 5px 0px #CFD0DC inset`,
      zIndex: 2,
      pointerEvents: 'none',
    },
  };

  const containerStyle: SxProps<Theme> = {
    height: '100%',
    '& > div': {
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderRightColor: 'nethansa.line.light',
      '&:first-of-type': {
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        borderLeftColor: 'nethansa.line.light',
      },
    },
  };

  return (
    <Stack direction="row" alignItems="center" sx={rootStyle}>
      <Box sx={{ mx: 3 }}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
            fontSize: '12px',
            color: 'nethansa.button.grayHover',
          }}
        >
          Views:
        </Typography>
      </Box>
      <Stack direction="row" sx={containerStyle}>
        {views.map((view) => (
          <ReportTableViewItem key={view.id} active={view.id === currentView?.id} onClick={() => changeView(view.id)}>
            {view.name}
          </ReportTableViewItem>
        ))}
      </Stack>
    </Stack>
  );
};
