import React from 'react';
import { CardForm } from 'components';
import { Box } from '@mui/material';
import { ShippingCountryForm } from './components/shipping-country/shipping-country-form';

export const ShippingCountryPage: React.VFC = () => {
  return (
    <Box>
      <CardForm>
        <ShippingCountryForm />
      </CardForm>
    </Box>
  );
};
