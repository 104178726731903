import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountDataLayout, ChangePasswordPage, SecurityPage, YourDataPage } from 'modules/account';
import { CarriersLayout, IntegrationsPage, ShippingCountryPage } from 'modules/carriers';
import { GlobalSettingsPage } from 'modules/global-settings';
import { PlatformsPage } from 'modules/platform-settings';
import { SalesIntelligenceLayout } from 'modules/sales-intelligence';
import { SettingsLayout, SettingsPage, SettingsPageLayout } from 'modules/settings';
import { VatRatesPage } from 'modules/vat-rates-settings';
import { routes } from '../routes';

export const SettingsRouter: React.VFC = () => {
  const salesIntelligenceRoutes = routes.Settings.children.SalesIntelligence;
  const profileRoutes = routes.Settings.children.Profile;
  const carriersRoutes = routes.Settings.children.Carriers;

  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route element={<SettingsPageLayout />}>
          <Route index element={<SettingsPage />} />
        </Route>
        <Route path={salesIntelligenceRoutes.name} element={<SalesIntelligenceLayout />}>
          <Route index element={<GlobalSettingsPage />} />
          <Route path={salesIntelligenceRoutes.children.Platforms.name} element={<PlatformsPage />} />
          <Route path={salesIntelligenceRoutes.children.VatRates.name} element={<VatRatesPage />} />
        </Route>
        <Route path={profileRoutes.name} element={<AccountDataLayout />}>
          <Route index element={<YourDataPage />} />
          <Route path={profileRoutes.children.Security.name} element={<SecurityPage />} />
          <Route path={profileRoutes.children.ChangePassword.name} element={<ChangePasswordPage />} />
        </Route>
        <Route path={carriersRoutes.name} element={<CarriersLayout />}>
          <Route index element={<IntegrationsPage />} />
          <Route path={carriersRoutes.children.ShippingCountry.name} element={<ShippingCountryPage />} />
        </Route>
      </Route>
    </Routes>
  );
};
