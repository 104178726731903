import React from 'react';
import { Box } from '@mui/material';
import { usePlatformsContext } from '../hooks/use-platforms-context';
import { AmazonPlatform } from './amazon/amazon-platform';
import { KauflandPlatform } from './kaufland/kaufland-platform';

export const MarketplaceSettings: React.VFC = () => {
  const {
    tab: [platform],
  } = usePlatformsContext();

  return (
    <Box>
      {platform === 'amzeu' && <AmazonPlatform />}
      {platform === 'kauf' && <KauflandPlatform />}
    </Box>
  );
};
