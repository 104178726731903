import jwtDecode from 'jwt-decode';

export const setCredentials = (token: string, refreshToken: string) => {
  window.localStorage.setItem('token', token);
  window.localStorage.setItem('refreshToken', refreshToken);
  window.localStorage.setItem('tokenExpired', String(Date.now() + 36000));
  window.localStorage.setItem('refreshTokenExpired', String(Date.now() + 3600000));
};

export const getCredentials = () => ({
  token: window.localStorage.getItem('token'),
  refreshToken: window.localStorage.getItem('refreshToken'),
  tokenExpired: parseInt(window.localStorage.getItem('tokenExpired') || '0'),
  refreshTokenExpired: parseInt(window.localStorage.getItem('refreshTokenExpired') || '0'),
});

export const clearCredentials = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('tokenExpired');
  window.localStorage.removeItem('refreshTokenExpired');
};

export const hasValidToken = () => {
  const credentials = getCredentials();
  return !(!credentials || credentials.tokenExpired < Date.now());
};

export const hasValidCredentials = () => {
  const credentials = getCredentials();
  return !(!credentials || credentials.refreshTokenExpired < Date.now());
};

type DecodedTokenType = {
  sub: string;
  unique_name: string;
  email: string;
  jti: string;
  iat: '1648757768058';
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string[];
  partnerIds: string | string[];
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
};

export const decodeToken = (token: string) => {
  if (!token) throw new Error('Can not decode token, because token is missing');
  const decoded = jwtDecode<DecodedTokenType>(token);

  return {
    userId: decoded.sub,
    email: decoded.email,
    roles: decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
    partnerIds: decoded.partnerIds,
  };
};
